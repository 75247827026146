import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'
import { ProductBreadcrumb } from './marketplace/details/ProductBreadcrumb'
import { SubscriptionBreadcrumb } from './subscriptions/SubscriptionBreadcrumb'

export const routes: BreadcrumbsRoute[] = [
  { path: '/', breadcrumb: 'Home' },
  { path: '/me', breadcrumb: 'Profile' },
  { path: '/manage', breadcrumb: 'Manage' },
  { path: '/manage/subscriptions', breadcrumb: 'Subscriptions' },
  { path: '/marketplace', breadcrumb: 'Marketplace' },
  { path: '/marketplace/:key', breadcrumb: ProductBreadcrumb },
  { path: '/subscriptions/:subscriptionId', breadcrumb: SubscriptionBreadcrumb },
  { path: '/subscriptions/:subscriptionId/edit', breadcrumb: 'Edit' },
  { path: '/subscriptions/:subscriptionId/client-credentials', breadcrumb: 'Client credentials' },
  { path: '/subscriptions/:subscriptionId/users', breadcrumb: 'Users' },
  { path: '/subscriptions/:subscriptionId/invitations', breadcrumb: 'Invitations' },
  { path: '/subscriptions/:subscriptionId/marketplace/providers/create', breadcrumb: 'New provider' },
  { path: '/subscriptions/:subscriptionId/provider/products', breadcrumb: 'Products' },
]
