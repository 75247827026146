export const objectToFormData = (obj: any, rootName?: string, ignoreList?: string[]): FormData => {
  const formData = new FormData()

  function ignore(root?: string) {
    return Array.isArray(ignoreList)
      && ignoreList.some((x) => x === root)
  }

  function appendFormData(data: any, root?: string) {
    if (!ignore(root)) {
      root = root || '' // eslint-disable-line no-param-reassign
      if (data instanceof File) {
        formData.append(root, data)
      } else if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          appendFormData(data[i], `${root}[${i}]`)
        }
      } else if (typeof data === 'object' && data) {
        Object.keys(data).forEach((key) => {
          if (root === '') {
            appendFormData(data[key], key)
          } else {
            appendFormData(data[key], `${root}.${key}`)
          }
        })
      } else if (data !== null && typeof data !== 'undefined') {
        formData.append(root, data)
      }
    }
  }

  appendFormData(obj, rootName)

  return formData
}
