import React from 'react'
import ReactCopyToClipboard from 'react-copy-to-clipboard'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import './CopyToClipboard.scss'

export type CopyToClipboardProps = {
    text: string,
    title?: string,
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = (props) => {
  const { text, title } = props
  return (
    <ReactCopyToClipboard text={text}>
      <span className="copy-button">
        <FileCopyOutlinedIcon />
        {title && <span>{title}</span>}
      </span>
    </ReactCopyToClipboard>
  )
}
