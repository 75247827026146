import React from 'react'
import {
  Page,
  FormSkeleton,
  PageHeader,
} from '@logic/platform-fabric'
import { useParams } from 'react-router-dom'
import './ResourceSummary.scss'
import { useContextSubscriptionId } from '@logic/platform-core'
import { useProduct } from './useProduct'
import {
  GetStartedMarkDownDocument, ResourceDetails,
} from './ResourceDetails'

export const OpenProductsGetStarted = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { key, apiKeyId } = useParams<{ key:string, apiKeyId:string }>()
  const { value: productInfo, completed } = useProduct(key)

  return (
    <Page loading={!completed} skeleton={FormSkeleton} className="page-size">
      <PageHeader heading="Getting Started" />
      <GetStartedMarkDownDocument productInfo={productInfo} />
      <ResourceDetails apiKeyId={apiKeyId} keyValue={key} subscriptionId={subscriptionId} openProduct={true} />
    </Page>
  )
}
