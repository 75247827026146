import { ResourceModel } from './ResourceModel'
import { AxiosInstance } from 'axios'
import { useConfig } from '@logic/platform-core'
import { useAxios } from '../infrastructure/useAxios'

const getResources = (client: AxiosInstance) => (subscriptionId: string) =>
  client.get<ResourceModel[]>(`/subscriptions/${subscriptionId}/resources`).then((response) => response.data)

export type ResourceClient = {
  getResources: (subscriptionId: string) => Promise<ResourceModel[]>,
}

const createResourceClient = (client: AxiosInstance): ResourceClient => {
  return {
    getResources: getResources(client),
  }
}

export const useResourceClient = (): ResourceClient => {
  const { labPlatformApiUrl } = useConfig()
  const client = useAxios(labPlatformApiUrl, createResourceClient)
  return client
}
