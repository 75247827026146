import React from 'react'
import { Api } from '../../MarketplaceClient'
import { ApiSelector, ApiVersionSelection } from './ApiVersionSelector'
import { useData } from '@logic/platform-core'
import { TableOfContent, TableOfContentItemProps } from './TableOfContent'

export type ApiTableOfContentProps = {
  productApis: Api[] | undefined,
  loading: boolean,
  selectedKey?: string | undefined,
  onSelect: (item: TableOfContentItemProps) => void,
}

const useApiDefinition = (url?: string) => useData(undefined, () => {
  return url ? fetch(url).then((response) => response.json()) : Promise.resolve(undefined)
}, [url])

const operationsList = (apiSpec: any): TableOfContentItemProps[] => {
  const operations = Object.keys(apiSpec.paths).map((path) => {
    const methods = apiSpec.paths[path]
    return Object.keys(methods).map<[string, TableOfContentItemProps]>((method) => {
      const operation = methods[method]
      const tag = operation.tags?.[0] || 'default'
      return [tag, {
        itemKey: operation.operationId,
        name: operation.operationId,
        state: {
          type: 'api',
          apiDefinition: apiSpec,
          operationId: operation.operationId,
          tag: tag,
        },
      }]
    })
  })
  const groupedByTag = operations.reduce((prev, value) => [...prev, ...value], []).reduce((acc, [tag, operation]) => {
    acc[tag] = [...(acc[tag] || []), operation]
    return acc
  }, {} as {
    [tag: string]: TableOfContentItemProps[],
  })
  return Object.entries(groupedByTag).map(([tag, items]) => ({
    itemKey: tag,
    name: tag,
    items: items,
    state: {
      type: 'api',
      apiDefinition: apiSpec,
      operationId: items[0].state.operationId,
      tag: tag,
    },
  }))
}

const useTableOfContent = (apiVersion: ApiVersionSelection | undefined, apiDefinition: any): TableOfContentItemProps[] => {
  return React.useMemo(() => {
    if (apiVersion && apiDefinition) {
      return [
        {
          itemKey: 'introduction',
          name: 'Introduction',
          state: {
            type: 'introduction',
            name: `${apiVersion.api.name} ${apiVersion.version.number}`,
            apiDefinitionUrl: apiVersion.version.openApiSpecUrl,
            documentationUrl: apiVersion.version.documentationUrl,
          },
        },
        ...operationsList(apiDefinition),
      ]
    } else {
      return []
    }
  }, [apiVersion, apiDefinition])
}

export const ApiTableOfContent = ({ productApis, selectedKey, onSelect }: ApiTableOfContentProps): JSX.Element => {
  const [apiVersion, setApiVersion] = React.useState<ApiVersionSelection>()
  const { value: apiDefinition } = useApiDefinition(apiVersion?.version.openApiSpecUrl)
  const toc = useTableOfContent(apiVersion, apiDefinition)
  React.useEffect(() => {
    onSelect(toc[0])
  }, [toc, onSelect])
  return (
    <div>
      <ApiSelector loading={toc.length === 0} productApis={productApis || []} version={apiVersion} onVersionSelected={setApiVersion} />
      <TableOfContent loading={toc.length === 0} items={toc} selectedKey={selectedKey} onSelect={onSelect} />
    </div>
  )
}

export type ProductApiVersion = {
  id: string,
  name: string,
  version: string,
  documentationUrl: string | undefined,
  openApiSpecUrl: string,
  openApiSpec: any,
}
