import { FocusScope } from '@react-aria/focus'
import {
  OverlayContainer,
  OverlayProps,
  useModal,
  useOverlay,
  usePreventScroll,
} from '@react-aria/overlays'
import { useDialog } from '@react-aria/dialog'
import React, { ReactNode } from 'react'
import './Dialog.scss'

type ModalDialogProps = OverlayProps & {
  title: string,
  children: ReactNode,
}

const ModalDialog = (props: ModalDialogProps) => {
  usePreventScroll()
  const ref = React.useRef()
  const { modalProps } = useModal()
  const { overlayProps } = useOverlay(props, ref as any)
  const { dialogProps, titleProps } = useDialog(props as any, ref as any)
  return (
    <div className="overlay">
      <FocusScope contain={true} restoreFocus={true} autoFocus={true}>
        <div
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          className="dialog"
        >
          <h3 {...titleProps}>{props.title}</h3>
          {props.children}
        </div>
      </FocusScope>
    </div>
  )
}

type DialogProps = {
  isOpen: boolean,
  title: string,
  children: React.ReactNode,
}

export const Dialog: React.FC<DialogProps> = ({ isOpen, title, children }: DialogProps) => {
  return (
    <React.Fragment>
      {isOpen && (
        <OverlayContainer>
          <ModalDialog title={title}>
            {children}
          </ModalDialog>
        </OverlayContainer>
      )}
    </React.Fragment>
  )
}
