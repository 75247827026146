import React from 'react'
import { useMenuTriggerState } from '@react-stately/menu'
import { useButton } from '@react-aria/button'
import {
  useMenu,
  useMenuItem,
  useMenuTrigger,
} from '@react-aria/menu'
import { FocusScope } from '@react-aria/focus'
import { useFocus } from '@react-aria/interactions'
import { useOverlay, DismissButton } from '@react-aria/overlays'
import { mergeProps } from '@react-aria/utils'
import { TreeState, useTreeState } from '@react-stately/tree'
import { Item } from '@react-stately/collections'
import './ResourceFilter.scss'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

type DropdownFilterProps = {
  allowedValues: {
    displayName: string,
    value: string,
  }[],
  selectedKeys: Set<string>,
  onSelectionChange: any,
}

const MenuItem = ({ item, state, onAction, onClose }: { item: any, state: TreeState<any>, onAction: any, onClose: any}) => {
  const ref = React.useRef<HTMLLIElement | null>(null)
  const isSelected = state.selectionManager.isSelected(item.key)
  const { menuItemProps } = useMenuItem(
    {
      key: item.key,
      isDisabled: item.isDisabled,
      isSelected: isSelected,
      closeOnSelect: false,
      onAction,
      onClose,
    },
    state,
    ref
  )

  const [, setFocused] = React.useState(false)
  const { focusProps } = useFocus({ onFocusChange: setFocused })

  return (
    <li
      {...mergeProps(menuItemProps, focusProps)}
      ref={ref}
    >
      <div>
        <input type="checkbox" checked={isSelected} />
        <span>{item.rendered}</span>
      </div>
    </li>
  )
}

const DropdownFilterPopup = (props: any) => {
  const state = useTreeState({ ...props, selectionMode: 'multiple' })

  const ref = React.useRef<HTMLElement | null>(null)
  const { menuProps } = useMenu(props, state, ref)

  const overlayRef = React.useRef<HTMLDivElement | null>(null)
  const { overlayProps } = useOverlay(
    {
      onClose: props.onClose,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  )

  return (
    <FocusScope restoreFocus={true}>
      <div {...overlayProps} ref={overlayRef}>
        <DismissButton onDismiss={props.onClose} />
        <ul
          {...mergeProps(menuProps, props.domProps)}
          ref={ref}
          style={{
            position: 'absolute',
            right: 5,
            margin: '4px 0 0 0',
            listStyle: 'none',
            maxHeight: 300,
          }}
          className="popup-menu scoll-y"
        >
          {[...state.collection].map((item) => (
            <MenuItem
              key={item.key}
              item={item}
              state={state}
              onAction={props.onAction}
              onClose={props.onClose}
            />
          ))}
        </ul>
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  )
}

export const DropdownFilter = ({ allowedValues, selectedKeys, onSelectionChange, ...props }: DropdownFilterProps): JSX.Element => {
  const state = useMenuTriggerState(props)

  const ref = React.useRef<HTMLButtonElement | null>(null)
  const { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref)

  const { buttonProps } = useButton(menuTriggerProps, ref)
  const sortedAllowedValues = React.useMemo(() => {
    return [...allowedValues].sort((a, b) => a.displayName.localeCompare(b.displayName))
  }, [allowedValues])

  return (
    <div style={{ position: 'relative' }}>
      <button {...buttonProps} ref={ref} className="filter-button" type="button">
        <span>{selectedKeys?.size || 0} selected</span>
        {state.isOpen ? <KeyboardArrowUpIcon aria-hidden="true" /> : <KeyboardArrowDownIcon aria-hidden="true" />}
      </button>
      {state.isOpen && (
        <DropdownFilterPopup
          selectedKeys={selectedKeys}
          onSelectionChange={onSelectionChange}
          {...props}
          domProps={menuProps}
          autoFocus={state.focusStrategy}
          onClose={() => state.close()}
        >
          {sortedAllowedValues.map(({ displayName, value }) => <Item key={value}>{displayName}</Item>)}
        </DropdownFilterPopup>
      )}
    </div>
  )
}
