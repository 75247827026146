import React from 'react'
import { RestrictedRoute, RestrictedRouteProps } from '../authentication/RestrictedRoute'
import { useAuthentication } from '../authentication/AuthenticationProvider'
import { useContextSubscriptionId } from './useContextSubscriptionId'
import { useUserSubscriptionId } from './useUserSubscriptionId'
import { Unauthorized } from '../authentication/Unauthorized'
import { useConfig } from '../config/ConfigProvider'

const SubscriptionScopedRouteContent = ({ component, render, allowAdmin, ...ownProps }: any) => {
  const contextSubscriptionId = useContextSubscriptionId()
  const userSubscriptionId = useUserSubscriptionId()
  const { adminSubscriptionId } = useConfig()
  const { changeSubscription } = useAuthentication()
  React.useEffect(() => {
    if (userSubscriptionId !== contextSubscriptionId) {
      if (!allowAdmin || userSubscriptionId !== adminSubscriptionId) {
        changeSubscription(contextSubscriptionId)
      }
    }
  }, [userSubscriptionId, contextSubscriptionId, changeSubscription, adminSubscriptionId, allowAdmin])

  if (!userSubscriptionId) {
    return (<Unauthorized />)
  }

  if (userSubscriptionId !== contextSubscriptionId) {
    if (!allowAdmin || userSubscriptionId !== adminSubscriptionId) {
      return (<h1>Changing subscription...</h1>)
    }
  }

  if (component) {
    return React.createElement(component as React.ComponentClass<any, any>, ownProps)
  }

  if (render) {
    return render(ownProps)
  }
}

export type SubscriptionScopedRouteProps = RestrictedRouteProps & {
  allowAdmin?: boolean,
};

export const SubscriptionScopedRoute = ({ component, render, children, allowAdmin, ...props }: SubscriptionScopedRouteProps): JSX.Element => {
  return (
    <RestrictedRoute
      {...props}
      render={((renderProps) => (<SubscriptionScopedRouteContent allowAdmin={allowAdmin} component={component} {...renderProps} render={render} />))}
    />
  )
}
