import React from 'react'
import './ShareYourProductButton.scss'
import {
  CreateSubscriptionDialog,
  useCreateSubscriptionDialogControl,
  useSubscriptionRequiredLink,
} from '../subscriptions/CreateSubscriptionDialog'
import { Link } from 'react-router-dom'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

export const ShareYourProductButton = (): JSX.Element => {
  const { dialogProps, dialogControl } = useCreateSubscriptionDialogControl()
  const linkProps = useSubscriptionRequiredLink('/provider/products', dialogControl)
  return (
    <React.Fragment>
      <CreateSubscriptionDialog {...dialogProps} />
      <Link className="outlined" {...linkProps}>
        <FavoriteBorderIcon />
        <span>Share your product</span>
      </Link>
    </React.Fragment>
  )
}
