import { useSelectState } from '@react-stately/select'
import { HiddenSelect, useSelect } from '@react-aria/select'
import { useListBox, useOption } from '@react-aria/listbox'
import { mergeProps } from '@react-aria/utils'
import { useButton } from '@react-aria/button'
import { useFocus } from '@react-aria/interactions'
import { FocusScope } from '@react-aria/focus'
import { useOverlay, DismissButton } from '@react-aria/overlays'
import React from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import './Select.scss'

export { Item } from '@react-stately/collections'

const Option = ({ item, state }:any) => {
  const ref = React.useRef()
  const isDisabled = state.disabledKeys.has(item.key)
  const isSelected = state.selectionManager.isSelected(item.key)
  const { optionProps } = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
    },
    state,
    ref as any,
  )

  const [isFocused, setFocused] = React.useState(false)
  const { focusProps } = useFocus({ onFocusChange: setFocused })

  return (
    <li
      {...mergeProps(optionProps, focusProps)}
      ref={ref as any}
      style={{
        background: isFocused ? '#4dc6c7' : 'transparent',
        color: isFocused ? 'white' : 'black',
        padding: '2px 5px',
        outline: 'none',
        cursor: 'pointer',
      }}
    >
      {item.rendered}
    </li>
  )
}

const ListBoxPopup = ({ state, ...otherProps }:any): JSX.Element => {
  const ref = React.useRef()

  const { listBoxProps } = useListBox(
    {
      autoFocus: state.focusStrategy,
      disallowEmptySelection: true,
    },
    state,
    ref as any
  )

  const overlayRef = React.useRef()
  const { overlayProps } = useOverlay(
    {
      onClose: () => state.close(),
      shouldCloseOnBlur: true,
      isOpen: state.isOpen,
      isDismissable: true,
    },
    overlayRef as any
  )

  return (
    <FocusScope restoreFocus={true}>
      <div {...overlayProps} ref={overlayRef as any}>
        <DismissButton onDismiss={() => state.close()} />
        <ul
          className="list-box"
          {...mergeProps(listBoxProps, otherProps)}
          ref={ref as any}
        >
          {[...state.collection].map((item) => (
            <Option key={item.key} item={item} state={state} />
          ))}
        </ul>
        <DismissButton onDismiss={() => state.close()} />
      </div>
    </FocusScope>
  )
}

export const Select = (props:any) => {
  const state = useSelectState(props)

  const ref = React.useRef()
  const { triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    ref as any,
  )

  const { buttonProps } = useButton(triggerProps, ref as any)

  return (
    <div className="select-wrapper">
      <HiddenSelect
        state={state}
        triggerRef={ref as any}
        name={props.value}
      />
      <button type="button" {...buttonProps} ref={ref}>
        <span {...valueProps}>
          {state.selectedItem ? state.selectedItem.rendered : state.selectedKey}
        </span>
        <ArrowDropDownIcon />
      </button>
      {state.isOpen && <ListBoxPopup {...menuProps} state={state} />}
    </div>
  )
}
