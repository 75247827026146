import React from 'react'

type ErrorBoundaryProps = {
  children: React.ReactNode,
  errorComponent: () => JSX.Element,
  logError: (error: Error, errorMessage: string) => void,
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    this.props.logError(error, 'Error while rendering page')
  }

  render() {
    if (this.state.hasError) {
      const ErrorComponent = this.props.errorComponent
      return <ErrorComponent />
    }

    return this.props.children
  }
}
