import {
  objectToFormData, useConfig,
} from '@logic/platform-core'
import { AxiosInstance } from 'axios'
import { useAxios } from '../../infrastructure/useAxios'
import {
  GatewayPatchModel, GatewayProductConsumersModel, GatewayProductModel, GatewayVisibility,
} from './GatewayProductModel'
import {
  GatewayApiModel, GatewayApiVersionSetModel, GatewayCurrentVersionModel,
} from './GatewayApiModel'

export type ProductItem = {
  id: string,
  key: string,
  name: string,
  visibility: GatewayVisibility,
}

const onApiError = (reason: any) => {
  if (reason.response.status === 400 || reason.response.status === 409 || reason.response.status === 422) {
    return reason.response
  } else {
    return Promise.reject(reason)
  }
}

const onError = (reason: any) => {
  if (reason.response.status === 400 || reason.response.status === 409) {
    return Promise.reject(reason.response.data)
  } else {
    return Promise.reject(reason)
  }
}

const getProviderProducts = (client: AxiosInstance) => (providerId?: string) => {
  return client.get(`/provider/${providerId}/products`).then<ProductItem[]>((response) => response.data.map((product: any) => ({
    id: product.id,
    key: product.key,
    name: product.name,
    visibility: product.visibility,
  })))
}

const createProduct = (client: AxiosInstance) => (product:GatewayProductModel, subscriptionId: string) => {
  const productFormData = objectToFormData(product)
  return client.post(`/subscriptions/${subscriptionId}/gateway/products`, productFormData)
    .then((response) => response, onError)
}

const updateProduct = (client: AxiosInstance) => (product:GatewayProductModel, subscriptionId: string, productId : string) => {
  const productFormData = objectToFormData(product)
  return client.put(`/subscriptions/${subscriptionId}/gateway/products/${productId}`, productFormData)
    .then((response) => response, onError)
}

const loadGatewayProduct = (client: AxiosInstance) => (subscriptionId: string, productId : string) => {
  return client.get(`/subscriptions/${subscriptionId}/gateway/products/${productId}`).then<GatewayProductModel | undefined>((response) => response.data)
}

const deleteProduct = (client: AxiosInstance) => (subscriptionId: string, productId : string) => {
  return client.delete(`/subscriptions/${subscriptionId}/gateway/products/${productId}`)
    .then(() => { /* no-operation */ })
    .catch((reason) => {
      return reason.response.status === 400 ? Promise.reject(reason.response.data) : Promise.reject(reason)
    })
}

const patchProduct = (client: AxiosInstance) => (product:GatewayPatchModel, subscriptionId: string, productId : string) => {
  return client.patch(`/subscriptions/${subscriptionId}/gateway/products/${productId}`, { visibility: product.visibility })
    .then((response) => response.data)
}

const getApisByProductId = (client: AxiosInstance) => (productId: string, subscriptionId: string): Promise<GatewayApiModel[]> => {
  return client.get(`/subscriptions/${subscriptionId}/gateway/products/${productId}/apis`).then<GatewayApiModel[]>((response) => response.data)
}

const createApi = (client: AxiosInstance) => (api: GatewayCurrentVersionModel, subscriptionId: string) => {
  const apiFormData = objectToFormData(api)
  return client.post(`/subscriptions/${subscriptionId}/gateway/apis`, apiFormData).then((res) => res)
    .catch((reason) => {
      return onApiError(reason)
    })
}

const createApiVersion = (client: AxiosInstance) => (apiVersion: GatewayCurrentVersionModel, subscriptionId: string) => {
  const apiFormData = objectToFormData(apiVersion)
  return client.post(`/subscriptions/${subscriptionId}/gateway/apis?apiVersionSetId=${apiVersion.apiVersionSetId}`, apiFormData).then((res) => res)
    .catch((reason) => {
      return onApiError(reason)
    })
}

const fetchApiVersionSet = (client: AxiosInstance) => (subscriptionId: string, apiVersionSetId: string):
 Promise<GatewayApiVersionSetModel | undefined> => {
  return client.get(`/subscriptions/${subscriptionId}/gateway/api-sets/${apiVersionSetId}`)
    .then((response) => response.data)
}

const updateApiVersion = (client: AxiosInstance) => (apiVersion: GatewayCurrentVersionModel, subscriptionId: string) => {
  const apiFormData = objectToFormData(apiVersion)
  return client.put(`/subscriptions/${subscriptionId}/gateway/apis/${apiVersion.apiId}`, apiFormData).then((res) => res)
    .catch((reason) => {
      return onApiError(reason)
    })
}

const deleteApiVersion = (client: AxiosInstance) => (subscriptionId: string, apiId: string,) => {
  return client.delete(`subscriptions/${subscriptionId}/gateway/apis/${apiId}`)
}

const updateApiVersionIsCurrent = (client: AxiosInstance) => (subscriptionId: string, apiId: string): Promise<GatewayCurrentVersionModel[]> => {
  return client.patch(`subscriptions/${subscriptionId}/gateway/apis/${apiId}/?isCurrent=${true}`)
    .then((response) => response.data)
}

const isProductNameUnique = (client: AxiosInstance) => (productName: string) => {
  return client.get(`gateway/products/is-unique/productName?productName=${productName}`)
    .then((response) => response)
}

const isProductKeyUnique = (client: AxiosInstance) => (productKey: string) => {
  return client.get(`gateway/products/is-unique/productKey?productKey=${productKey}`)
    .then((response) => response)
}

const getProductConsumers = (client: AxiosInstance) => (subscriptionId: string, productId: string): Promise<GatewayProductConsumersModel[]> => {
  return client.get(`/subscriptions/${subscriptionId}/gateway/products/${productId}/consumers`).then((response) => response.data)
}

export type GatewayClient = {
  getProviderProducts: (providerId?: string) => Promise<ProductItem[]>,
  createProduct: (product:GatewayProductModel, subscriptionId: string) => Promise<any>,
  updateProduct: (product:GatewayProductModel, subscriptionId: string, productId: string) => Promise<any>,
  loadGatewayProduct: (subscriptionId: string, productId: string) => Promise<GatewayProductModel | undefined>,
  deleteProduct: (subscriptionId: string, productId: string) => Promise<any>,
  patchProduct: (product:GatewayPatchModel, subscriptionId: string, productId : string) => Promise<any>,
  getApisByProductId: (productId: string, subscriptionId: string) => Promise<GatewayApiModel[]>,
  createApi: (api: GatewayCurrentVersionModel, subscriptionId: string) => Promise<any>,
  createApiVersion:(apiVersion: GatewayCurrentVersionModel, subscriptionId:string) => Promise<any>,
  fetchApiVersionSet: (subscriptionId: string, apiVersionSetId: string) => Promise<GatewayApiVersionSetModel| undefined>,
  updateApiVersion: (apiVersion: GatewayCurrentVersionModel, subscriptionId: string) => Promise<any>,
  deleteApiVersion: (subscriptionId: string, apiId: string,) => Promise<any>,
  updateApiVersionIsCurrent: (subscriptionId: string, apiId: string) => Promise<any>,
  isProductNameUnique: (productName: string) => Promise<any>,
  isProductKeyUnique: (productKey: string) => Promise<any>,
  getProductConsumers: (subscriptionId: string, productId: string) => Promise<GatewayProductConsumersModel[]>,
}

const createGatewayClient = (client: AxiosInstance): GatewayClient => {
  return {
    getProviderProducts: getProviderProducts(client),
    createProduct: createProduct(client),
    updateProduct: updateProduct(client),
    loadGatewayProduct: loadGatewayProduct(client),
    deleteProduct: deleteProduct(client),
    patchProduct: patchProduct(client),
    getApisByProductId: getApisByProductId(client),
    createApi: createApi(client),
    fetchApiVersionSet: fetchApiVersionSet(client),
    updateApiVersion: updateApiVersion(client),
    deleteApiVersion: deleteApiVersion(client),
    updateApiVersionIsCurrent: updateApiVersionIsCurrent(client),
    createApiVersion: createApiVersion(client),
    isProductNameUnique: isProductNameUnique(client),
    isProductKeyUnique: isProductKeyUnique(client),
    getProductConsumers: getProductConsumers(client),
  }
}

export const useGatewayClient = (): GatewayClient => {
  const { labPlatformApiUrl } = useConfig() as any
  return useAxios(labPlatformApiUrl, (client) => createGatewayClient(client))
}
