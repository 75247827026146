import React from 'react'
import { Logger } from 'structured-log'

type LoggingContextProps = {
  logger: Logger,
}

const LoggingContext = React.createContext<LoggingContextProps | undefined>(undefined)

export const LoggingProvider: React.FC<{ logger: any }> = ({ children, logger }) => (
  <LoggingContext.Provider value={{ logger }}>{children}</LoggingContext.Provider>
)

export const useLogger = (): Logger => {
  const context = React.useContext<LoggingContextProps | undefined>(LoggingContext)
  if (!context) {
    throw Error('You cannot use logger outside of the context')
  }

  return context.logger
}
