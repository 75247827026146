import React, { ReactNode } from 'react'
import './Accordion.scss'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Skeleton } from '../Skeleton/Skeleton'

type AccordionItemPanelProps = AccordionItemProps & {
  setActiveItem: (value: string | undefined) => void,
  activeItem: string | undefined,
}
const AccordionItemPanel = (props: AccordionItemPanelProps) => (
  <div className="accordion-item">
    <button type="button" className="accordion-header" onClick={() => props.setActiveItem(props.activeItem === props.name ? undefined : props.name)}>
      <div className="heading">{props.heading}</div>
      <div className="subheading">{props.subheading}</div>
      <div className="arrow">{props.activeItem === props.name ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
    </button>
    {props.name === props.activeItem && <div>{props.children}</div>}
  </div>
)

const AccordionItemSkeleton = () => (
  <div className="accordion-item">
    <div className="accordion-header">
      <div className="heading"><Skeleton width={150} /></div>
      <div className="subheading"><Skeleton width={100} /></div>
    </div>
  </div>
)

export type AccordionProps = {
  children?: ReactNode,
  loading?: boolean,
  role?: any,
}

export const Accordion = ({ children, loading, ...otherProps }: AccordionProps): JSX.Element => {
  const [activeItem, setActiveItem] = React.useState<string | undefined>()
  const items = React.Children.map(children || [] as any, ({ props }) => props)

  let content
  if (loading) {
    content = Array(3).fill(<AccordionItemSkeleton />)
  } else {
    content = items.map((props: AccordionItemProps) => (
      <AccordionItemPanel key={props.name} {...props} setActiveItem={setActiveItem} activeItem={activeItem} />
    ))
  }

  return (
    <div className="accordion" {...otherProps} aria-busy={loading}>
      {content}
    </div>
  )
}

export type AccordionItemProps = {
  name: string,
  heading: string,
  subheading: string,
  children?: ReactNode,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AccordionItem = (props: AccordionItemProps): JSX.Element => {
  return <React.Fragment />
}
