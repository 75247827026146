import { useMemo } from 'react'
import { useUserSubscriptions } from './UserSubscriptionsProvider'

export const useSubscriptionRole = (subscriptionId: string): string => {
  const { subscriptions } = useUserSubscriptions()
  return useMemo(() => {
    const [subscription] = subscriptions.filter((x) => x.id === subscriptionId)
    return subscription?.role || ''
  }, [subscriptionId, subscriptions])
}
