import React from 'react'
import {
  Page,
  FormSkeleton,
  PageHeader,
} from '@logic/platform-fabric'
import { useParams } from 'react-router-dom'
import './ResourceSummary.scss'
import { useContextSubscriptionId } from '@logic/platform-core'
import { useClientCredentials } from '../client-credentials/useClientCredentials'
import { useProduct } from './useProduct'
import { useApiKey } from './api-keys/ApiKeyPage'
import {
  GetStartedMarkDownDocument, ResourceApikeys, ResourceClientCredentials, ResourceDetails,
} from './ResourceDetails'
import { ClientCredentials } from '../client-credentials/ClientCrendetialsClient'

export const ResourceGetStarted = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { value: defaultCC, refresh } = useClientCredentials(subscriptionId)
  const { key, apiKeyId } = useParams<{ key:string, apiKeyId:string }>()
  const { value: productInfo, completed } = useProduct(key)
  const { value: apiKeys } = useApiKey(apiKeyId)

  let selectedCC: ClientCredentials[] | undefined
  (apiKeys?.clientCredentialId)
    && (selectedCC = defaultCC?.filter((ccId) => ccId.clientId === apiKeys.clientCredentialId))
  const selectedCCValues = selectedCC?.[0]
  const defaultCCValues = defaultCC?.[0]

  return (
    <Page loading={!completed} skeleton={FormSkeleton}>
      <PageHeader heading="Getting Started" />
      <GetStartedMarkDownDocument productInfo={productInfo} />
      <ResourceDetails apiKeyName={apiKeys?.name} apiKeyId={apiKeyId} keyValue={key} subscriptionId={subscriptionId} />
      <ResourceClientCredentials
        selectedCCValues={selectedCCValues}
        defaultCCValues={defaultCCValues}
        productInfo={productInfo}
        refresh={refresh}
      />
      {apiKeys && <ResourceApikeys />}
    </Page>
  )
}
