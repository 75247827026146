import React from 'react'
import { BaseFieldControlProps } from './BaseFieldControlProps'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import { useToggleState } from '@react-stately/toggle'
import { useFocusRing } from '@react-aria/focus'
import { useCheckbox } from '@react-aria/checkbox'
import { useField } from 'formik'

export type CheckboxFieldProps = {
  type: 'checkbox',
  enabledValue?: any,
  disabledValue?: any,
}

export type CheckboxFieldControlProps = CheckboxFieldProps & BaseFieldControlProps

export const CheckboxFieldControl = (props: CheckboxFieldControlProps): JSX.Element => {
  const [, { value }, { setValue }] = useField(props.name)
  const isSelected = React.useMemo(() => {
    if (props.enabledValue && props.enabledValue === value) return true
    if (props.disabledValue && props.disabledValue === value) return false
    return value === true
  }, [value, props.enabledValue, props.disabledValue])
  const onChange = React.useCallback((selected: boolean) => {
    setValue(selected ? (props.enabledValue || true) : (props.disabledValue || false))
  }, [setValue, props.enabledValue, props.disabledValue])
  const state = useToggleState({ isSelected, onChange, ...props })
  const ref = React.useRef<HTMLInputElement>()
  const { inputProps } = useCheckbox(props, state, ref as any)
  const { focusProps } = useFocusRing()

  return (
    <div className="field-checkbox">
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref as any} />
      </VisuallyHidden>
      <svg width={28} height={28} aria-hidden="true" style={{ marginRight: 4 }}>
        <rect
          x={1}
          y={1}
          width={26}
          height={26}
          fill={state.isSelected ? '#1676ff' : '#fff'}
          stroke="#aaaab4"
          rx={4}
          strokeWidth={1}
        />
        {state.isSelected && (
          <path
            fill="#fff"
            transform="translate(7 7) scale(1.5)"
            d={`M3.788 9A.999.999 0 0 1 3 8.615l-2.288-3a1 1 0 1 1
              1.576-1.23l1.5 1.991 3.924-4.991a1 1 0 1 1 1.576 1.23l-4.712
              6A.999.999 0 0 1 3.788 9z`}
          />
        )}
      </svg>
    </div>
  )
}
