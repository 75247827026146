import React, { ReactNode } from 'react'
import { useLocation, Link } from 'react-router-dom'
import './Tabs.css'

export type TabsProps = {
  children?: ReactNode,
}

export const Tabs = ({ children }: TabsProps): JSX.Element => {
  const location = useLocation()
  const tabs = React.Children.map(children || [] as any, ({ props }: { props: TabProps }) => props)
  const activeTab = tabs.filter((tab) => `#${tab.name}` === location.hash)[0] || tabs[0]

  return (
    <React.Fragment>
      <ul className="tabs">
        {tabs.map((tab) => (<li key={tab.name}><Link to={{ state: undefined, hash: tab.name }} className={activeTab.name === tab.name ? 'active-tab' : ''}>{tab.title}</Link></li>))}
      </ul>
      <div>
        {activeTab.children}
      </div>
    </React.Fragment>
  )
}

export type TabProps = {
  title: string,
  name: string,
  children?: ReactNode,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Tab = (props: TabProps): JSX.Element => {
  return <React.Fragment />
}
