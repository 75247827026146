import React from 'react'

export type Config = {
  labPlatformApiUrl: string,
  adminSubscriptionId: string,
  clientCredentialsAppId: string,
}

const ConfigContext = React.createContext<Config | undefined>(undefined)

export function useConfig<T extends Config>(): T
export function useConfig(): Config {
  const context = React.useContext(ConfigContext)
  if (!context) {
    throw Error('You cannot use `useConfig` outside of `ConfigProvider`')
  }

  return context
}

export const ConfigProvider: React.FC<{ url: string }> = ({ url, children }) => {
  const [config, setConfig] = React.useState<Config | undefined>(undefined)
  React.useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then(setConfig)
  }, [url])
  return (
    <ConfigContext.Provider value={config}>
      {config ? children : null}
    </ConfigContext.Provider>
  )
}

export const MemoryConfigProvider: React.FC<{ config: Config }> = ({ config, children }) => {
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}
