import {
  useConfig,
  useContextSubscriptionId,
  useNotifications,
} from '@logic/platform-core'
import {
  Button,
  Dialog,
  Field,
  Form,
  useSimpleForm,
} from '@logic/platform-fabric'
import React from 'react'
import { useSubscriptionUserClient } from './SubscriptionUserClient'

type InviteDialogProps = {
  isOpen: boolean,
  onClose: () => void,
}

const useInviteForm = (onClose: () => void) => {
  const initialValues = {
    email: '',
    role: 'Contributor',
  }
  const { redirectUri } = useConfig<any>()
  const subscriptionId = useContextSubscriptionId()
  const client = useSubscriptionUserClient()
  const { addInfo } = useNotifications()
  return useSimpleForm(initialValues, undefined, (values) => {
    return client.inviteUser(subscriptionId, {
      emailAddress: values.email,
      role: values.role,
      responseUrlPattern: `${redirectUri}/subscriptions/${subscriptionId}/invitations/:invitationId/respond`,
    }).then(() => {
      addInfo('Invitation has been sent')
      onClose()
    })
  })
}

const InviteDialog = ({ isOpen, onClose }: InviteDialogProps) => {
  const form = useInviteForm(onClose)
  const roles = React.useMemo(() => {
    return ['Owner', 'Contributor'].map((role) => ({ value: role, text: role }))
  }, [])
  return (
    <Dialog isOpen={isOpen} title="Invite user">
      <p>
        Do you want more people to join? Send them invitation.
      </p>
      <Form {...form}>
        <Field type="text" name="email" label="E-mail" validators={['required', 'email']} />
        <Field type="dropdown" name="role" label="User role" options={roles} />
        <div style={{ marginTop: 40 }}>
          <Button type="submit" variant="primary">Invite</Button>
          <Button type="button" onPress={() => onClose()}>Cancel</Button>
        </div>
      </Form>
    </Dialog>
  )
}

export const InviteButton = (): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <React.Fragment>
      <InviteDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Button variant="primary" onPress={() => setIsOpen(true)}>Invite user</Button>
    </React.Fragment>
  )
}
