import React, { useState, useEffect } from 'react'
import {
  useContextSubscriptionId, useNotifications,
} from '@logic/platform-core'
import {
  useSimpleForm, Form, Field, Button, Section, FieldInstruction, CancelButton, SectionSkeleton,
} from '@logic/platform-fabric'
import { useGatewayClient } from './GatewayClient'
import {
  GatewayProductModel, GatewaySynchronization, GatewayVisibility,
} from './GatewayProductModel'
import { useHistory, useParams } from 'react-router-dom'
import './ProductSettingsTab.scss'
import debounce from 'lodash.debounce'
import { useGatewayProduct } from './GatewayProductPage'
import { useField } from 'formik'

export const useProductPageForm = (subscriptionId: string, productId: string) => {
  const client = useGatewayClient()
  const history = useHistory()
  const { addInfo, addError } = useNotifications()

  const initialValues: GatewayProductModel = React.useMemo(() => ({
    apiIds: [],
    apiKeyRequired: false,
    description: '',
    documentationUrl: '',
    getStartedUrl: '',
    id: '',
    logoUrl: '',
    name: '',
    key: '',
    productTerms: '',
    providerId: '',
    synchronization: GatewaySynchronization.Active,
    visibility: GatewayVisibility.Public,
    applicationId: '',
    openidConfigIssuer: '',
    openidConfigCustomUrl: '',
    contactProvider: '',
  }), [])

  const loadProduct = React.useCallback(() => {
    return client.loadGatewayProduct(subscriptionId, productId).then((data) => data || initialValues)
  }, [client, subscriptionId, productId, initialValues])

  const saveProduct = async (data: any) => {
    const axiosResponse = data.id ? await client.updateProduct(data, subscriptionId, data.id).catch((error) => addError(error))
      : await client.createProduct(data, subscriptionId).catch((error) => addError(error))
    if (axiosResponse.status === 200 || axiosResponse.status === 201) {
      addInfo(data.id ? 'Product has been updated' : 'Product has been added')
      if (!data.id) {
        history.push(`/subscriptions/${subscriptionId}/provider/products/${axiosResponse.data.id}`)
      }
    }
  }
  return useSimpleForm(initialValues, (productId ? loadProduct : undefined), saveProduct)
}

export const CheckBoxFields = (): JSX.Element => {
  const [, { value: apiKeyRequired }, { setValue: setApiKeyRequired }] = useField('apiKeyRequired')
  const [, { value: clientCredentialRequired }, { setValue: setClientCredentialRequired }] = useField('clientCredentialRequired')
  const [, { value: openidConfigIssuer }, { setValue: setOpenidConfigIssuer }] = useField('openidConfigIssuer')
  const [, { value: applicationId }, { setValue: setApplicationId }] = useField('applicationId')
  const [, { value: providerApprovalRequired }, { setValue: setProviderApprovalRequired }] = useField('providerApprovalRequired')
  const [, { value: openidConfigCustomUrl }, { setValue: setOpenidConfigCustomUrl }] = useField('openidConfigCustomUrl')

  const IdentityKmdlogicIo = 'IdentityKmdlogicIo'
  const LogicidentityprodB2clogicCom = 'LogicidentityprodB2clogicCom'
  const Custom = 'Custom'

  const domains = [
    { value: IdentityKmdlogicIo, text: 'identity.kmdlogic.io' },
    { value: LogicidentityprodB2clogicCom, text: 'logicidentityprod.b2clogic.com' },
    { value: Custom, text: 'other' },
  ]

  useEffect(() => {
    if (!apiKeyRequired) {
      setApiKeyRequired(false)
      setClientCredentialRequired(false)
      setOpenidConfigIssuer('')
      setApplicationId('')
      setProviderApprovalRequired(false)
      setOpenidConfigCustomUrl('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!apiKeyRequired, clientCredentialRequired, openidConfigIssuer, applicationId, providerApprovalRequired, openidConfigCustomUrl])

  useEffect(() => {
    if (!clientCredentialRequired) {
      setOpenidConfigIssuer('')
      setApplicationId('')
      setOpenidConfigCustomUrl('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!clientCredentialRequired, openidConfigIssuer, applicationId, openidConfigCustomUrl])

  return (
    <React.Fragment>
      <Field type="checkbox" label="Requires API Key" name="apiKeyRequired" />
      {apiKeyRequired
        && (
          <React.Fragment>
            <Field label="Requires Client Credentials" name="clientCredentialRequired" type="checkbox" />
            {clientCredentialRequired
              && (
                <React.Fragment>
                  <Field name="applicationId" label="Application Id" type="text" validators={['required']} />
                  <Field label="Issuer Domain" name="openidConfigIssuer" type="dropdown" options={domains} optionLabel="Select" />
                  {(openidConfigIssuer === Custom)
                    && <Field name="openidConfigCustomUrl" label="Custom openid-config url" type="text" validators={['required', 'url']} />
                  }
                </React.Fragment>
              )}
            <Field label="Requires Approval" name="providerApprovalRequired" type="checkbox" />
          </React.Fragment>
        )}
    </React.Fragment>
  )
}

export const ProductSettingsTab = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { productId } = useParams<{ productId: string }>()
  const { loading, ...form } = useProductPageForm(subscriptionId, productId)
  const client = useGatewayClient()
  const { value: productDetails } = useGatewayProduct(subscriptionId, productId)
  const [productName, setProductName] = useState('')
  const [apiCall, setApiCall] = useState(true)
  const [productNameError, setProductNameError] = useState<string | undefined>()

  const [productKey, setProductKey] = useState('')
  const [keyApiCall, setKeyApiCall] = useState(true)
  const [productKeyError, setProductKeyError] = useState<string | undefined>()

  if (loading) {
    return <SectionSkeleton />
  }

  const isValidKey = async (value: string) => {
    const message = 'Key can contain only lowercase letters, numbers and hyphen'
    const uniqueKeyError = `Product with key ${value} already exists`
    setProductKey(value)
    debounce(() => productKey, 300)
    if (!value.match(/^[a-z0-9-]*$/)) {
      setProductKeyError(message)
    } else if (value !== productKey) {
      setKeyApiCall(true)
      setProductKeyError(undefined)
    } else if (value === productKey && productDetails?.key !== value && keyApiCall === true) {
      await client.isProductKeyUnique(value).catch((uniqueKey) => {
        if (uniqueKey.response.status === 409) {
          setProductKeyError(uniqueKeyError)
        }
      })
      setKeyApiCall(false)
    }
    return productKeyError
  }

  const isValidName = async (value: string) => {
    const uniqueNameError = `Product with name ${value} already exists`
    setProductName(value)
    debounce(() => productName, 300)
    if (value !== productName) {
      setApiCall(true)
      setProductNameError(undefined)
    } else if (value === productName && productDetails?.name !== value && apiCall === true) {
      await client.isProductNameUnique(value).catch((uniqueName) => {
        if (uniqueName.response.status === 409) {
          setProductNameError(uniqueNameError)
        }
      })
      setApiCall(false)
    }
    return productNameError
  }

  return (
    <Form {...form}>
      <Section className="two-columns">
        <div className="field-base">
          <Field name="name" label="Name" type="text" validators={['required']} validate={isValidName} />
          <Field name="key" label="Key" type="text" validators={['required']} validate={isValidKey} />
        </div>
        <FieldInstruction>
          Key can contain only lowercase letters, numbers and hyphen
        </FieldInstruction>
        <Field name="description" label="Description" type="multiline" />
        <CheckBoxFields />
        <Field name="productTerms" label="Legal Terms" type="multiline" />
      </Section>
      <Section className="two-columns">
        <Field name="logo" label="Logo" type="image" accept="image/*" />
        <Field name="documentation" label="Overview" type="file" accept=".md" />
        <Field name="getStarted" label="GetStarted Note" type="file" accept=".md" />
        <Field name="contactProvider" label="Contact details" type="multiline" />
        <FieldInstruction>
          Contact details supports Markdown syntax
        </FieldInstruction>
      </Section>
      <Button type="submit" variant="primary">Save</Button>
      <CancelButton defaultBackTo={`/subscriptions/${subscriptionId}/provider/products`} />
    </Form>
  )
}
