import React, { useState } from 'react'
import {
  Dialog, Button, Field, Form, useSimpleForm,
} from '@logic/platform-fabric'

type DeleteDialogProps = {
  title: string,
  resourceName?: string,
  isOpen: boolean,
  onClose: (result: DeleteDialogResult) => void,
  onSubmit: () => Promise<any>,
}

const useForm = (onSubmit: () => Promise<any>, onClose: (result: DeleteDialogResult) => void) => {
  const initialValues = React.useMemo(() => ({
    title: '',
    deleteName: '',
  }), [])

  const saveForm = () => {
    return onSubmit().then(() => {
      return onClose({ answer: 'delete' })
    })
  }
  return useSimpleForm(initialValues, undefined, saveForm)
}

export const DeleteDialog = ({ title, resourceName, isOpen, onClose, onSubmit }: DeleteDialogProps): JSX.Element => {
  const { ...form } = useForm(onSubmit, onClose)

  const [disableButton, setdisableButton] = useState(true)

  const isValidName = (value: string, label: string) => {
    const errMessage = `${label}`;
    (value === resourceName) ? setdisableButton(false) : setdisableButton(true)
    return (value !== resourceName) ? errMessage : undefined
  }

  return (
    <Dialog isOpen={isOpen} title={title}>
      <Form {...form}>
        <Field name="displayName" label={`Please enter "${resourceName}" to delete`} validate={isValidName} />
        <div>
          <Button type="submit" variant="primary" disabled={disableButton}>Delete</Button>
          <Button onPress={() => onClose({ answer: 'cancel' })}>Cancel</Button>
        </div>
      </Form>
    </Dialog>
  )
}

const DeleteDialogController = ({ deleteRef, component: Component }: any) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [dialogTitle, setDialogTitle] = React.useState('')
  const [dialogResourceName, setDialogResourceName] = React.useState('')
  const resolveRef = React.useRef<((result: DeleteDialogResult) => void) | undefined>(undefined)
  const deleteResourceRef = React.useRef<((result: DeleteDialogResult) => void) | undefined>(undefined)

  const onClose = (result: DeleteDialogResult) => {
    resolveRef.current && resolveRef.current(result)
  }

  const onSubmit = (res: DeleteDialogResult) => {
    return deleteResourceRef.current && deleteResourceRef.current(res)
  }

  const confirmDeletion = React.useCallback((title: string, resourceName: string, deleteResource: () => Promise<any>) => {
    setDialogResourceName(resourceName)
    setDialogTitle(title)
    setIsOpen(true)
    return new Promise((resolve) => {
      resolveRef.current = resolve
      deleteResourceRef.current = deleteResource
    }).then((result) => {
      setIsOpen(false)
      setDialogTitle('')
      setDialogResourceName('')
      return result
    })
  }, [setDialogTitle, setIsOpen])

  React.useEffect(() => {
    deleteRef.current = confirmDeletion
  }, [confirmDeletion, deleteRef])

  return (isOpen === true ? (
    <Component
      isOpen={isOpen}
      title={dialogTitle}
      resourceName={dialogResourceName}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  )
    : <React.Fragment />)
}

export type DeleteDialogResult = {
  answer: 'delete' | 'cancel',
}

type DeleteDialogContextProps = {
  confirmDeletion: (title: string, resourceName: string, deleteResource: () => Promise<void>) => Promise<DeleteDialogResult>,
}

export const DeleteDialogContext = React.createContext<DeleteDialogContextProps | undefined>(undefined)

type DeleteDialogProviderProps = {
  component: React.ReactNode,
}

export const DeleteDialogProvider: React.FC<DeleteDialogProviderProps> = ({ component, children }) => {
  const confirmDeletionRef = React.useRef<((title: string, resourceName: string, deleteResource: () => Promise<void>) =>
  Promise<DeleteDialogResult>) | undefined>(undefined)
  const value = {
    confirmDeletion: (title: string, resourceName: string, deleteResource: () => Promise<void>) => {
      if (confirmDeletionRef.current) {
        return confirmDeletionRef.current(title, resourceName, deleteResource)
      } else {
        return Promise.reject(Error('The reference to function `confirmDeletion` is not defined'))
      }
    },
  }
  return (
    <DeleteDialogContext.Provider value={value}>
      {children}
      <DeleteDialogController deleteRef={confirmDeletionRef} component={component} />
    </DeleteDialogContext.Provider>
  )
}

export const useDeleteDialog = (): DeleteDialogContextProps => {
  const context = React.useContext<DeleteDialogContextProps | undefined>(DeleteDialogContext)
  if (!context) {
    throw Error('You cannot call `useDeleteDialog` out of the DeleteDialogContext')
  }

  return context
}
