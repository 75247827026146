import React from 'react'

export type SimpleForm<T> = {
  initialValues: T | undefined,
  onSubmit: (data: T) => Promise<void>,
  loading: boolean,
  error: any,
}

export const useSimpleForm = <T>(
  defaultInitialValues: T | undefined,
  loadInitialValues: (() => Promise<T>) | undefined,
  onSubmit: (data: T) => Promise<any>): SimpleForm<T> => {
  const [initialValues, setInitialValues] = React.useState<T | undefined>(defaultInitialValues)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<any>(undefined)
  React.useEffect(() => {
    if (loadInitialValues) {
      setLoading(true)
      loadInitialValues().then((value) => {
        setInitialValues(value)
        setLoading(false)
        setError(undefined)
      }).catch((err) => {
        setInitialValues(undefined)
        setLoading(false)
        setError(err)
      })
    }
  }, [loadInitialValues])

  return React.useMemo(() => ({
    initialValues,
    onSubmit,
    loading,
    error,
  }), [initialValues, onSubmit, loading, error])
}
