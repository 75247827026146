import React from 'react'
import { Button } from '@logic/platform-fabric'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { JsonSnippet } from './CodeSnippet'
import {
  Formik,
  Form,
  Field,
  useField,
} from 'formik'
import './Playground.scss'
import { Operation, sampleJson } from './OpenApiDescription'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs'
import 'prismjs/components/prism-json'

type PlaygroundContentTypeProvider = {
  initialValues: (code: any) => any,
  createRequest: (body: any) => any,
  requestForm: ({ operation }: any) => JSX.Element,
}

const formdataProvider = (): PlaygroundContentTypeProvider => {
  return {
    initialValues: () => ({ body: new FormData() }),
    createRequest: (body) => {
      const form = new FormData()
      Object.entries(body).forEach(([key, value]: any) => form.append(key, value))
      return {
        headers: [
        ],
        body: form,
      }
    },
    requestForm: ({ operation }: any) => {
      return (
        <React.Fragment>
          {Object.entries<any>(operation.requestContent['multipart/form-data']?.schema.properties || {}).map(([key, field]) => (
            <div className="playground-field">
              <label>{key}</label>
              <Field name={`body.${key}`} type={field.format === 'binary' ? 'file' : undefined} />
            </div>
          ))}
        </React.Fragment>
      )
    },
  }
}

const JsonRequestForm = () => {
  const [{ value }, , { setValue }] = useField('body')
  return (
    <div className="textarea">
      <Editor
        value={value}
        onValueChange={setValue}
        highlight={(code) => highlight(code, languages.json, 'json')}
      />
    </div>
  )
}

const jsonProvider = (): PlaygroundContentTypeProvider => {
  return {
    initialValues: (code) => ({ body: code }),
    createRequest: (body) => {
      return {
        headers: [
          ['Content-Type', 'application/json'],
        ],
        body,
      }
    },
    requestForm: JsonRequestForm,
  }
}

const defaultProvider: PlaygroundContentTypeProvider = {
  initialValues: () => ({}),
  createRequest: () => {
    return {}
  },
  requestForm: () => <React.Fragment />,
}

const contentTypeProviders = {
  'multipart/form-data': formdataProvider(),
  'application/json': jsonProvider(),
}

const getSecurityHeaders = (security: any = {}, securitySchemes: any = {}) => {
  return Object
    .entries<any>(securitySchemes)
    .filter(([key]) => security[key])
    .map(([key, entry]) => {
      if (entry.scheme === 'bearer') {
        return ['Authorization', `Bearer ${security[key]}`]
      } else if (entry.in === 'header') {
        return [entry.name, security[key]]
      } else {
        return undefined
      }
    })
    .filter((x) => x) as string[][]
}

type PlaygroundRequestProps = {
  operation: Operation,
  code: any,
  servers: any,
  securitySchemes: any,
  security: any,
  setResponse: any,
}

const PlaygroundRequest = ({ operation, code, servers, securitySchemes, security, setResponse }: PlaygroundRequestProps) => {
  const contentTypeProvider: PlaygroundContentTypeProvider = operation.requestContent ? Object
    .keys(operation.requestContent)
    .map((contentType) => (contentTypeProviders as any)[contentType])
    .filter((provider) => Boolean(provider))[0] : defaultProvider
  const request = contentTypeProvider.initialValues(code)
  const sendRequest = ({ path, header, body, security: requestSecurity }: any) => {

    const baseUrl = servers[0].url
    const url = baseUrl + Object.entries<any>(path || {}).reduce((acc, [param, value]) => acc.replace(`{${param}}`, value), operation.path)
    const requestInit = contentTypeProvider.createRequest(body)

    return fetch(url, {
      ...requestInit,
      method: operation.method,
      headers: [
        ...(requestInit.headers || []),
        ...Object.entries(header || {}),
        ...getSecurityHeaders(requestSecurity, securitySchemes),
      ],
    }).then((response) => {
      return response.text().then((data) => ({
        status: response.status,
        headers: getHeaders(response),
        data,
      }))
    }).then(setResponse)
  }
  const { requestForm: OperationForm } = contentTypeProvider
  return (
    <div className="playground">
      <Formik initialValues={request} onSubmit={sendRequest}>
        <Form>
          {operation.parameters?.map((parameter: any) => (
            <div className="playground-field" key={parameter.name}>
              <label>{parameter.name}</label>
              <Field name={`${parameter.in}.${parameter.name}`} />
            </div>
          ))}
          {[...(operation.security || []), ...(security || [])]?.map((s: string) => (
            <div className="playground-field" key={s}>
              <label>{s}</label>
              <Field name={`security.${s}`} />
            </div>
          ))}
          <OperationForm operation={operation} />
          <Button variant="light" size="small" icon={PlayArrowIcon} type="submit" />
        </Form>
      </Formik>
    </div>
  )
}

const getHeaders = (response: Response) => {
  const headers: ([key: string, value: string])[] = []
  response.headers.forEach((value, key) => headers.push([key, value]))
  return headers
}

export type PlainResponse = {
  status: number,
  headers: ([key: string, value: string])[],
  data: any,
}

export const PlaygroundResponse = ({ response }: { response: PlainResponse | undefined }) => (
  <React.Fragment>
    {response && (
      <React.Fragment>
        <div className="code-snippet-header">
          Response: {response.status}
        </div>
        <pre>
          {response.headers.map(([key, value]) => `${key}: ${value}`).join('\n')}<br />
          <JsonSnippet json={response.data} />
        </pre>
      </React.Fragment>
    )}
  </React.Fragment>
)

type PlaygroundProps = {
  operation: Operation,
  servers: any,
  schemas: any,
  securitySchemes: any,
  security: any,
}

export const Playground = ({ operation, servers, schemas, security, securitySchemes }: PlaygroundProps): JSX.Element => {
  const [playgroundMode, setPlaygroundMode] = React.useState(false)
  const [response, setResponse] = React.useState<PlainResponse>()
  const requestBody = React.useMemo(() => {
    return JSON.stringify(sampleJson(operation.requestContent?.['application/json']?.schema, schemas), null, 2)
  }, [operation, schemas])
  const responseBody = React.useMemo(() => {
    const responseSchema = operation.responses
      .find(({ statusCode }: any) => ['200', '201', '202'].indexOf(statusCode) !== -1)
      ?.content
      ?.['application/json']
      ?.schema
    return JSON.stringify(sampleJson(responseSchema, schemas), null, 2)
  }, [operation, schemas])
  return (
    <div className="code-snippet">
      <div className="code-snippet-header">
        <div className="title"><span className={`http-method ${operation.method}`}>{operation.method.toUpperCase()}</span> {operation.path}</div>
        <div className="actions"><Button variant="light" size="small" icon={SportsEsportsIcon} onPress={() => setPlaygroundMode(!playgroundMode)} /></div>
      </div>
      <div>
        {playgroundMode === false && (<JsonSnippet json={requestBody} />)}
        {playgroundMode === true && (
          <PlaygroundRequest
            operation={operation}
            servers={servers}
            code={requestBody}
            securitySchemes={securitySchemes}
            security={security}
            setResponse={setResponse}
          />
        )}
      </div>
      <div>
        {playgroundMode === false && responseBody && (
          <React.Fragment>
            <div className="code-snippet-header">Response</div>
            <pre>
              <JsonSnippet json={responseBody} />
            </pre>
          </React.Fragment>
        )}
        {playgroundMode === true && (<PlaygroundResponse response={response} />)}
      </div>
    </div>
  )
}
