import React from 'react'
import {
  ErrorMessage,
  useField,
} from 'formik'
import { FileFieldControl, FileFieldProps } from './FileFieldControl'
import { ImageFieldControl, ImageFieldProps } from './ImageFieldControl'
import { TextFieldControl, TextFieldProps } from './TextFieldControl'
import { DropDownFieldControl, DropDownFieldProps } from './DropDownFieldControl'
import { SwitchFieldControl, SwitchFieldProps } from './SwitchFieldControl'
import { StaticFieldControl, StaticFieldProps } from './StaticFieldControl'
import { RadioGroupFieldControl, RadioGroupFieldProps } from './RadioGroupFieldControl'
import { CheckboxFieldControl, CheckboxFieldProps } from './CheckboxFieldControl'
import * as knownValidators from './validators'
import { BaseFieldControlProps } from './BaseFieldControlProps'
import { useLabel } from '@react-aria/label'
import '../styles/input.scss'

export type Validator = keyof typeof knownValidators

export type FieldValidator = (value: string, label: string) => string | undefined | Promise<string | void>
export type FieldControlProps = TextFieldProps
  | DropDownFieldProps
  | FileFieldProps
  | ImageFieldProps
  | SwitchFieldProps
  | StaticFieldProps
  | RadioGroupFieldProps
  | CheckboxFieldProps
export type FieldProps = FieldControlProps & {
  label: string,
  name: string,
  validators?: Validator[],
  validate?: FieldValidator,
}

export const createValidate = (label: string, validators?: Validator[], validate?: FieldValidator) => {
  return (value: string): string | undefined | Promise<string | void> => {
    if (validators) {
      for (let i = 0; i < validators.length; i++) {
        const validatorName = validators[i]
        const result = knownValidators[validatorName](value, label)
        if (result) {
          return result
        }
      }
    }

    if (validate) {
      return validate(value, label)
    }

    return undefined
  }
}

// eslint-disable-next-line complexity
const FieldControl = (props: FieldControlProps & BaseFieldControlProps) => {
  switch (props.type) {
  case 'text':
  case 'time':
  case 'multiline':
  case 'password':
  case 'email':
  case undefined:
    return <TextFieldControl {...props} />
  case 'dropdown':
    return <DropDownFieldControl {...props} />
  case 'file':
    return <FileFieldControl {...props} />
  case 'image':
    return <ImageFieldControl {...props} />
  case 'switch':
    return <SwitchFieldControl {...props} />
  case 'checkbox':
    return <CheckboxFieldControl {...props} />
  case 'static':
    return <StaticFieldControl {...props} />
  case 'radio':
    return <RadioGroupFieldControl {...props} />
  }
}

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp]

const isInlineControl = (type: PropType<FieldControlProps, 'type'>) => type === 'switch' || type === 'checkbox'
const hasOwnErrorMessage = (type: PropType<FieldControlProps, 'type'>) => type === 'file' || type === 'image'

export const Field = ({ name, label, validators, validate, ...props }: FieldProps): JSX.Element => {
  const aggregatedValidate = createValidate(label, validators, validate)
  const [, meta] = useField(name)
  const { labelProps, fieldProps } = useLabel({ label })
  return (
    <div className={`field ${meta.touched && meta.error ? 'error' : ''}`}>
      <label {...labelProps}>
        {isInlineControl(props.type) ? <FieldControl name={name} {...props} {...fieldProps} validate={aggregatedValidate} /> : undefined} {label}
      </label>
      {!isInlineControl(props.type) ? <FieldControl name={name} {...props} {...fieldProps} validate={aggregatedValidate} /> : undefined}
      {!hasOwnErrorMessage(props.type) ? <div className="message"><ErrorMessage name={name} /></div> : undefined}
    </div>
  )
}
