import React from 'react'
import { NotificationContext, NotificationType } from './NotificationProvider'

export const withNotifications = (Component: React.ComponentType<any>): React.ComponentClass<any> =>
  class extends React.Component<any> {
    render() {
      return (
        <NotificationContext.Consumer>
          {(props) => (
            <Component
              {...this.props as any}
              addInfo={(message: string) => props?.addNotification(message, NotificationType.Info)}
              addError={(message: string) => props?.addNotification(message, NotificationType.Error)}
            />
          )}
        </NotificationContext.Consumer>
      )
    }
  }
