import React from 'react'
import { Data, useData } from '@logic/platform-core'
import { ProductItem, useMarketplaceClient } from './MarketplaceClient'

export const useProducts = (query: string): Data<ProductItem[]> => {
  const client = useMarketplaceClient()
  const { value, ...data } = useData([], () => client.getAllProducts(), [client])
  const filtered = React.useMemo(() => value?.filter((product) => {
    return product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      || product.shortDescription.toLowerCase().indexOf(query.toLowerCase()) !== -1
  }), [value, query])
  return { value: filtered, ...data }
}
