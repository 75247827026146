import { ResourceType } from '@logic/platform-core'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import React from 'react'
import TransformIcon from '@material-ui/icons/Transform'

export const shellResources: ResourceType[] = [
  {
    name: 'Product',
    path: '/subscriptions/:subscriptionId/provider/products/:resourceId',
    icon: (props: any) => <TransformIcon {...props} style={{ color: '#1676ff' }} />,
  },
  {
    name: 'API Key',
    path: '/subscriptions/:subscriptionId/gateway/api-key/:resourceId',
    icon: () => <VpnKeyIcon style={{ color: '#1676ff' }} />,
  },
]
