import React from 'react'
import { Button } from '../Button/Button'
import './Wizard.scss'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { FormikHelpers, useFormikContext } from 'formik'

type WizardProps = {
  children: React.ReactNode,
}

type WizardContextProps = {
  steps: WizardStepProps[],
  activeStep: string,
  goToStep: (step: string) => void,
  goToNextStep: () => void,
  goToPrevStep: () => void,
  hasNextStep: boolean,
  hasPrevStep: boolean,
}

const WizardContext = React.createContext<WizardContextProps | undefined>(undefined)

export const Wizard = ({ children }: WizardProps): JSX.Element => {
  const steps = React.Children
    .map<WizardStepProps, WizardStepProps[]>(children || [] as any, (child: any) => child?.props)
    .filter((x) => Boolean(x))
  const [activeStep, goToStep] = React.useState<string>(steps[0].name)
  const activeStepIndex = steps.findIndex((step) => step.name === activeStep)
  const contextValue = {
    steps,
    activeStep,
    hasNextStep: React.useMemo(() => activeStepIndex + 1 < steps.length, [activeStepIndex, steps.length]),
    hasPrevStep: React.useMemo(() => activeStepIndex > 0, [activeStepIndex]),
    goToStep,
    goToNextStep: React.useCallback(() => {
      const nextStep = steps[activeStepIndex + 1]
      if (nextStep) {
        goToStep(nextStep.name)
      }
    }, [activeStepIndex, steps]),
    goToPrevStep: React.useCallback(() => {
      const prevStep = steps[activeStepIndex - 1]
      if (prevStep) {
        goToStep(prevStep.name)
      }
    }, [activeStepIndex, steps]),
  }

  return (
    <WizardContext.Provider value={contextValue}>
      <div>
        {steps.map((step, index) => (
          <div className={`wizard-step ${step.name === activeStep ? 'active' : (index < activeStepIndex ? 'prev' : '')}`}>
            <div className="wizard-header">
              <div>{index + 1}</div>
              <h3>{step.title}</h3>
            </div>
            <div className="wizard-content">{step.name === activeStep && step.children}</div>
          </div>
        ))}
      </div>
    </WizardContext.Provider>
  )
}

export type WizardStepProps = {
  name: string,
  title?: string,
  children?: React.ReactNode,
}

export const useWizard = (): WizardContextProps => {
  const wizard = React.useContext<WizardContextProps | undefined>(WizardContext)
  if (!wizard) {
    throw Error('You cannot call useWizard out of Wizard scope')
  }

  return wizard
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const WizardStep = (props: WizardStepProps): JSX.Element => (
  <React.Fragment />
)

export type WizardActionsProps = {
  nextActionTitle?: string,
  nextActionIcon?: any,
  nextAction?: string,
  nextButtonType?: 'submit' | 'button',
  backActionTitle?: string,
  backActionIcon?: any,
  backAction?: string,
  validateFields?: string[],
}

const fieldsValid = async (form: FormikHelpers<any>, fields: string[]): Promise<boolean> => {

  const results = []
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    form.setFieldTouched(field, true, false)
    results.push(await form.validateField(field))
  }

  const anyError = results.some((result) => Boolean(result))
  return !anyError
}

export const WizardActions = ({ backAction, nextAction, validateFields, ...props }: WizardActionsProps): JSX.Element => {
  const { goToStep, goToNextStep, goToPrevStep, hasNextStep, hasPrevStep } = useWizard()
  const form = useFormikContext()
  const onNextButtonPress = React.useCallback(() => {
    if (validateFields) {
      return fieldsValid(form, validateFields).then((valid) => {
        if (valid) {
          nextAction ? goToStep(nextAction) : goToNextStep()
        }
      })
    } else {
      nextAction ? goToStep(nextAction) : goToNextStep()
      return undefined
    }
  }, [nextAction, form, goToNextStep, goToStep, validateFields, validateFields?.length]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="wizard-actions">
      {hasPrevStep && (
        <Button
          variant="light"
          icon={props.backActionTitle ? props.backActionIcon : ChevronLeftIcon}
          rounded={true}
          onPress={backAction ? () => goToStep(backAction) : goToPrevStep}
        >
          {props.backActionTitle || 'Back'}
        </Button>
      )}
      <Button
        type={props.nextButtonType || (hasNextStep ? 'button' : 'submit')}
        variant="primary"
        iconLocation="right"
        icon={props.nextActionTitle ? props.nextActionIcon : ChevronRightIcon}
        onPress={onNextButtonPress}
      >
        {props.nextActionTitle || 'Next'}
      </Button>
    </div>
  )
}
