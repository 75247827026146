import { useContextSubscriptionId, useNotifications } from '@logic/platform-core'
import {
  Button,
  Dialog,
  Field,
  Form,
  useSimpleForm,
} from '@logic/platform-fabric'
import { useOverlayTriggerState } from '@react-stately/overlays'
import React, { useState } from 'react'
import { useClientCredentialsClient } from './ClientCrendetialsClient'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import { useHistory } from 'react-router-dom'
import './ClientCredentialsPage.scss'

const useDeleteClientCredentialsDialogForm = (onClose: any, clientId: string) => {
  const subscriptionId = useContextSubscriptionId()
  const { addInfo } = useNotifications()
  const history = useHistory()
  const client = useClientCredentialsClient()
  const deleteClientCredentials = React.useCallback(async () => {
    await client.deleteClientCredentials(subscriptionId, clientId)
    onClose()
    addInfo('Client credentials deleted')
    history.push(`/subscriptions/${subscriptionId}/client-credentials`)
  }, [client, subscriptionId, onClose, clientId, addInfo, history])
  return useSimpleForm({ clientId: clientId }, undefined, deleteClientCredentials)
}

type DeleteClientCredentialsDialogProps = {
  isOpen: boolean,
  close: () => void,
  clientId: string,
  clientName: string,
}

export const DeleteClientCredentialsDialog = ({ isOpen, close, clientId, clientName }: DeleteClientCredentialsDialogProps): JSX.Element => {
  const form = useDeleteClientCredentialsDialogForm(close, clientId)
  const [disableButton, setdisableButton] = useState(true)

  const isValidName = (value: string, label: string) => {
    const message = `Please enter ${label} to delete Client Credentials`;
    (value === clientName) ? setdisableButton(false) : setdisableButton(true)
    return (value !== clientName) ? message : undefined
  }

  return (
    <Dialog isOpen={isOpen} title="Are you sure want to delete Client Credentials?">
      <Form {...form}>
        <Field name="displayName" label={`Please enter "${clientName}" to delete`} validators={['required']} validate={isValidName} />
        <div style={{ marginTop: 40 }}>
          <Button type="submit" variant="primary" disabled={disableButton}>Delete</Button>
          <Button type="button" onPress={() => close()}>Cancel</Button>
        </div>
      </Form>
    </Dialog>
  )
}

export const DeleteClientCredentialsButton = ({ onClose, clientId, clientName }:
     { onClose: () => void, clientId: string, clientName: string }): JSX.Element => {
  const state = useOverlayTriggerState({})
  const close = React.useCallback(() => {
    state.close()
    onClose()
  }, [state, onClose])
  return (
    <React.Fragment>
      <Button className="revoke" type="button" variant="light" icon={WhatshotIcon} onPress={() => state.open()}>Revoke credentials</Button>
      <DeleteClientCredentialsDialog clientId={clientId} clientName={clientName} isOpen={state.isOpen} close={close} />
    </React.Fragment>
  )
}
