import { normalizeBadRequestResponse, useConfig } from '@logic/platform-core'
import { AxiosInstance } from 'axios'
import { useAxios } from '../infrastructure/useAxios'

export type ProductItem = {
  id: string,
  key: string,
  name: string,
  shortDescription: string,
  apiKeyRequired: boolean,
  logoUrl: string,
}

export type ProductDetails = {
  id: string,
  name: string,
  providerName: string,
  key: string,
  subscriptionId: string,
  logoUrl: string,
  documentationUrl: string,
  getStartedUrl: string,
  productTerms: string,
  clientCredentialsRequired: boolean,
  providerApprovalRequired: boolean,
  apiKeyRequired: boolean,
  applicationId: string | undefined,
  contactProvider: string,
}

export type AccessRequest = {
  id: string,
  name: string,
  productId: string,
  requesterName: string,
  requesterEmail: string | undefined,
  requesterPhoneNumber: string | undefined,
  requesterIntent: string,
}

export type Api = {
  id: string,
  name: string,
  versions: ApiVersion[],
}

export type ApiVersion = {
  number: string,
  documentationUrl: string | undefined,
  openApiSpecUrl: string,
}

export type ApiKey = {
  id: string,
  name: string,
  productId: string,
  primaryKey: string,
  secondaryKey: string,
  clientCredentialId: string,
}

export type MarketplaceClient = {
  getAllProducts: () => Promise<ProductItem[]>,
  getProduct: (productKey: string) => Promise<ProductDetails>,
  requestAccess: (subscriptionId: string, model: AccessRequest) => Promise<AccessRequest>,
  getProductApis: (productKey: string) => Promise<Api[]>,
  getProductApiKey: (subscriptionId: string, apiKeyId: string) => Promise<ApiKey | undefined>,
  regeneratePrimaryKey: (subscriptionId: string, apiKeyId: string) => Promise<string>,
  regenerateSecondaryKey: (subscriptionId: string, apiKeyId: string) => Promise<string>,
}

const onError = (reason: any) => {
  if (reason.response.status === 400 || reason.response.status === 409) {
    return Promise.reject(normalizeBadRequestResponse(reason.response.data))
  } else {
    return Promise.reject(reason)
  }
}

const getAllProducts = (client: AxiosInstance) => () => {
  return client.get('/marketplace/products').then<ProductItem[]>((response) => response.data.map((product: any) => ({
    id: product.id,
    key: product.key,
    name: product.name,
    shortDescription: product.shortDescription || '',
    apiKeyRequired: product.apiKeyRequired,
    logoUrl: product.logoUrl,
  })))
}

const getProduct = (client: AxiosInstance) => (productKey: string) => {
  return client.get(`/marketplace/products/${productKey}`).then<ProductDetails>((response) => response.data)
}

const requestAccess = (client: AxiosInstance) => (subscriptionId: string, model: AccessRequest) => {
  return client.post<AccessRequest>(`/subscriptions/${subscriptionId}/gateway/api-keys`, model).then((response) => response.data, onError)
}

const getProductApis = (client: AxiosInstance) => (productKey: string) => {
  return client.get(`/marketplace/products/${productKey}/apis`).then<Api[]>((response) => response.data)
}

const getProductKey = (client: AxiosInstance) => (subscriptionId: string, apiKeyId: string) => {
  return client.get<ApiKey[]>(`/subscriptions/${subscriptionId}/gateway/api-keys`).then((response) => response.data.find((apiKey) => apiKey.id === apiKeyId))
}

const regeneratePrimaryKey = (client: AxiosInstance) => (subscriptionId: string, apiKeyId: string) =>
  client.post(`subscriptions/${subscriptionId}/gateway/api-keys/${apiKeyId}/regenerate-primary`)
    .then((response) => response.data.key)

const regenerateSecondaryKey = (client: AxiosInstance) => (subscriptionId: string, apiKeyId: string) =>
  client.post(`subscriptions/${subscriptionId}/gateway/api-keys/${apiKeyId}/regenerate-secondary`)
    .then((response) => response.data.key)

const createMarketplaceClient = (client: AxiosInstance): MarketplaceClient => {
  return {
    getAllProducts: getAllProducts(client),
    getProduct: getProduct(client),
    requestAccess: requestAccess(client),
    getProductApis: getProductApis(client),
    getProductApiKey: getProductKey(client),
    regeneratePrimaryKey: regeneratePrimaryKey(client),
    regenerateSecondaryKey: regenerateSecondaryKey(client),
  }
}

export const useMarketplaceClient = (): MarketplaceClient => {
  const { labPlatformApiUrl } = useConfig() as any
  return useAxios(labPlatformApiUrl, (client) => createMarketplaceClient(client))
}
