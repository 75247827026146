import React from 'react'
import { useSelectState } from '@react-stately/select'
import { Item } from '@react-stately/collections'
import { useSelect, HiddenSelect } from '@react-aria/select'
import { useButton } from '@react-aria/button'
import { useOverlay, DismissButton } from '@react-aria/overlays'
import { useListBox, useOption } from '@react-aria/listbox'
import { useSearchField } from '@react-aria/searchfield'
import { useSearchFieldState } from '@react-stately/searchfield'
import { FocusScope } from '@react-aria/focus'
import { mergeProps } from '@react-aria/utils'
import { useFocus } from '@react-aria/interactions'
import './SubscriptionsSelector.scss'
import { useUserSubscriptions } from './UserSubscriptionsProvider'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import DoneIcon from '@material-ui/icons/Done'
import { useAuthentication, useUserSubscriptionId } from '@logic/platform-core'
import { useHistory } from 'react-router-dom'
import { CreateSubscriptionDialog, useCreateSubscriptionDialogControl } from './CreateSubscriptionDialog'
import { useUserClient } from '../users/UserClient'
import { UserSubscription } from './SubscriptionClient'

function Option({ item, state }: any) {
  // Get props for the option element
  const ref = React.useRef()
  const isDisabled = state.disabledKeys.has(item.key)
  const isSelected = state.selectionManager.isSelected(item.key)
  const { optionProps } = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
    },
    state,
    ref as any,
  )

  // Handle focus events so we can apply highlighted
  // style to the focused option
  const [, setFocused] = React.useState(false)
  const { focusProps } = useFocus({ onFocusChange: setFocused })
  const [name, id] = item.rendered

  return (
    <li
      {...mergeProps(optionProps, focusProps)}
      ref={ref as any}
      className={isSelected ? 'selected' : ''}
    >
      {React.cloneElement(name)}
      {React.cloneElement(id)}
      {isSelected ? <DoneIcon style={{ color: 'rgb(22, 118, 255)' }} /> : null}
    </li>
  )
}

const SearchBox = (props: any) => {
  const state = useSearchFieldState(props)
  const ref = React.useRef()
  const { inputProps } = useSearchField(props, state, ref as any)

  return (
    <div className="search-box">
      <input {...inputProps} placeholder="Search for subscription name or ID" ref={ref as any} />
    </div>
  )
}

function ListBoxPopup({ state, onCreateSubscriptionClick, filter, onFilter, ...otherProps }: any) {
  const ref = React.useRef<HTMLElement>()

  // Get props for the listbox
  const { listBoxProps } = useListBox(
    {
      autoFocus: state.focusStrategy || true,
      disallowEmptySelection: true,
      ...otherProps,
    },
    state,
    ref as any,
  )

  // Handle events that should cause the popup to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  const overlayRef = React.useRef<HTMLElement>()
  const { overlayProps } = useOverlay(
    {
      onClose: () => {
        state.close()
        onFilter('')
      },
      shouldCloseOnBlur: true,
      isOpen: state.isOpen,
      isDismissable: true,
    },
    overlayRef as any,
  )

  // Wrap in <FocusScope> so that focus is restored back to the
  // trigger when the popup is closed. In addition, add hidden
  // <DismissButton> components at the start and end of the list
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope restoreFocus={true}>
      <div {...overlayProps} ref={overlayRef as any}>
        <DismissButton onDismiss={() => state.close()} />
        <div
          className="header-select-list"
          style={{
            position: 'absolute',
            width: '100%',
            margin: '4px 0 0 0',
            listStyle: 'none',
          }}
        >
          <SearchBox onChange={onFilter} value={filter} />
          <ul
            {...mergeProps(listBoxProps, otherProps)}
            ref={ref as any}

          >
            {[...state.collection].map((item) => (
              <Option key={item.key} item={item} state={state} />
            ))}
          </ul>
          <button type="button" onClick={onCreateSubscriptionClick}>Create new subscription</button>
        </div>
        <DismissButton onDismiss={() => state.close()} />
      </div>
    </FocusScope>
  )
}

function HeaderSelect({ filter, onFilter, ...props }: any) {
  // Create state based on the incoming props
  const state = useSelectState(props)

  // Get props for child elements from useSelect
  const ref = React.useRef<HTMLElement>()
  const { triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    ref as any
  )

  // Get props for the button based on the trigger props from useSelect
  const { buttonProps } = useButton(triggerProps, ref as any)
  const onCreateSubscriptionClick = () => {
    state.close()
    props.onCreateSubscriptionClick()
  }

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <HiddenSelect
        state={state}
        triggerRef={ref as any}
        label={props.label}
        name={props.name}
      />
      <button {...buttonProps} ref={ref} className="header-select" type="button">
        <span {...valueProps} className="header-select-value">
          {state.selectedItem
            ? state.selectedItem.rendered
            : 'Select an option'}
        </span>
        <span aria-hidden="true" className="header-select-arrow">
          {state.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </span>
      </button>
      {state.isOpen && (
        <ListBoxPopup
          {...menuProps}
          state={state}
          onCreateSubscriptionClick={onCreateSubscriptionClick}
          filter={filter}
          onFilter={onFilter}
        />
      )}
    </div>
  )
}

function searchSubscriptions(items: UserSubscription[], query: string): UserSubscription[] {
  if (query === '') {
    return items
  } else {
    return items.filter((item) => {
      return item.id.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1
        || item.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1
    })
  }
}

export const SubscriptionsSelector = (): JSX.Element | null => {
  const client = useUserClient()
  const { subscriptions, completed } = useUserSubscriptions()
  const { user, changeSubscription } = useAuthentication()
  const { dialogProps, dialogControl } = useCreateSubscriptionDialogControl()
  const userSubscriptionId = useUserSubscriptionId()
  const history = useHistory()
  const [selectedSubscriptionId, setSelectedSubscriptionId] = React.useState(userSubscriptionId)
  const [filter, setFilter] = React.useState('')
  React.useEffect(() => {
    if (selectedSubscriptionId !== userSubscriptionId) {
      setSelectedSubscriptionId(userSubscriptionId)
    }
  }, [userSubscriptionId, selectedSubscriptionId])
  const onSubscriptionChange = React.useCallback((subscriptionId: string) => {
    if (user) {
      client.updateDefaultSubscriptionId(user.profile.sub, subscriptionId)
    }

    setSelectedSubscriptionId(subscriptionId)
    changeSubscription(subscriptionId)
    history.push(`/subscriptions/${subscriptionId}/resources`)
  }, [setSelectedSubscriptionId, changeSubscription, history, client, user])

  dialogControl.onCloseRef.current = React.useCallback((subscriptionId?: string) => {
    dialogControl.close()
    if (subscriptionId) {
      onSubscriptionChange(subscriptionId)
    }
  }, [onSubscriptionChange, dialogControl])

  if (completed && subscriptions.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <CreateSubscriptionDialog {...dialogProps} />
      <HeaderSelect aria-label="Select subscription" selectedKey={selectedSubscriptionId} onSelectionChange={onSubscriptionChange} onCreateSubscriptionClick={dialogControl.open} onFilter={setFilter} filter={filter}>
        {searchSubscriptions(subscriptions, filter).map((subscription) => (
          <Item textValue={subscription.id} key={subscription.id}>
            <div className="subscription-name">
              {subscription.name}
              {subscription.isProduction && (
                <span className="chip">
                  Production
                </span>
              ) }
            </div>
            <div className="subscription-id">{subscription.id}</div>
          </Item>
        ))}
      </HeaderSelect>
    </React.Fragment>
  )
}
