export enum GatewayVisibility {
    Public = 'Public',
    Private = 'Private'
  }

export enum GatewaySynchronization {
    Pending = 'Pending',
    Active = 'Active',
    Faulted = 'Faulted'
}

export type GatewaySyncModel = {
  synchronization: GatewaySynchronization,
}

export type GatewayPatchModel = {
  visibility: GatewayVisibility,
}

export type GatewayProductModel = GatewaySyncModel & {
    providerId: string,
    id: string,
    name: string,
    key: string,
    description: string,
    productTerms: string,
    apiKeyRequired: boolean,
    clientCredentialRequired?: boolean,
    providerApprovalRequired?: boolean,
    logoUrl: string,
    documentationUrl: string,
    getStartedUrl: string,
    apiIds: string[],
    visibility: GatewayVisibility,
    logo?: File,
    documentation?: File,
    getStarted?: File,
    applicationId: string,
    openidConfigIssuer?: string,
    openidConfigCustomUrl: string,
    contactProvider:string,
  }

export type GatewayProductConsumersModel = {
    id: string,
    isProduction:boolean,
    name: string,
}
