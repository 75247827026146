import {
  NotificationContext,
  NotificationContextProps,
  NotificationType,
} from './NotificationProvider'
import React from 'react'

export type Notifications = {
  addInfo: (message: string) => void,
  addWarn: (message: string) => void,
  addError: (message: string) => void,
}

export const useNotifications = (): Notifications => {
  const context = React.useContext<NotificationContextProps | undefined>(NotificationContext)
  if (!context) {
    throw Error('You cannot call useNotification outside of NotificationContext scope')
  }

  return {
    addInfo: (message: string) => context.addNotification(message, NotificationType.Info),
    addWarn: (message: string) => context.addNotification(message, NotificationType.Warning),
    addError: (message: string) => context.addNotification(message, NotificationType.Error),
  }
}
