import React from 'react'
import './Section.scss'

export type SectionProps = {
  children?: React.ReactNode,
  className?: string,
  loading?: boolean,
  skeleton?: React.FC,
}

export const Section = ({ children, loading, skeleton: Skeleton, className }: SectionProps): JSX.Element => {
  return (
    <div className={`${className ? `${className}` : ''} section`}>
      {loading && Skeleton ? <Skeleton /> : children}
    </div>
  )
}
