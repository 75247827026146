import {
  useAuthentication,
  useContextSubscriptionId,
  useData,
} from '@logic/platform-core'
import {
  Button,
  Page,
  Skeleton,
} from '@logic/platform-fabric'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useUserSubscriptions } from '../UserSubscriptionsProvider'
import InvitationImage from './invitation.svg'
import { InvitationResponse, useSubscriptionUserClient } from './SubscriptionUserClient'

const useInvitation = (subscriptionId: string, invitationId: string) => {
  const client = useSubscriptionUserClient()
  return useData(undefined, () => client.getInvitation(subscriptionId, invitationId), [client, subscriptionId, invitationId])
}

const RespondInvitationSkeleton = () => (
  <div style={{ textAlign: 'center' }}>
    <Skeleton circle={true} width={100} height={100} />
    <h1><Skeleton width={120} /></h1>
    <p><Skeleton width={400} /></p>
    <h3><Skeleton width={250} /></h3>
    <p><Skeleton width={150} /></p>
  </div>
)

export const RespondInvitationPage = (): JSX.Element => {
  const { invitationId } = useParams<{ invitationId: string }>()
  const subscriptionId = useContextSubscriptionId()
  const client = useSubscriptionUserClient()
  const { value: invitation, completed } = useInvitation(subscriptionId, invitationId)
  const { changeSubscription } = useAuthentication()
  const { addSubscription } = useUserSubscriptions()
  const history = useHistory()
  const respond = React.useCallback((response: InvitationResponse) => {
    return client.putInvitation(subscriptionId, invitationId, response).then(() => {
      if (response === 'Accepted') {
        addSubscription({ id: subscriptionId, name: invitation?.subscriptionName || '', role: 'Contributor' })
        changeSubscription(subscriptionId)
      } else {
        history.push('/')
      }
    })
  }, [client, subscriptionId, invitationId, invitation, addSubscription, changeSubscription, history])
  return (
    <Page loading={!completed} skeleton={RespondInvitationSkeleton}>
      <div style={{ textAlign: 'center' }}>
        <img src={InvitationImage} alt="" />
        <h1>Hi there!</h1>
        <p>Good news, looks like you were invited to join subscription</p>
        <h3 style={{ color: '#7d86a9' }}>{invitation?.subscriptionName}</h3>
        <p>Will you accept it?</p>
        <div style={{ marginTop: '40px' }}>
          <Button variant="primary" onPress={() => respond('Accepted')}>Sure, I accept!</Button>
          <Button onPress={() => respond('Declined')}>Nah, thanks</Button>
        </div>
      </div>
    </Page>
  )
}
