import React from 'react'
import {
  Page,
  PageHeader,
  Table,
  Button,
  DefaultColumnFilter,
} from '@logic/platform-fabric'
import {
  useAuthentication,
  useContextSubscriptionId, useNotifications,
} from '@logic/platform-core'
import { Column, Row } from 'react-table'
import { useSubscriptionName } from '../useSubscriptionName'
import { useSubscriptionUsers } from './useSubscriptionUsers'
import { SubscriptionUser, useSubscriptionUserClient } from './SubscriptionUserClient'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'
import { useQuestionDialog } from '../../layout/QuestionDialog'
import { EditSubscriptionUserDialog } from './EditSubscriptionUserDialog'
import { InviteButton } from './InviteButton'

export const SubscriptionUsersList = (): JSX.Element => {

  const [userToEdit, setUserToEdit] = React.useState<SubscriptionUser | undefined>(undefined)
  const subscriptionId = useContextSubscriptionId()
  const subscriptionName = useSubscriptionName(subscriptionId)
  const { value, completed, refresh } = useSubscriptionUsers(subscriptionId)
  const { askQuestion } = useQuestionDialog()
  const { addError } = useNotifications()
  const client = useSubscriptionUserClient()
  const { user } = useAuthentication()
  const onUserChanged = React.useCallback(() => {
    setUserToEdit(undefined)
    refresh()
  }, [refresh, setUserToEdit])
  const deleteUser = React.useCallback((subscriptionUser: SubscriptionUser) => {
    return askQuestion(`Are you sure you want to remove user ${subscriptionUser.givenName} ${subscriptionUser.familyName}?`, 'Delete user')
      .then(({ answer }) => {
        if (answer === 'yes') {
          client.deleteSubscriptionUser(subscriptionId, subscriptionUser.id).then(() => refresh(), (reason) => {
            addError(reason)
          })
        }
      })
  }, [askQuestion, subscriptionId, client, refresh, addError])
  const columns: Column<SubscriptionUser>[] = [
    {
      id: 'name',
      Header: 'Name',
      Cell: ({ row }: any) => `${row.original.givenName} ${row.original.familyName}`,
      canSort: true,
      sortType: React.useCallback((a: Row<SubscriptionUser>, b: Row<SubscriptionUser>) => `${a.original.givenName} ${a.original.familyName}`.localeCompare(`${b.original.givenName} ${b.original.familyName}`), []),
      defaultCanFilter: true,
      Filter: DefaultColumnFilter,
      filter: React.useCallback((rows: Row<SubscriptionUser>[], _, filterValue: string) => {
        return rows.filter((row) => `${row.original.givenName} ${row.original.familyName}`.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) !== -1)
      }, []),
    } as Column<SubscriptionUser>,
    {
      Header: 'Role',
      accessor: 'role',
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }: any) => (
        <div className="actions">
          {user?.profile.sub !== row.original.userSubject && (
            <React.Fragment>
              <Button variant="light" size="small" title="Edit" icon={CreateIcon} onPress={() => setUserToEdit(row.original)} />
              <Button variant="light" size="small" title="Delete" icon={DeleteIcon} onPress={() => deleteUser(row.original)} />
            </React.Fragment>
          )}
        </div>
      ),
    },
  ]

  return (
    <Page>
      <PageHeader heading={subscriptionName} subheading="Subscription users">
        <InviteButton />
      </PageHeader>
      {(value && (!completed || value?.length > 0))
        ? (
          <Table
            loading={!completed}
            data={value}
            columns={columns}
            defaultSort="name"
          />
        ) : 'No users found'}
      {userToEdit && <EditSubscriptionUserDialog isOpen={true} onClose={onUserChanged} user={userToEdit} />}
    </Page>
  )
}
