import React, { useRef } from 'react'
import './Button.scss'
import { useButton } from '@react-aria/button'
import { Link, useLocation } from 'react-router-dom'
import * as H from 'history'
import { createLocation } from 'history'
import { FormikContext } from 'formik'
import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import classnames from 'classnames'

type ButtonBaseProps = {
  variant?: ButtonVariantType,
  size?: ButtonSize,
  iconLocation?: IconLocation,
  rounded?: boolean,
  children?: string,
  icon?: any,
  disabled?: boolean,
  className?: string,
  title?: string,
}

export type ButtonProps = ButtonBaseProps & {
  onPress?: () => void | Promise<any>,
  type?: ButtonType,
}
export type LinkButtonProps<S = H.LocationState> = ButtonBaseProps & {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>),
  target?: string,
}

export type IconLocation = 'left' | 'right'

export type ButtonType = 'submit' | 'reset' | 'button'

export type ButtonVariantType = 'primary' | 'secondary' | 'light'

export type ButtonSize = 'regular' | 'small'

const isRounded = (variant: ButtonVariantType, rounded: boolean | undefined) => {
  if (rounded !== undefined) {
    return rounded
  } else {
    return variant === 'primary' || variant === 'secondary'
  }
}

export const Button: React.FC<ButtonProps> = ({ onPress, ...props }) => {
  const ref = useRef()
  const [isInvoking, setIsInvoking] = React.useState<boolean>(false)
  const onClick = React.useCallback(() => {
    if (onPress) {
      const result = onPress()
      if (result && result.finally) {
        setIsInvoking(true)
        result.finally(() => setIsInvoking(false))
      }
    }
  }, [onPress])
  const { buttonProps } = useButton({ onPress: onClick, ...props }, ref as any)
  const { variant = 'secondary', children, icon: Icon, type = 'button', className = '', disabled, iconLocation = 'left', size = 'regular', title = '' } = props
  const formik = React.useContext(FormikContext)
  const isSubmitting = (type === 'submit' && formik?.isSubmitting) || isInvoking
  const loaderColor = variant === 'primary' ? '#fff' : variant === 'light' ? '#7d86a9' : '#000'
  const ButtonIcon = isSubmitting ? () => <Loader type="TailSpin" width={24} height={24} color={loaderColor} /> : () => (Icon ? <Icon /> : null)
  const classNames = classnames('button', variant, className, {
    'submitting': isSubmitting,
    'rounded': isRounded(variant, props.rounded),
    'icon-right': iconLocation === 'right',
    'small': size === 'small',
  })
  return (
    // eslint-disable-next-line react/button-has-type
    <button {...buttonProps} disabled={isSubmitting || disabled} ref={ref} className={classNames} type={type} title={title}>
      {
        iconLocation === 'left'
          ? (<React.Fragment><ButtonIcon />{children && <span>{children}</span>}</React.Fragment>)
          : (<React.Fragment>{children && <span>{children}</span>}<ButtonIcon /></React.Fragment>)
      }
    </button>
  )
}

export const CancelableLink = ({ to, children, ...props }: any): JSX.Element => {
  const location = useLocation()
  const enhancedTo = typeof to === 'string'
    ? createLocation(to, {
      backTo: location.pathname,
    }, undefined, location)
    : {
      ...to,
      state: {
        backTo: location.pathname,
        ...to.state,
      },
    }
  return <Link to={enhancedTo} {...props}>{children}</Link>
}

export const LinkButton = <S extends H.LocationState>(props: LinkButtonProps<S>): JSX.Element => {
  const { children, variant = 'secondary', to, target, icon: Icon, className = '', iconLocation = 'left', size = 'regular', ...otherProps } = props

  const classNames = classnames('button', variant, className, {
    'rounded': isRounded(variant, props.rounded),
    'icon-right': iconLocation === 'right',
    'small': size === 'small',
  })

  const LinkContent = () => (
    iconLocation === 'left'
      ? (<React.Fragment>{Icon && <Icon />}{children && <span>{children}</span>}</React.Fragment>)
      : (<React.Fragment>{children && <span>{children}</span>}{Icon && <Icon />}</React.Fragment>)
  )

  // NOTE: Link doesn't handle URL outside of the app
  if (typeof to === 'string' && to.startsWith('http')) {
    return (
      <a role="button" href={to} target={target} className={classNames} {...otherProps}>
        <LinkContent />
      </a>
    )
  } else {
    return (
      <CancelableLink role="button" to={to} target={target} className={classNames} {...otherProps}>
        <LinkContent />
      </CancelableLink>
    )
  }
}
