import React from 'react'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { NavLink } from 'react-router-dom'
import './Breadcrumbs.scss'
import { routes } from '../breadcrumbs'
import { useServiceCatalog } from '@logic/platform-core'

export const Breadcrumbs = (): JSX.Element => {
  const { services } = useServiceCatalog()
  const serviceRoutes = services.map((s) => s.breadcrumbs || []).flat(1)
  const allRoutes = [
    ...routes,
    ...serviceRoutes,
  ]
  const breadcrumbs = useBreadcrumbs(allRoutes, { disableDefaults: true })

  return (
    <div className="breadcrumbs">
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <div key={(match as any).key}>
          {index < breadcrumbs.length - 1 ? <NavLink to={match.url}>{breadcrumb}</NavLink> : breadcrumb}
        </div>
      ))}
    </div>
  )
}
