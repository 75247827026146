import {
  LinkButton,
  Markdown,
} from '@logic/platform-fabric'
import React from 'react'
import { useProductApis } from '../useProductApis'
import { OpenApiOperationDescription } from './documentation/OpenApiDescription'
import { ApiTableOfContent } from './documentation/ApiTableOfContent'
import { TableOfContentItemProps } from './documentation/TableOfContent'
import './ApiReferneceTab.scss'
import GetAppIcon from '@material-ui/icons/GetApp'

type ApiReferenceTabProps = {
  productKey: string,
  apiKeyRequired: boolean | undefined,
}

const IntroductionContent = ({ name, documentationUrl, apiDefinitionUrl }: any) => (
  <div>
    <header>
      <h1>{name}</h1>
      <div><LinkButton to={apiDefinitionUrl} variant="light" target="_blank" size="small" icon={GetAppIcon}>Download API definition</LinkButton></div>
    </header>
    {documentationUrl && <Markdown source={documentationUrl} />}
  </div>
)

const ApiReferenceContent = ({ item, apiKeyRequired }: { item: TableOfContentItemProps, apiKeyRequired: boolean }) => {
  if (item.state.type === 'introduction') {
    return <IntroductionContent {...item.state} />
  } else if (item.state.type === 'api') {
    return (
      <OpenApiOperationDescription
        api={item.state.apiDefinition}
        operationId={item.state.operationId}
        tag={item.state.tag}
        apiKeyRequired={apiKeyRequired}
      />
    )
  } else {
    return <div>Not found</div>
  }
}

export const ApiReferenceTab = ({ productKey, apiKeyRequired }: ApiReferenceTabProps): JSX.Element => {
  const { value: apis, completed } = useProductApis(productKey)
  const [selectedItem, setSelectedItem] = React.useState<TableOfContentItemProps>()
  const selectedItemKey = React.useMemo(() => selectedItem?.itemKey, [selectedItem])

  return (
    <div className="api-description">
      <div className="api-table-of-content">
        <ApiTableOfContent productApis={apis} loading={!completed} selectedKey={selectedItemKey} onSelect={setSelectedItem} />
      </div>
      <div className="content">
        {
          selectedItem && <ApiReferenceContent item={selectedItem} apiKeyRequired={apiKeyRequired || false} />
        }
      </div>
    </div>
  )
}
