import React from 'react'
import classNames from 'classnames'
import './Splash.scss'

type SplashEventArgs = {
  status?: string,
  visible: boolean,
}

export const showSplash = (status?: string): void => {
  const event = new CustomEvent<SplashEventArgs>('splash', {
    detail: {
      visible: true,
      status,
    },
  })
  window.dispatchEvent(event)
}

export const hideSplash = (): void => {
  const event = new CustomEvent<SplashEventArgs>('splash', {
    detail: {
      visible: false,
    },
  })
  window.dispatchEvent(event)
}

export const Splash = (): JSX.Element => {
  const [visible, setVisible] = React.useState(true)
  const [removed, setRemoved] = React.useState(false)
  const [status, setStatus] = React.useState('Loading')

  React.useEffect(() => {
    const showHandler = ((e: CustomEvent<SplashEventArgs>) => {
      if (e.detail.status) {
        setStatus(e.detail.status)
      }
      if (e.detail.visible) {
        setVisible(true)
        setRemoved(false)
      } else {
        setVisible(false)
        setTimeout(() => setRemoved(true), 800)
      }
    }) as EventListener // eslint-disable-line no-undef
    window.addEventListener('splash', showHandler)

    return () => window.removeEventListener('splash', showHandler)
  }, [])

  return (
    <div id="splash-wrapper" className={classNames({ unload: !visible, removed })}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550 280" width="550" height="280" style={{ isolation: 'isolate' }}>
        <g>
          <path fillRule="evenodd" d="M167 276.1l-11-11a9 9 0 0 1-10.4-14.4 9 9 0 0 1 14.5 10.2l8.1 8.2 63.3-63.3h8.4l-71.7 71.8-1.4-1.5h.1zM150 255a3 3 0 1 1 4.1 4.3 3 3 0 0 1-4.2-4.3zm-.4-40l22.5-22.4v-30.2a9 9 0 0 1-3.5-15v.1a9 9 0 1 1 9.5 14.8v32.8L154 219.3a9 9 0 1 1-4.3-4.2v-.1zm23.2-63.2a3 3 0 1 1 4.8 3.6 3 3 0 0 1-4.8-3.6zM143.6 221a3 3 0 1 1 4.3 4.2 3 3 0 0 1-4.3-4.2z" />
          <path fillRule="evenodd" d="M145.3 194.5v-38.6a9 9 0 1 1 6 .1v40.9l-27.6 27.6 13.2 13.3h28.5a9 9 0 1 1 0 6h-31l-27-27 .2-.2A9 9 0 0 1 97.4 202a9 9 0 0 1 14.3 10.5l7.8 7.7 25.8-25.8v.1zm26.5 44.2a3 3 0 1 1 4.8 3.6 3 3 0 0 1-4.8-3.6zm-25.5-93.4a3 3 0 1 1 4.3 4.3 3 3 0 0 1-4.3-4.3zm-44.7 61a3 3 0 1 1 4.3 4.3 3 3 0 0 1-4.3-4.3z" />
          <path fillRule="evenodd" d="M56.2 124.7a3 3 0 1 1 4.3 4.3 3 3 0 0 1-4.3-4.3zM90 73.2a3 3 0 1 1 4.2 4.3 3 3 0 0 1-4.2-4.3zM27.5 57.7a3 3 0 1 1 4.3 4.2 3 3 0 0 1-4.3-4.2zm96 107.3a3 3 0 1 1 4.3 4.2 3 3 0 0 1-4.3-4.2zm-3.4 23.6a3 3 0 1 1 4.8 3.6 3 3 0 0 1-4.8-3.6zM166 125h-19.2l-29 29H77.3l9.7 9.7h30.4a9 9 0 1 1-.3 6H85l-.2.2-23.2-23.3v15.6l26 26h26.2a9 9 0 1 1 .3 6H85.2l-.2.1L17.6 127v-24l9-9V68.3a9 9 0 1 1 6 .1v28.2l-9 8.9v11l30.8-30.8h23l6.4-6.4a9 9 0 1 1 4.2 4.3l-8 8H57l-33 33.2 31.4 31.5v-20.8a9 9 0 1 1 6 0v2.8l9.8 9.8h44.2l29-29h21.7a9 9 0 1 1 0 6l-.2-.1zm6.4-5a3 3 0 1 1 4.2 4.3 3 3 0 0 1-4.2-4.3z" />
          <path fillRule="evenodd" d="M51 64l2 2.1-5 5a1 1 0 0 0 0 1.4l3 2.8c.3.4 1 .4 1.3 0l5-5 2 2.2-4.9 5a1 1 0 0 0 0 1.4l2.8 2.8c.4.4 1 .4 1.4 0l5-5 2.1 2.2 4.3-4.3 8.4-8.5 4.3-4.2-21.2-21.2-17 17 2.1 2-5 5a1 1 0 0 0 0 1.4l3 2.9c.3.4 1 .4 1.3 0l5-5zm2-6.3l8.5-8.5 12.7 12.7-8.5 8.5L53 57.7zm49.6 51h-27c-.9 0-1.6-.6-1.6-1.4v-3c0-1 .7-1.6 1.5-1.6h27c1 0 1.5.7 1.5 1.5v3c0 .9-.6 1.5-1.4 1.5zm0 10h-27c-.9 0-1.6-.6-1.6-1.4v-3c0-.9.7-1.5 1.5-1.5h27c1 0 1.5.6 1.5 1.5v3c0 .8-.6 1.5-1.4 1.5v-.1zm0 10.1h-27c-.9 0-1.6-.6-1.6-1.4v-3.1c0-.8.7-1.5 1.5-1.5h27c1 0 1.5.7 1.5 1.5v3c0 .9-.6 1.5-1.4 1.5zM117 31.7a6 6 0 1 1 12 0 6 6 0 0 1-12 0zm-12-12a6 6 0 1 1 12 0 6 6 0 0 1-12 0z" />
          <path fillRule="evenodd" d="M234 8.3h8.2L229.6 21a1 1 0 0 0 0 1.4l2.8 2.8c.4.4 1 .4 1.4 0l17-17h8.4l-12.9 12.9a1 1 0 0 0 0 1.4l3 2.8c.3.4 1 .4 1.3 0l17-17h8.2l-13 12.7a1 1 0 0 0 0 1.4l3 2.8c.3.4 1 .4 1.3 0l14-14 4.2 4-12 12.2a1 1 0 0 0 0 1.5l2.6 2.8a1 1 0 0 0 1.5 0l12.2-12.2 4.1 4.2-12 12.1a1 1 0 0 0 0 1.5l2.6 2.8a1 1 0 0 0 1.6 0L298 28l4.2 4.2-12 12.1c-.5.5-.5 1.1 0 1.6l2.6 2.7c.5.4 1.2.4 1.6 0l12-12.1 4.3 4.1-12.2 12.2a1 1 0 0 0 0 1.5l2.8 2.7c.4.4 1 .4 1.5 0l12.1-12 4.2 4.1-12.2 12.2a1 1 0 0 0 0 1.5l2.8 2.7c.4.4 1 .4 1.5 0L321.6 55v9.9c0 .5.5 1 1 1h4c.6 0 1-.5 1-1V49.2l-47-47h-66.3l-44 44.2L125.7 2H59L9 52.2v19.7a9 9 0 1 0 6 .6V54.7l18-18h41.6L108 70.1v32l8.7 8.8a9 9 0 1 0 4.7-3.8l-7.4-7.5V73.4h12.2l18 18a9 9 0 1 0 4.5-4l-20-20h-15L77 30.6H38.7L50 19.5h35.7l31 31h9.8a9 9 0 1 0 .2-6h-7.4l-31-31H56L61.4 8h62L166 50.6l-8 8a9 9 0 1 0 4.3 4.3l54.6-54.6h8.6L212.6 21a1 1 0 0 0 0 1.4l2.8 2.8c.4.4 1 .4 1.4 0l17.1-17v.1zM123 113.5a3 3 0 0 1 4.2 4.3 3 3 0 0 1-4.2-4.3zm27-19.8A3 3 0 1 1 154 98a3 3 0 0 1-4.2-4.3zM8.7 78.5a3 3 0 0 1 4.2 4.3 3 3 0 0 1-4-4.3h-.2zM152 64.7a3 3 0 1 1 4.2 4.3 3 3 0 0 1-4.2-4.3zm-19-19a3 3 0 1 1 4.1 4.2 3 3 0 0 1-4.2-4.3v.1z" />
          <path d="M240 84.4v92.4h49.1v7h-57.6V84.5h8.5zm58.9 63v-1.6q0-10.7 4-19.2 4.2-8.5 11.8-13.3 7.5-4.7 17.1-4.7 14.8 0 24 10.3 9 10.4 9 27.5v1.7q0 10.7-4 19.3-4.2 8.6-11.7 13.2-7.5 4.7-17.2 4.7-14.7 0-23.8-10.4-9.2-10.4-9.2-27.5zm8.2-.8v1.5q0 13.2 6.8 21.7 6.9 8.5 18 8.5t18-8.5q6.9-8.5 6.9-22.4v-1.6q0-8.5-3.2-15.5-3.1-7-8.8-11-5.7-3.8-13-3.8-11 0-17.8 8.6-7 8.6-7 22.5zm71.4.7v-1q0-17.4 7.7-27.6 7.7-10.1 20.9-10.1 15 0 23 12l.3-10.7h7.7v72.5q0 14.3-8 22.6t-21.7 8.3q-7.7 0-14.7-3.4-7-3.5-11-9.2l4.5-5q8.7 10.7 20.7 10.7 10.2 0 16-6 5.7-6 6-16.8v-9.3q-8 11-23 11-12.8 0-20.6-10.3-7.8-10.3-7.8-27.7zm8.3.4q0 14.1 5.6 22.3 5.7 8.1 16 8.1 15.1 0 21.4-13.5v-34.4q-2.8-7-8.2-10.8-5.4-3.7-13-3.7-10.3 0-16 8-5.8 8.2-5.8 24zm81.8-37.8v74h-8.2v-74h8.2zM459 88.5q0-2.3 1.5-4 1.5-1.5 4-1.5 2.7 0 4.2 1.6t1.5 4q0 2.2-1.5 3.8-1.5 1.6-4.1 1.6-2.6 0-4.1-1.6-1.5-1.6-1.5-3.9zm59.2 89.8q8.5 0 14.3-4.8 5.8-4.8 6.2-12.4h7.9q-.4 6.7-4.2 12.3-4 5.6-10.4 8.7-6.5 3.2-13.8 3.2-14.6 0-23.1-10.2-8.5-10-8.5-27.2v-2.4q0-11 3.8-19.4 3.8-8.4 11-13 7.1-4.5 16.7-4.5 12.2 0 20 7.2 8 7.2 8.5 19h-7.9q-.5-8.6-6.2-14-5.7-5.3-14.4-5.3-11 0-17.2 8-6.1 8-6.1 22.4v2.4q0 14.2 6 22.1 6.2 8 17.4 8z" />
        </g>
      </svg>

      <div id="splash-status">{status}</div>
    </div>
  )
}
