import * as React from 'react'

export type Data<T> = {
  value: T | undefined,
  completed: boolean,
  error: any,
  refresh: () => void,
}

export const useData = <T>(initial: T, fallback: () => Promise<T>, deps: React.DependencyList): Data<T> => {

  const [refreshToken, setRefreshToken] = React.useState(Math.random())
  const refresh = React.useCallback(() => {
    setRefreshToken(Math.random())
  }, [setRefreshToken])
  const [value, setValue] = React.useState<Data<T>>({
    value: initial,
    completed: false,
    error: undefined,
    refresh,
  })

  const cachedFallback = React.useCallback(fallback, deps) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setValue({
      value: value.value,
      completed: false,
      error: undefined,
      refresh,
    })
    cachedFallback().then((result) => {
      setValue({
        value: result,
        completed: true,
        error: undefined,
        refresh,
      })
    }, (reason) => {
      setValue({
        value: undefined,
        completed: true,
        error: reason,
        refresh,
      })
    })
  }, [cachedFallback, refreshToken]) // eslint-disable-line react-hooks/exhaustive-deps

  return value
}
