import * as React from 'react'
import {
  Route,
  RouteProps,
  useLocation,
} from 'react-router'
import { useAuthentication } from './AuthenticationProvider'
import { Unauthorized } from './Unauthorized'

export type RestrictedRouteProps = RouteProps & {
  restricted?: () => boolean,
}

const RestrictedRouteContent = ({ component, render, restricted, ...ownProps }: any) => {
  const location = useLocation()
  const { user, redirectToSignIn } = useAuthentication()

  if (!user) {
    redirectToSignIn(location.pathname + location.search)
    return (<React.Fragment />)
  }

  if (restricted && !restricted()) {
    return (<Unauthorized />)
  }

  if (component) {
    return React.createElement(component as React.ComponentClass<any, any>, ownProps)
  }

  if (render) {
    return render(ownProps)
  }
}

export const RestrictedRoute = ({ component, render, children, restricted, ...props }: RestrictedRouteProps): JSX.Element => {
  return (
    <Route
      {...props}
      render={((renderProps) => (<RestrictedRouteContent restricted={restricted} component={component} {...renderProps} render={render} />))}
    />
  )
}
