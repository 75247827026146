/* eslint-disable no-console */
import { enableLogging as MobxLogger } from 'mobx-logger';
import { configure as configureMobx } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './core/infrastructure/registerServiceWorker';
import { Splash, showSplash } from '@logic/platform-shell';


configureMobx({
  enforceActions: 'observed'
});

MobxLogger({
  action: true,
  compute: true,
  predicate: () => true,
  reaction: true,
  transaction: true
});

const root = document.createElement('div');
document.body.appendChild(root);

const splashRoot = document.createElement('div');
document.body.appendChild(splashRoot);

ReactDOM.render(<Splash />, splashRoot)

const run = async () => {

  showSplash('Downloading application');
  const App = await import(/* webpackChunkName: "App" */ './layout/App').then(app => app.default);

  showSplash('Downloading modules');
  const modules = await import(/* webpackChunkName: "Modules" */ './modules').then(m => m.default);

  showSplash('Bootstrapping application');
  ReactDOM.render(<App modules={modules} />, root);
}

run();


unregister();
