import React from 'react'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import { useToggleState } from '@react-stately/toggle'
import { useFocusRing } from '@react-aria/focus'
import { useSwitch } from '@react-aria/switch'
import './ToggleSwitch.scss'

type labelPositionProps = 'left' | 'right'

export type ToggleSwitchProps = {
  isSelected: boolean,
  label: string,
  onChange?: (value: boolean) => void,
  labelPosition?: labelPositionProps,
}

export const ToggleSwitch = (props: ToggleSwitchProps): JSX.Element => {
  const { label, labelPosition = 'right', isSelected } = props
  const state = useToggleState({ ...props, isSelected })
  const ref = React.useRef(null)
  const { inputProps } = useSwitch(props, state, ref)
  const { isFocusVisible, focusProps } = useFocusRing()

  return (
    <label className={`toggle-switch ${labelPosition}`}>
      <VisuallyHidden>
        <input type="checkbox" {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg width={58} height={35} aria-hidden="true" style={{ marginRight: 4 }}>
        <rect
          x={4}
          y={4}
          width={52}
          height={30}
          rx={15}
          fill={state.isSelected ? '#1676FF' : '#ffffff'}
          strokeWidth={state.isSelected ? 0 : 1}
          stroke="#aaaab4"
        />
        <circle cx={state.isSelected ? 41 : 19} cy={19} r={12} fill="white" stroke={state.isSelected ? '#fff' : '#aaaab4'} strokeWidth="1" />
        {isFocusVisible && (
          <rect
            x={1}
            y={1}
            width={58}
            height={35}
            rx={11}
            fill="none"
            stroke="#1676FF"
            strokeWidth={2}
          />
        )}
      </svg>
      <label>{label}</label>
    </label>
  )
}
