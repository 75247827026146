import { useConfig } from '@logic/platform-core'
import { AxiosInstance } from 'axios'
import { useAxios } from '../../infrastructure/useAxios'

type SubscriptionUserRole = 'Owner' | 'Contributor'

export type SubscriptionUser = {
  id: string,
  givenName: string,
  familyName: string,
  role: SubscriptionUserRole,
}

type InviteRequest = {
  emailAddress: string,
  role: string,
  responseUrlPattern: string,
}

type Invitation = {
  invitationId: string,
  invitedBy: string,
  subscriptionName: string,
}

export type InvitationResponse = 'Accepted' | 'Declined'

export type InvitationItem = {
  emailAddress: string,
  invitedBy: string,
  id: string,
}

type SubscriptionUserClient = {
  getSubscriptionUsers(subscriptionId: string): Promise<SubscriptionUser[]>,
  deleteSubscriptionUser(subscriptionId: string, userId: string): Promise<void>,
  updateSubscriptionUserRole(subscriptionId: string, userId: string, role: SubscriptionUserRole): Promise<SubscriptionUser>,
  getInvitations(subscriptionId: string): Promise<InvitationItem[]>,
  inviteUser(subscriptionId: string, request: InviteRequest): Promise<void>,
  getInvitation(subscriptionId: string, invitationId: string): Promise<Invitation>,
  putInvitation(subscriptionId: string, invitationId: string, response: InvitationResponse): Promise<void>,
  deleteInvitation(subscriptionId: string, invitationId: string): Promise<void>,
}

const getSubscriptionUsers = (client: AxiosInstance) => (subscriptionId: string): Promise<SubscriptionUser[]> => {
  return client.get<SubscriptionUser[]>(`subscriptions/${subscriptionId}/users`).then((response) => response.data)
}

const updateSubscriptionUserRole = (client: AxiosInstance) => (subscriptionId: string, userId: string, role: SubscriptionUserRole) => {
  return client.put<SubscriptionUser>(`subscriptions/${subscriptionId}/users/${userId}`, {
    subscriptionRole: role,
  }).then((response) => response.data)
}

const deleteSubscriptionUser = (client: AxiosInstance) => (subscriptionId: string, userId: string): Promise<void> => {
  return client.delete(`subscriptions/${subscriptionId}/users/${userId}`)
    .then(() => { /* no-operation */ })
    .catch((reason) => {
      return reason.response.status === 400 ? Promise.reject(reason.response.data) : Promise.reject(reason)
    })
}

const getInvitations = (client: AxiosInstance) => (subscriptionId: string): Promise<InvitationItem[]> => {
  return client.get(`subscriptions/${subscriptionId}/invitations`).then((response) => response.data)
}

const inviteUser = (client: AxiosInstance) => (subscriptionId: string, request: InviteRequest): Promise<void> => {
  return client.post(`/subscriptions/${subscriptionId}/invitations`, request)
    .then(() => { /* no-operation */ })
}

const getInvitation = (client: AxiosInstance) => (subscriptionId: string, invitationId: string): Promise<Invitation> => {
  return client.get<Invitation>(`/subscriptions/${subscriptionId}/invitations/${invitationId}`).then((response) => response.data)
}

const putInvitation = (client: AxiosInstance) => (subscriptionId: string, invitationId: string, response: InvitationResponse): Promise<void> => {
  return client.put(`/subscriptions/${subscriptionId}/invitations/${invitationId}`, {
    answer: response,
  }).then(() => { /* no-operation */ })
}

const deleteInvitation = (client: AxiosInstance) => (subscriptionId: string, invitationId: string) => {
  return client.delete(`/subscriptions/${subscriptionId}/invitations/${invitationId}`)
    .then(() => { /* no-operation */ })
}

const createSubscriptionUserClient = (client: AxiosInstance) => {
  return {
    getSubscriptionUsers: getSubscriptionUsers(client),
    deleteSubscriptionUser: deleteSubscriptionUser(client),
    updateSubscriptionUserRole: updateSubscriptionUserRole(client),
    getInvitations: getInvitations(client),
    inviteUser: inviteUser(client),
    getInvitation: getInvitation(client),
    putInvitation: putInvitation(client),
    deleteInvitation: deleteInvitation(client),
  }
}

export const useSubscriptionUserClient = (): SubscriptionUserClient => {
  const { labPlatformApiUrl } = useConfig()
  return useAxios(labPlatformApiUrl, createSubscriptionUserClient)
}
