import React from 'react'
import { ErrorMessage, useField } from 'formik'
import { useDropzone } from 'react-dropzone'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { BaseFieldControlProps } from './BaseFieldControlProps'

export type FileFieldProps = {
  type: 'file',
  accept?: string,
  multiple?: boolean,
}

export type FileFieldControlProps = FileFieldProps & BaseFieldControlProps

export const fileSize = (size: number): string => {
  if (size > 1024 * 1024) return `${Math.round(size / 1024 / 1024)}MB`
  if (size > 1024) return `${Math.round(size / 1024)}KB`
  else return `${size} bytes`
}

export const FileFieldControl = ({ name, validate, accept, multiple, ...props }: FileFieldControlProps): JSX.Element => {
  const [, { value }, { setValue, setTouched }] = useField({ name, validate })
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: any) => {
      setValue(multiple ? files : files[0])
      setTouched(true)
    },
    maxFiles: multiple === false ? 1 : undefined,
    accept,
  })

  const files = multiple ? (value || []) : (value ? [value] : [])

  return (
    <div>
      <div {...getRootProps()} className="field-drop-zone">
        <input {...getInputProps()} {...props} />
        <p>
          <AttachFileIcon />
          <span>Attach file</span>
        </p>
      </div>
      <ul className="field-files">
        {files.map((file: File) => (
          <li key={file.name}>
            <div><span className="name">{file.name}</span>{file.size ? <span className="size">({fileSize(file.size)})</span> : undefined}</div>
            <div className="message"><ErrorMessage name={name} /></div>
          </li>
        ))}
      </ul>
    </div>
  )
}
