import {
  useContextSubscriptionId,
  useTitle,
} from '@logic/platform-core'
import {
  Button,
  EditablePageHeader,
  Field,
  FieldInstruction,
  Form,
  FormSkeleton,
  CancelButton,
  Page,
  Section,
  useSimpleForm,
} from '@logic/platform-fabric'
import React from 'react'
import {
  Link,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { useClientCredentials } from '../../client-credentials/useClientCredentials'
import { AccessRequest, useMarketplaceClient } from '../MarketplaceClient'
import { useProduct } from '../useProduct'

const useProductKey = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  return query.get('key') || ''
}

const useAddProductForm = (productId: string) => {
  const history = useHistory()
  const productKey = useProductKey()
  const initialValues = {
    id: '',
    name: '',
    clientCredentialId: '',
    requesterName: '',
    requesterEmail: undefined,
    requesterPhoneNumber: undefined,
    requesterIntent: '',
    productId,
    allowTracing: false,
  }

  const client = useMarketplaceClient()
  const contextSubscriptionId = useContextSubscriptionId()
  const createProductApiKey = (model: AccessRequest) => client.requestAccess(contextSubscriptionId, { ...model, productId })
    .then((response) => {
      history.push(`/subscriptions/${contextSubscriptionId}/resources/${productKey}/${response.id}/summary`)
    })
  return useSimpleForm(initialValues, undefined, createProductApiKey)
}

const useProductClientCredentialsOptions = (subscriptionId: string, applicationId: string | undefined) => {
  const clientCredentials = useClientCredentials(subscriptionId)
  return React.useMemo(() => clientCredentials.value
    ?.filter((cc) => cc.applicationIds?.some((appId) => appId === applicationId))
    .map((cc) => ({
      value: cc.clientId,
      text: cc.resourceDescription,
    })) || [], [clientCredentials, applicationId])
}

export const AddProductPage = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const productKey = useProductKey()
  const { value: product, completed } = useProduct(productKey)
  const form = useAddProductForm(product?.id || '')
  const clientCredentials = useProductClientCredentialsOptions(subscriptionId, product?.applicationId)
  useTitle(() => (product ? `${product.name} - Add product` : 'Add product'), [product])

  return (
    <Page loading={!completed} skeleton={FormSkeleton}>
      <Form {...form}>
        <EditablePageHeader subheading={product?.name || ''} label="API key name" name="name" />
        {product?.clientCredentialsRequired
          && (
            <Section>
              <React.Fragment>
                <Field label="Client credentials" name="clientCredentialId" type="dropdown" options={clientCredentials} optionLabel="Select client credentials" />
                {clientCredentials.length === 0 && (
                  <FieldInstruction>
                    This product requires a valid Client Credentials and is optional to create at this stage.
                    You can request KMD Identity Client Credentials from <Link to={`/subscriptions/${subscriptionId}/client-credentials`}>here</Link>.
                    For external products please contact with the <Link to={`/marketplace/${productKey}`}>provider</Link>.
                  </FieldInstruction>
                )}
              </React.Fragment>
            </Section>
          )
        }
        {product?.providerApprovalRequired
          && (
            <Section>
              (
              <React.Fragment>
                <Field label="Name" name="requesterName" validators={['required']} />
                <Field label="Email" name="requesterEmail" validators={['required', 'email']} />
                <Field label="Phone number" name="requesterPhoneNumber" validators={['phone']} />
                <Field label="How are you going to use this product" name="requesterIntent" validators={['required']} type="multiline" />
              </React.Fragment>
              )
            </Section>
          )}

        {product?.subscriptionId === subscriptionId && <Field label="Allow tracing" name="allowTracing" type="switch" />}

        <Button variant="primary" type="submit">Request Access</Button>
        <CancelButton defaultBackTo={`/marketplace/${productKey}`} />
      </Form>
    </Page>
  )
}
