import {
  GatewaySynchronization, GatewayVisibility,
} from './GatewayProductModel'

export enum ApisetsStatus {
    Preview = 'Preview',
    Active = 'Active',
    Deprecated ='Deprecated'
}

export type GatewayCurrentVersionModel = GatewayApiModel & {
    isCurrent: boolean,
}

export type GatewayApiVersionSetModel = {
    versionSetId: string,
    path: string,
    providerId: string,
    versions: GatewayCurrentVersionModel[],
}

export type GatewayApiModel = {
    id?: string,
    apiId: string,
    providerId: string,
    name: string,
    description: string | null,
    path: string,
    backendServiceUrl: string,
    publicUrl?: string,
    apiVersion: string,
    openApiSpecUrl: string | null,
    logoUrl: string | null,
    documentationUrl: string | null,
    productIds: string[] | null,
    visibility: GatewayVisibility,
    apiVersionSetId: string,
    status: ApisetsStatus,
    logo?: File,
    openApiSpec: File,
    documentation?: File,
    synchronization: GatewaySynchronization,
}
