import React from 'react'
import { LinkButton } from '@logic/platform-fabric'
import { useProductLinkWithText } from '../useProductLink'
import {
  CreateSubscriptionDialog,
  useCreateSubscriptionDialogControl,
  useSubscriptionRequiredLink,
} from '../../subscriptions/CreateSubscriptionDialog'

export type AddProductButtonProps = {
  productKey: string,
}

export const AddProductButton = ({ productKey }: AddProductButtonProps): JSX.Element => {
  const { link, text } = useProductLinkWithText(productKey)
  const { dialogProps, dialogControl } = useCreateSubscriptionDialogControl()
  const linkProps = useSubscriptionRequiredLink(link, dialogControl)

  return (
    <React.Fragment>
      <CreateSubscriptionDialog {...dialogProps} />
      <LinkButton variant="primary" {...linkProps}>{text}</LinkButton>
    </React.Fragment>
  )
}
