import { useContextSubscriptionId, useNotifications } from '@logic/platform-core'
import {
  Button,
  CancelButton,
  EditablePageHeader,
  Field,
  Form,
  FormSkeleton,
  Page,
  Section,
  useSimpleForm,
} from '@logic/platform-fabric'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { EditSubscription, useSubscriptionClient } from './SubscriptionClient'
import { useSubscriptionRole } from './useSubscriptionRole'

const useEditSubscriptionForm = (subscriptionId: string) => {
  const client = useSubscriptionClient()
  const { addInfo } = useNotifications()
  const history = useHistory()
  const getSubscription = React.useCallback(() => client.getSubscription(subscriptionId), [client, subscriptionId])
  const initialValues = {
    name: '',
    billingInfo: '',
    isProduction: false,
  }
  return useSimpleForm(initialValues, getSubscription, (values: EditSubscription) => {
    return client.putSubscription(subscriptionId, values).then(() => {
      addInfo('Changes have been saved')
      history.push(`/subscriptions/${subscriptionId}`)
    })
  })
}

export const EditSubscriptionPage = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const userRole = useSubscriptionRole(subscriptionId)
  const { loading, ...form } = useEditSubscriptionForm(subscriptionId)
  return (
    <Page loading={loading} skeleton={FormSkeleton}>
      <Form {...form}>
        <EditablePageHeader name="name" label="Name" subheading="Subscription details" />
        <Section>
          {userRole === 'Owner' && <Field type="switch" label="Production" name="isProduction" />}
          <Field name="billingInfo" label="Billing information" type="multiline" />
        </Section>
        <Button type="submit" variant="primary">Save</Button>
        <CancelButton defaultBackTo={`/subscriptions/${subscriptionId}`} />
      </Form>
    </Page>
  )
}
