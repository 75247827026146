/* eslint-disable no-console */

import React from 'react'
import { User, UserManager } from 'oidc-client'
import { useLocation, useHistory } from 'react-router-dom'
import History from 'history'

export type AuthenticationContextProps = {
  signInComplete: boolean,
  signIn: (redirectUri: string, domainHint?: string) => Promise<void>,
  redirectToSignIn: (redirectUri: string) => void,
  signOut: () => void,
  changeSubscriptionComplete: boolean,
  changeSubscription: (subscriptionId: string, redirectTo?: string) => void,
  getAccessToken: () => string | undefined,
  isKnownUser: () => boolean,
  user: User | undefined,
  userManager: UserManager,
  unauthorizedUri: string,
  error: string | undefined,
}

export const AuthenticationContext = React.createContext<AuthenticationContextProps | undefined>(undefined)

export type AuthenticationProviderProps = {
  clientId: string,
  authority: string,
  scope: string,
  redirectUri: string,
  proxyRedirectUri?: string,
  postLogoutRedirectUri: string,
  loginErrorRedirectUri?: string,
  signinRedirectUri: string,
  unauthorizedUri: string,
  extraQueryParams?: any,
}

const initUserManager = (props: AuthenticationProviderProps) => {
  return new UserManager({
    client_id: props.clientId,
    authority: props.authority,
    scope: props.scope,
    response_type: 'id_token token',
    automaticSilentRenew: true,
    silentRequestTimeout: 30000,
    loadUserInfo: false,
    redirect_uri: props.proxyRedirectUri ? `${props.proxyRedirectUri}index.html` : props.redirectUri,
    post_logout_redirect_uri: props.postLogoutRedirectUri,
    silent_redirect_uri: props.proxyRedirectUri ? `${props.proxyRedirectUri}index.html` : props.redirectUri,
    revokeAccessTokenOnSignout: true,
    extraQueryParams: props.extraQueryParams,
  })
}

const hasCodeInUrl = (location: History.Location): boolean => {
  const searchParams = new URLSearchParams(location.search)
  const hashParams = new URLSearchParams(location.hash.replace('#', '?'))

  return Boolean(
    searchParams.get('code')
    || searchParams.get('id_token')
    || searchParams.get('session_state')
    || searchParams.get('error')
    || hashParams.get('code')
    || hashParams.get('id_token')
    || hashParams.get('session_state')
    || hashParams.get('error'),
  )
}

const setProxyRedirectUri = (uri: string, proxyUri: string | undefined) => {
  if (proxyUri) {
    return new Promise<void>((resolve) => {
      const proxyIframe = document.getElementById('loginProxy') as HTMLIFrameElement
      const proxyIframeWindow = proxyIframe?.contentWindow
      const sendUri = () => {
        proxyIframeWindow?.postMessage(uri, new URL(proxyUri).origin)
        resolve()
      }
      sendUri()
    })
  }
  return Promise.resolve()
}

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  children,
  ...props
}) => {

  const history = useHistory()
  const location = useLocation()
  const [signInComplete, setSignInComplete] = React.useState<boolean>(false)
  const [changeSubscriptionComplete, setChangeSubscriptionComplete] = React.useState<boolean>(true)
  const [user, setUser] = React.useState<User | undefined>(undefined)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const userManager = initUserManager(props)
  const userRef = React.useRef(user)

  React.useEffect(() => {
    userRef.current = user
  }, [userRef, user])

  React.useEffect(() => {
    const getUser = async () => {
      if (hasCodeInUrl(location)) {
        setSignInComplete(false)
        try {
          const response = await userManager.signinCallback()
          const state = response?.state
          const userData = await userManager.getUser()
          if (userData) {
            if (state && state.redirectUri) {
              history.replace(state.redirectUri)
            }
            setSignInComplete(true)
          }
        } catch (reason: any) {
          setError(reason)
          console.log(reason)
          if (props.loginErrorRedirectUri) {
            history.replace(props.loginErrorRedirectUri)
          }
          setSignInComplete(true)
        }
      } else {
        setSignInComplete(true)
      }
    }
    getUser()
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  const signIn = React.useCallback(async (redirectUri: string, domainHint?: string) => {
    if (domainHint) {
      localStorage.setItem('domainHint', domainHint)
    }

    setSignInComplete(false)
    await setProxyRedirectUri(props.redirectUri, props.proxyRedirectUri)
    return userManager.signinRedirect({
      state: {
        redirectUri: redirectUri,
      },
      extraQueryParams: {
        domain_hint: domainHint,
      },
    })
  }, [props.proxyRedirectUri, props.redirectUri, userManager])

  const getAccessToken = React.useCallback(() => userRef.current && userRef.current.access_token, [userRef])
  const redirectToSignIn = React.useCallback((redirectTo: string) => {
    const domainHint = localStorage.getItem('domainHint')
    if (domainHint) {
      signIn(redirectTo, domainHint)
    } else {
      history.push(props.signinRedirectUri, {
        redirectTo,
      })
    }
  }, [props.signinRedirectUri, history, signIn])

  const value: AuthenticationContextProps = {
    signInComplete,
    signIn,
    signOut: () => {
      localStorage.removeItem('domainHint')
      userManager.signoutRedirect({
        extraQueryParams: {
          client_id: userManager.settings.client_id,
        },
      })
    },
    redirectToSignIn,
    changeSubscriptionComplete,
    changeSubscription: async (subscriptionId: string, redirectTo?: string) => {
      setChangeSubscriptionComplete(false)
      userManager.settings.extraQueryParams = { subscription: subscriptionId }
      if (user?.profile.idp === 'logicidentitylocalusersdev.onmicrosoft.com'
        || user?.profile.idp === 'logicidentitylocalusers.onmicrosoft.com') {
        await userManager.signinRedirect({
          state: {
            redirectUri: redirectTo || history.location,
          },
        })
      }
      await userManager.signinSilent()
      await userManager.getUser()
      setChangeSubscriptionComplete(true)
      if (redirectTo) {
        history.push(redirectTo)
      }
    },
    getAccessToken,
    user,
    userManager,
    error,
    unauthorizedUri: props.unauthorizedUri,
    isKnownUser: React.useCallback(() => {
      return Boolean(localStorage.getItem('domainHint'))
    }, []),
  }

  userManager.events.addUserLoaded((userData) => setUser(userData))
  userManager.events.addAccessTokenExpiring(() => console.info('Token expiring. Need to renewe'))
  userManager.events.addAccessTokenExpired(() => {
    console.info('Token has expired. User needs to login.')
    value.signOut()
  })
  userManager.events.addSilentRenewError((renewError) => {
    console.error(renewError)
    value.signOut()
  })

  return (
    <AuthenticationContext.Provider value={value}>
      {props.proxyRedirectUri ? <iframe title="proxy" style={{ display: 'none' }} id="loginProxy" src={`${props.proxyRedirectUri}/iframe.html`} /> : null}
      {hasCodeInUrl(location) ? null : children}
    </AuthenticationContext.Provider>
  )
}

export const useAuthentication = (): AuthenticationContextProps => {
  const context = React.useContext<AuthenticationContextProps | undefined>(AuthenticationContext)
  if (!context) {
    throw Error('You cannot use `useAuthentication` outside of `AuthenticationProvider`')
  }

  return context
}
