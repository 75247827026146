import React from 'react'
import { PageHeader } from '../content/PageHeader/PageHeader'
import { Section } from '../content/Section/Section'
import { Skeleton } from '../index'

export const FieldSkeleton = (): JSX.Element => (
  <div className="field">
    <div><Skeleton className="field-skeleton" width={100} /></div>
    <div><Skeleton className="field-skeleton" width={324} height={35} /></div>
  </div>
)

export const FormSkeleton = (): JSX.Element => (
  <React.Fragment>
    <PageHeader loading={true} heading="" subheading="" />
    <Section>
      <FieldSkeleton />
      <FieldSkeleton />
      <FieldSkeleton />
    </Section>
  </React.Fragment>
)
