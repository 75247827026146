import {
  Page,
  PageHeader,
  Table,
  CopyToClipboard,
  LinkButton,
} from '@logic/platform-fabric'
import React from 'react'
import { Column } from 'react-table'
import { AdminSubscription } from '../SubscriptionClient'
import { useAllSubscriptions } from './useAllSubscriptions'
import PeopleIcon from '@material-ui/icons/People'
import { Link } from 'react-router-dom'

export const ManageSubscriptions = (): JSX.Element => {
  const { value, completed } = useAllSubscriptions()
  const columns: Column<AdminSubscription>[] = [
    {
      accessor: 'name',
      Header: 'Name',
    },
    {
      accessor: 'id',
      Header: 'ID',
      Cell: ({ row }: any) => <React.Fragment>{row.original.id}<CopyToClipboard text={row.original.id} /> </React.Fragment>,
    },
    {
      accessor: 'providerApprovalStatus',
      Header: 'Provider approval status',
      Cell: ({ row }: any) => <Link to={`/marketplace/providers/${row.original.providerId}`}>{row.original.providerApprovalStatus}</Link>,
    },
    {
      id: 'actions',
      Header: '',
      Cell: ({ row }: any) => (
        <div className="actions">
          <LinkButton to={`/subscriptions/${row.original.id}/users`} variant="light" size="small" title="Users" icon={PeopleIcon} />
        </div>
      ),
    },
  ]
  return (
    <Page>
      <PageHeader heading="All Logic subscriptions" subheading="Logic management" />
      <Table loading={!completed} data={value || []} columns={columns} defaultSort="name" />
    </Page>
  )
}
