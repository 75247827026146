import React from 'react'
import NotAuthorizedImage from './403.svg'
import { Page, LinkButton } from '@logic/platform-fabric'
import './ErrorPage.scss'

type NotAuthorizedMessageProps = {
  header?: string,
}

export const NotAuthorizedMessage = ({ header = 'Just don\'t! 403' }: NotAuthorizedMessageProps): JSX.Element => {
  return (
    <div className="error-page">
      <img src={NotAuthorizedImage} alt="403" />
      <h1>{header}</h1>
      <p>It looks like you have no access here.</p>
      <div className="action-buttons">
        <LinkButton to="/" variant="primary">Take me from here!</LinkButton>
      </div>
    </div>
  )
}

export const NotAuthorizedPage = (): JSX.Element => {
  return (
    <Page>
      <NotAuthorizedMessage />
    </Page>
  )
}
