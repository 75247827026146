import React from 'react'
import './Page.scss'

export type PageProps = {
  children?: React.ReactNode,
  loading?: boolean,
  skeleton?: React.FC,
  className?: string,
}

export const Page = ({ children, loading, skeleton: Skeleton, className }: PageProps): JSX.Element => {
  return (
    <div className={`${className ? `${className}` : ''} page`} role="main" aria-busy={loading}>
      {loading && Skeleton ? <Skeleton /> : children}
    </div>
  )
}
