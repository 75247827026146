import React from 'react'
import {
  Page,
  PageHeader,
  Table,
} from '@logic/platform-fabric'
import { useAuthentication } from '@logic/platform-core'
import Gravatar from 'react-gravatar'
import { Column } from 'react-table'
import { useUserSubscriptions } from '../subscriptions/UserSubscriptionsProvider'

export const UserSummary = (): JSX.Element => {
  const columns: Column<any>[] = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Role',
      accessor: 'role',
    },
  ]
  const { user } = useAuthentication()
  const { subscriptions }: { subscriptions: any[] } = useUserSubscriptions()
  return (
    <Page>
      <PageHeader
        heading={`${user?.profile.given_name} ${user?.profile.family_name}`}
        logo={<Gravatar email={user?.profile.email} size={76} default="mm" />}
        subheading="User summary"
      />
      <Table
        loading={false}
        data={subscriptions || []}
        columns={columns}
        defaultSort="name"
        noRowsMessage={<p>Looks like you have no subscription.</p>}
      />
    </Page>
  )
}
