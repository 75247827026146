import {
  Data,
  useData,
  useUserSubscriptionId,
} from '@logic/platform-core'
import { Api, useMarketplaceClient } from './MarketplaceClient'

export const useProductApis = (productKey: string): Data<Api[]> => {
  const subscriptionId = useUserSubscriptionId() || ''
  const client = useMarketplaceClient()
  return useData([], () => {
    return client.getProductApis(productKey)
  }, [client, subscriptionId, productKey])
}
