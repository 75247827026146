import React from 'react'
import './TableOfContent.scss'
import classnames from 'classnames'
import { Skeleton } from '@logic/platform-fabric'

export type TableOfContentItemProps = {
  itemKey: string,
  name: string,
  items?: TableOfContentItemProps[],
  state?: any,
}

export type TableOfContentItemComponentProps = TableOfContentItemProps & {
  selectedKey?: string | undefined,
  onSelect: (item: TableOfContentItemProps) => void,
}

const TableOfContentItem = ({ onSelect, selectedKey, ...item }: TableOfContentItemComponentProps) => {
  const classes = classnames({
    selected: selectedKey === item.itemKey,
  })
  return (
    <li className="toc-item">
      <div className={classes} onClick={() => onSelect(item)}>{item.name}</div>
      {item.items && (
        <ul>
          {item.items.map((i) => <TableOfContentItem key={i.itemKey} {...i} selectedKey={selectedKey} onSelect={onSelect} />)}
        </ul>
      )}
    </li>
  )
}

type TableOfContentProps = {
  items: TableOfContentItemProps[],
  loading: boolean,
  selectedKey?: string | undefined,
  onSelect: (item: TableOfContentItemProps) => void,
}

const TableOfContentSkeleton = () => (
  <ul className="table-of-content">
    {new Array(2).fill({}).map(() => (
      <li className="toc-item">
        <div><Skeleton width={150} /></div>
        <ul>
          {new Array(5)
            .fill({})
            .map((_, index) => index)
            .map((index) => (
              <li className="toc-item" key={index}><div><Skeleton width={200} /></div></li>
            ))}
        </ul>
      </li>
    ))}
  </ul>
)

export const TableOfContent = ({ items, loading, selectedKey, onSelect }: TableOfContentProps): JSX.Element => {
  return loading ? <TableOfContentSkeleton /> : (
    <ul className="table-of-content">
      {items.map((item) => <TableOfContentItem key={item.itemKey} {...item} selectedKey={selectedKey} onSelect={onSelect} />)}
    </ul>
  )
}
