import React from 'react'
import ErrorImage from './500.svg'
import { Page, LinkButton } from '@logic/platform-fabric'
import './ErrorPage.scss'

export const ErrorPage = (): JSX.Element => {
  return (
    <Page>
      <div className="error-page">
        <img src={ErrorImage} alt="401" />
        <h1>Well, sh*t! 500</h1>
        <p>It looks like something went wrong</p>
        <div className="action-buttons">
          <LinkButton to="/" variant="primary">Take me from here!</LinkButton>
        </div>
      </div>
    </Page>
  )
}
