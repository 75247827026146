import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Column } from 'react-table'
import {
  Data, useContextSubscriptionId, useData,
} from '@logic/platform-core'
import {
  LinkButton,
  PageHeader, Table,
} from '@logic/platform-fabric'
import { GatewayApiModel } from './GatewayApiModel'
import { useGatewayClient } from './GatewayClient'
import { GatewaySynchronization } from './GatewayProductModel'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import AddIcon from '@material-ui/icons/Add'

const useProductApis = (productId: string, subscriptionId: string): Data<GatewayApiModel[] | undefined> => {
  const client = useGatewayClient()
  return useData([], () => client.getApisByProductId(productId, subscriptionId), [client, productId, subscriptionId])
}

export const ApisTab = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { productId } = useParams<{ productId: string }>()
  const { value, completed } = useProductApis(productId, subscriptionId)

  const renderApiName = (api: GatewayApiModel) => {
    return <Link to={`/subscriptions/${subscriptionId}/provider/products/${productId}/apis/${api.apiVersionSetId}/versions/${api.id}/edit`}>{api.name}</Link>
  }

  const renderSyncStatus = (state: GatewaySynchronization) => {
    switch (state) {
    case GatewaySynchronization.Pending: return <CloudUploadIcon titleAccess="Pending" />
    case GatewaySynchronization.Active: return <CloudDoneIcon titleAccess="Good" />
    case GatewaySynchronization.Faulted: return <SyncProblemIcon titleAccess="Sync Error" color="error" />
    }
  }

  const columns: Column<GatewayApiModel>[] = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row }) => {
        return renderApiName(row.original)
      },
    },
    {
      Header: 'Version',
      accessor: 'apiVersion',
    },
    {
      Header: 'Sync Status',
      accessor: 'synchronization',
      Cell: ({ row }) => {
        return renderSyncStatus(row.original.synchronization)
      },
    },
  ]

  return (
    <React.Fragment>
      <PageHeader>
        <LinkButton icon={AddIcon} variant="primary" to={`/subscriptions/${subscriptionId}/provider/products/${productId}/apis/new`}>Add API</LinkButton>
      </PageHeader>
      <Table
        loading={!completed}
        data={value || []}
        columns={columns}
        defaultSort="name"
        noRowsMessage={<p>Looks like you have no APIs here.<br /><Link to={`/subscriptions/${subscriptionId}/provider/products/${productId}/apis/new`}>Click here to add new API.</Link></p>}
      />
    </React.Fragment>
  )
}
