import React from 'react'
import { Section } from '../content/Section/Section'
import { FieldSkeleton } from './FormSkeleton'

export const SectionSkeleton = (): JSX.Element => (
  <Section>
    <FieldSkeleton />
    <FieldSkeleton />
    <FieldSkeleton />
  </Section>
)
