import {
  formatDistanceStrict, parseISO, format as Format,
} from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import Locale from 'date-fns/locale'

interface DateDistanceOptions {
  includeSeconds?: boolean,
  addSuffix?: boolean,
  locale?: Locale,
}

const parseDate = (date?: string | Date) => {
  if (!date) {
    return new Date()
  }

  return typeof date === 'object' ? date : parseISO(date)
}

export const distanceInWords = (date: string | Date, baseDate?: string | Date, options?: DateDistanceOptions): string =>
  formatDistanceStrict(parseDate(date), parseDate(baseDate), options)

interface DateFormatOptions {
  locale?: Locale,
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
  firstWeekContainsDate?: number,
  useAdditionalWeekYearTokens?: boolean,
  useAdditionalDayOfYearTokens?: boolean,
}

export const formatDate = (date: string | Date, format: string, options?: DateFormatOptions): string => {
  const formatOptions = { locale: enUS, ...options } // Default to enGB

  return Format(parseDate(date), format, formatOptions)
}
