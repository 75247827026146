import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Data,
  useContextSubscriptionId, useData, useNotifications,
} from '@logic/platform-core'
import {
  Button, CancelButton, Field, Form, Section, SectionSkeleton, useSimpleForm,
} from '@logic/platform-fabric'
import { useSubscriptionProvider } from '../useSubscriptionProvider'
import {
  ApisetsStatus, GatewayApiVersionSetModel, GatewayCurrentVersionModel,
} from './GatewayApiModel'
import { useGatewayClient } from './GatewayClient'
import { GatewaySynchronization, GatewayVisibility } from './GatewayProductModel'

export const useApiVersionSet = (subscriptionId: string, apiVersionSetId: string | undefined): Data<GatewayApiVersionSetModel | undefined> => {
  const client = useGatewayClient()
  return useData(
    undefined,
    async () => {
      if (apiVersionSetId) {
        return await client.fetchApiVersionSet(subscriptionId, apiVersionSetId)
      } else {
        return Promise.resolve(undefined)
      }
    },
    [client, subscriptionId, apiVersionSetId]
  )
}

export const useApiPageForm = (subscriptionId: string, providerId: string | undefined,
  productId: string, versionSetId: string, apiId: string) => {
  const client = useGatewayClient()
  const history = useHistory()
  const { addInfo, addError } = useNotifications()
  const apiVersionSetId = versionSetId

  const initialValues: GatewayCurrentVersionModel = React.useMemo(() => ({
    apiId: '',
    providerId: '',
    name: '',
    description: '',
    path: '',
    backendServiceUrl: '',
    apiVersion: '',
    openApiSpecUrl: '',
    logoUrl: '',
    documentationUrl: '',
    productIds: [productId],
    visibility: GatewayVisibility.Public,
    apiVersionSetId: '',
    synchronization: GatewaySynchronization.Active,
    isCurrent: false,
    openApiSpec: new File([], ''),
    status: ApisetsStatus.Preview,
  }), [productId])

  const fetchApiVersionSet = React.useCallback(async () => {
    const apiVersionSet = await client.fetchApiVersionSet(subscriptionId, apiVersionSetId)
    const versionToUpdate = apiVersionSet && apiVersionSet.versions.find((x) => x.apiId === apiId)
    if (!versionToUpdate) {
      addError('Api not found')
      history.push('/resource-not-found')
    }
    const path = apiVersionSet?.path
    const updatedVersion = { ...versionToUpdate, path, apiVersionSetId }
    return (updatedVersion || initialValues)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, subscriptionId, apiVersionSetId, initialValues, history, apiId])

  const saveApi = async (data: any) => {
    const apiData = { ...data, providerId, apiVersionSetId }
    const axiosResponse = apiVersionSetId ? await client.updateApiVersion(apiData, subscriptionId) : await client.createApi(apiData, subscriptionId)
    if (axiosResponse.status === 200 || axiosResponse.status === 201) {
      apiVersionSetId ? addInfo('Api has been updated') : addInfo('Api has been added')
      history.push(`/subscriptions/${subscriptionId}/provider/products/${productId}#apis`)
    } else if (axiosResponse.status === 400 || axiosResponse.status === 409 || axiosResponse.status === 422) {
      if (apiVersionSetId) {
        addError(axiosResponse.data)
      } else {
        const object = (axiosResponse.data).valueOf()
        const errorMessage = Object.values(object)[0] as any
        addError(errorMessage)
      }
    }
  }
  return useSimpleForm(initialValues, (apiVersionSetId ? fetchApiVersionSet : undefined), saveApi)
}

export const ApiForm = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { productId, versionSetId, apiId } = useParams<{ productId: string, versionSetId: string, apiId: string }>()
  const { value: provider } = useSubscriptionProvider(subscriptionId)
  const { loading, ...form } = useApiPageForm(subscriptionId, provider?.id, productId, versionSetId, apiId)

  if (loading) {
    return <SectionSkeleton />
  }

  const apiStatus = [
    { value: ApisetsStatus.Active, text: 'Active' },
    { value: ApisetsStatus.Deprecated, text: 'Deprecated' },
    { value: ApisetsStatus.Preview, text: 'Preview' },
  ]

  const isValidPath = (value: string) => {
    const message = 'Path can contain only letters, numbers, slash, underscore and hyphen'
    return (value.match(/^[A-Za-z0-9/_-]*$/)) ? undefined : message
  }

  return (
    <Section>
      <Form {...form}>
        <div className="field-base">
          {!versionSetId
            && <Field name="name" label="Name" placeholder="e.g., Trading API" type="text" validators={['required']} />
          }
          <Field name="apiVersion" label="Version" placeholder="e.g., 1.0.0" type="text" validators={['required']} />
          <Field name="path" label="Path" type="text" placeholder="e.g., northwind-trading" prefix="gateway.kmdlogic.io/" validators={['required']} validate={isValidPath} />
          <Field name="backendServiceUrl" label="Backend location" placeholder="e.g., https://api.northwind.com/trading" type="text" validators={['required', 'url']} />
          <Field name="documentation" label="Markdown document" type="file" accept=".md" />
          {!versionSetId
            && <Field name="openApiSpec" label="OpenAPI definition" type="file" accept=".json" validators={['required']} />
          }
          {versionSetId
            && <Field name="status" label="State" type="dropdown" options={apiStatus} />
          }
        </div>
        <Button type="submit" variant="primary">Save</Button>
        <CancelButton defaultBackTo={`/subscriptions/${subscriptionId}/provider/products/${productId}#apis`} />
      </Form>
    </Section>
  )
}
