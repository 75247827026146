import { camelCase } from 'lodash'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const normalizeBadRequestResponse = (errors: any): any => {
  const normalizedErrors = {} as any
  Object.keys(errors).forEach((key) => {
    normalizedErrors[camelCase(key)] = errors[key]
  })
  return normalizedErrors
}
