import React from 'react'
import { RestrictedRoute, RestrictedRouteProps } from '../authentication/RestrictedRoute'
import { Unauthorized } from '../authentication/Unauthorized'
import { useConfig } from '../config/ConfigProvider'
import { useUserSubscriptionId } from './useUserSubscriptionId'

const AdminRestrictedRouteContent = ({ children }: any) => {
  const subscriptionId = useUserSubscriptionId()
  const { adminSubscriptionId } = useConfig()
  if (subscriptionId !== adminSubscriptionId) {
    return (<Unauthorized />)
  }
  return (<React.Fragment>{children}</React.Fragment>)
}

export const AdminRestrictedRoute = ({ children, render, component, ...props }: RestrictedRouteProps): JSX.Element => {
  return <RestrictedRoute {...props} render={() => (<AdminRestrictedRouteContent>{children}</AdminRestrictedRouteContent>)} />
}
