import React, { ReactNode } from 'react'
import {
  Formik,
  Form as FormikForm,
  FormikHelpers,
} from 'formik'

export type FormProps<T> = {
  initialValues: any,
  onSubmit: (data: T) => Promise<void>,
  children?: ReactNode,
}

// eslint-disable-next-line @typescript-eslint/ban-types
const handleSubmit = <T extends object>(onSubmit: (data: T) => Promise<void>) => (data: T, actions: FormikHelpers<T>) => {
  return onSubmit(data).catch((result) => {
    Object.keys(result).forEach((field) => {
      actions.setFieldError(field, result[field][0])
    })
  })
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const Form = <T extends object>({ initialValues, onSubmit, children }: FormProps<T>): JSX.Element => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormikForm>
        {children}
      </FormikForm>
    </Formik>
  )
}
