import { normalizeBadRequestResponse, useConfig } from '@logic/platform-core'
import { AxiosInstance } from 'axios'
import { useAxios } from '../infrastructure/useAxios'

export type CreateSubcription = {
  name: string,
  isProduction: boolean,
  addMeToSubscription?: boolean,
}

export type EditSubscription = {
  name: string,
  isProduction: boolean,
  billingInfo: string,
}

export type Subscription = {
  id: string,
  name: string,
  isProduction: boolean,
}

export type SubscriptionDetails = Subscription & {
  billingInfo: string,
}

export type AdminSubscription = {
  id: string,
  name: string,
  providerApprovalStatus: string,
  providerId: string,
}

export type UserSubscription = {
  id: string,
  name: string,
  isProduction?: boolean,
  role: 'Owner' | 'Contributor',
}

const onError = (reason: any) => {
  if (reason.response.status === 400) {
    return Promise.reject(normalizeBadRequestResponse(reason.response.data))
  } else {
    return Promise.reject(reason)
  }
}

const getSubscriptions = (client: AxiosInstance) => () => {
  return client.get('/subscriptions').then((response) => response.data.map((subscription: any) => ({
    id: subscription.subscriptionId,
    name: subscription.subscriptionName,
    role: subscription.role,
    isProduction: subscription.isProduction,
  })))
}

const getSubscription = (client: AxiosInstance) => (subscriptionId: string) => {
  return client.get(`/subscriptions/${subscriptionId}`).then((response) => ({
    id: response.data.id,
    name: response.data.name,
    billingInfo: response.data.billingInfo,
    isProduction: response.data.isProduction,
  }))
}

const getAllSubscriptions = (client: AxiosInstance) => () => {
  return client.get('/admin/subscriptions').then((response) => response.data.map((subscription: any) => ({
    id: subscription.subscriptionId,
    name: subscription.subscriptionName,
    providerApprovalStatus: subscription.providerApprovalStatus,
    providerId: subscription.providerId,
  })))
}

const postSubscription = (client: AxiosInstance) => (model: CreateSubcription) => {
  return client.post<Subscription>('/subscriptions', model).then((response) => response.data, onError)
}

const putSubscription = (client: AxiosInstance) => (subscriptionId: string, model: EditSubscription) => {
  return client.post<Subscription>('/subscriptions', {
    id: subscriptionId,
    ...model,
  }).then((response) => response.data, onError)
}

export type SubscriptionClient = {
  postSubscription: (model: CreateSubcription) => Promise<Subscription>,
  putSubscription: (subscriptionId: string, model: EditSubscription) => Promise<Subscription>,
  getSubscriptions: () => Promise<UserSubscription[]>,
  getSubscription: (subscriptionId: string) => Promise<SubscriptionDetails>,
  getAllSubscriptions: () => Promise<AdminSubscription[]>,
}

const createSubscriptionClient = (client: AxiosInstance): SubscriptionClient => {
  return {
    postSubscription: postSubscription(client),
    putSubscription: putSubscription(client),
    getSubscriptions: getSubscriptions(client),
    getSubscription: getSubscription(client),
    getAllSubscriptions: getAllSubscriptions(client),
  }
}

export const useSubscriptionClient = (): SubscriptionClient => {
  const config = useConfig()
  return useAxios(config.labPlatformApiUrl, createSubscriptionClient)
}
