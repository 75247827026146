import { Data, useData } from '@logic/platform-core'
import { useResourceClient } from './ResourceClient'
import { ResourceModel, ResourceStatus } from './ResourceModel'

export const useResources = (subscriptionId: string): Data<ResourceModel[]> => {
  const client = useResourceClient()
  return useData<ResourceModel[]>([], () => client.getResources(subscriptionId).then((resources) => (
    [...resources, {
      id: 'data-extracts',
      subscriptionId,
      name: 'Data extracts',
      resourceType: 'Data Services',
      status: ResourceStatus.Published,
      createdAt: '',
      updatedAt: '',
    }]
  )), [subscriptionId])
}
