import React from 'react'
import { BaseFieldControlProps } from './BaseFieldControlProps'
import { useFieldValue } from './useFieldValue'

export type StaticFieldProps = {
  type: 'static',
}

export type StaticFieldControlProps = StaticFieldProps & BaseFieldControlProps

export const StaticFieldControl = ({ name, validate, ...props }: StaticFieldControlProps): JSX.Element => {
  const value = useFieldValue<any>(name)
  return (<div {...props} className="static-field">{value}</div>)
}
