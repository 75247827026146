import React from 'react'
import { BaseFieldControlProps } from './BaseFieldControlProps'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import { useToggleState } from '@react-stately/toggle'
import { useFocusRing } from '@react-aria/focus'
import { useSwitch } from '@react-aria/switch'
import { useField } from 'formik'

export type SwitchFieldProps = {
  type: 'switch',
  enabledValue?: any,
  disabledValue?: any,
}

export type SwitchFieldControlProps = SwitchFieldProps & BaseFieldControlProps

export const SwitchFieldControl = (props: SwitchFieldControlProps): JSX.Element => {
  const [, { value }, { setValue }] = useField(props.name)
  const isSelected = React.useMemo(() => {
    if (props.enabledValue && props.enabledValue === value) return true
    if (props.disabledValue && props.disabledValue === value) return false
    return value === true
  }, [value, props.enabledValue, props.disabledValue])
  const onChange = React.useCallback((selected: boolean) => {
    setValue(selected ? (props.enabledValue || true) : (props.disabledValue || false))
  }, [setValue, props.enabledValue, props.disabledValue])
  const state = useToggleState({ isSelected, onChange, ...props })
  const ref = React.useRef() as any
  const { inputProps } = useSwitch(props, state, ref)
  const { isFocusVisible, focusProps } = useFocusRing()

  return (
    <div className="field-switch">
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg width={58} height={35} aria-hidden="true" style={{ marginRight: 4 }}>
        <rect
          x={4}
          y={4}
          width={52}
          height={30}
          rx={15}
          fill={state.isSelected ? '#1676FF' : '#ffffff'}
          strokeWidth={state.isSelected ? 0 : 1}
          stroke="#aaaab4"
        />
        <circle cx={state.isSelected ? 41 : 19} cy={19} r={12} fill="white" stroke={state.isSelected ? '#fff' : '#aaaab4'} strokeWidth="1" />
        {isFocusVisible && (
          <rect
            x={1}
            y={1}
            width={58}
            height={35}
            rx={11}
            fill="none"
            stroke="#1676FF"
            strokeWidth={2}
          />
        )}
      </svg>
    </div>
  )
}
