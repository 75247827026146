import React from 'react'
import { useField } from 'formik'
import { BaseFieldControlProps } from './BaseFieldControlProps'
import { useRadioGroup, useRadio } from '@react-aria/radio'
import { useRadioGroupState, RadioGroupState } from '@react-stately/radio'
import { VisuallyHidden } from '@react-aria/visually-hidden'

const RadioContext = React.createContext<RadioGroupState | undefined>(undefined)

const RadioGroup = (props: any) => {
  const [, { value }, { setValue }] = useField(props.name)
  const { children } = props
  const state = useRadioGroupState({ value, onChange: setValue, ...props })
  const { radioGroupProps } = useRadioGroup(props, state)

  return (
    <div {...radioGroupProps}>
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
    </div>
  )
}

const Radio = (props: any) => {
  const { children } = props
  const state = React.useContext<RadioGroupState | undefined>(RadioContext)
  if (!state) {
    throw Error('Cannot use Radio outside of RadioGroup')
  }
  const ref = React.useRef(null)
  const { inputProps } = useRadio(props, state, ref)
  const isSelected = state.selectedValue === props.value
  const strokeWidth = isSelected ? 2 : 1

  return (
    <label style={{ display: 'flex', alignItems: 'center' }} className={isSelected ? 'checked' : 'not-checked'}>
      <VisuallyHidden>
        <input {...inputProps} ref={ref} />
      </VisuallyHidden>
      <svg width={28} height={28} aria-hidden="true" style={{ marginRight: 16 }}>
        <circle
          cx={14}
          cy={14}
          r={14 - strokeWidth}
          fill="#fff"
          stroke={isSelected ? '#1676ff' : '#aaaab4'}
          strokeWidth={strokeWidth}
        />
        {isSelected && (
          <circle
            cx={14}
            cy={14}
            r={8}
            fill="#1676ff"
          />
        )}
      </svg>
      {children}
    </label>
  )
}

export type RadioGroupFieldProps = {
  type: 'radio',
  options: { value: string, label: string }[],
}

export type RadioGroupFieldControlProps = RadioGroupFieldProps & BaseFieldControlProps

export const RadioGroupFieldControl = ({ name, options }: RadioGroupFieldControlProps): JSX.Element => (
  <RadioGroup name={name}>
    {options.map(({ value, label }) => (<Radio label={label} value={value} key={value}>{label}</Radio>))}
  </RadioGroup>
)
