import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Column } from 'react-table'
import {
  Data, useContextSubscriptionId, useData, useNotifications,
} from '@logic/platform-core'
import {
  Button,
  LinkButton, PageHeader, Table,
} from '@logic/platform-fabric'
import {
  GatewayApiVersionSetModel,
  GatewayCurrentVersionModel,
} from './GatewayApiModel'
import { useGatewayClient } from './GatewayClient'
import AddIcon from '@material-ui/icons/Add'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { useQuestionDialog } from '../..'
import { useSubscriptionRole } from '../../subscriptions/useSubscriptionRole'
import { useSubscriptionProvider } from '../useSubscriptionProvider'
import DeleteIcon from '@material-ui/icons/Delete'
import './VersionsTab.scss'

export const useVersionApis = (subscriptionId: string, versionSetId: string): Data<GatewayApiVersionSetModel | undefined> => {
  const client = useGatewayClient()
  return useData(undefined, () => client.fetchApiVersionSet(subscriptionId, versionSetId), [client, versionSetId, subscriptionId])
}

export const VersionsTab = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { versionSetId, productId, apiId: applicationId } = useParams<{ versionSetId: string, productId: string, apiId: string }>()
  const { value, completed, refresh } = useVersionApis(subscriptionId, versionSetId)
  const { value: provider } = useSubscriptionProvider(subscriptionId)
  const userRole = useSubscriptionRole(subscriptionId)
  const client = useGatewayClient()
  const { askQuestion } = useQuestionDialog()
  const { addInfo } = useNotifications()

  const apiOwner = () => {
    if (userRole === 'Owner' && value?.providerId === provider?.id && provider?.status === 'Approved') {
      return true
    } else {
      return false
    }
  }

  const renderVersionName = (data: any) => {
    return (
      <Link to={`/subscriptions/${subscriptionId}/provider/products/${productId}/apis/${versionSetId}/versions/${data.apiId}/${data.apiVersion}/edit`}>
        {data.apiVersion}
      </Link>
    )
  }

  const makeApiVersionIsCurrent = React.useCallback(async (apiId: string, apiVersion: string) => {
    return askQuestion(`Are you sure you want to make ${apiVersion} API version as current?`, 'Change API version', ({ answer }) => {
      if (answer === 'yes') {
        return client.updateApiVersionIsCurrent(subscriptionId, apiId).then(() => {
          refresh()
          addInfo(`${apiVersion} has been changed to current version`)
        })
      }

      return undefined
    })
  }, [askQuestion, client, subscriptionId, refresh, addInfo])

  const deleteApiVersion = React.useCallback(async (apiId: string, apiVersion: string) => {
    return askQuestion(`Are you sure you want to delete ${apiVersion} version?`, 'Delete API version', ({ answer }) => {
      if (answer === 'yes') {
        return client.deleteApiVersion(subscriptionId, apiId).then(() => {
          refresh()
          addInfo(`API version ${apiVersion} has been deleted successfully`)
        })
      }

      return undefined
    })
  }, [askQuestion, client, subscriptionId, refresh, addInfo])

  const columns: Column<GatewayCurrentVersionModel>[] = [
    {
      Header: 'Version Name',
      accessor: 'apiVersion',
      Cell: ({ row }) => {
        return renderVersionName(row.original)
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Is Current',
      accessor: 'isCurrent',
      Cell: ({ row }: any) => {
        return row.original.isCurrent ? <Button icon={CheckBoxIcon} className="checkBoxIcon" size="small" />
          : (
            apiOwner()
              ? <Button icon={CheckBoxOutlineBlankIcon} size="small" variant="light" onPress={() => makeApiVersionIsCurrent(row.original.apiId, row.original.apiVersion)} />
              : <Button icon={CheckBoxOutlineBlankIcon} size="small" variant="light" />)
      },
    },
    {
      id: 'icon2',
      Header: '',
      Cell: ({ row }: any) => {
        return (
          <div className="actions align-left">
            {apiOwner() && !row.original.isCurrent
            && <Button icon={DeleteIcon} size="small" title="Delete" variant="light" onPress={() => deleteApiVersion(row.original.apiId, row.original.apiVersion)} />
            }
          </div>
        )
      },
    },
  ]

  return (
    <React.Fragment>
      <PageHeader>
        <LinkButton
          icon={AddIcon}
          variant="primary"
          to={`/subscriptions/${subscriptionId}/provider/products/${productId}/apis/${versionSetId}/versions/${applicationId}/create`}
        >
          Add Version
        </LinkButton>
      </PageHeader>
      <Table
        loading={!completed}
        data={value?.versions || []}
        columns={columns}
        defaultSort="apiVersion"
        noRowsMessage={(
          <p>
            Looks like you have no API Versions here.
            <br />
            <Link
              to={`/subscriptions/${subscriptionId}/provider/products/${productId}/apis/${versionSetId}/versions/${applicationId}/create`}
            >
              Click here to add new API Version.
            </Link>
          </p>
        )}
      />
    </React.Fragment>
  )
}
