import React from 'react'
import {
  Table, CopyToClipboard, ToggleSwitch,
} from '@logic/platform-fabric'
import { useGatewayClient } from './GatewayClient'
import { Column, Row } from 'react-table'
import {
  Data,
  useContextSubscriptionId,
  useData,
} from '@logic/platform-core'
import { useParams } from 'react-router-dom'
import { GatewayProductConsumersModel } from './GatewayProductModel'

const useProductConsumers = (subscriptionId: string, productId: string): Data<GatewayProductConsumersModel[]> => {
  const client = useGatewayClient()
  return useData([], () => {
    if (productId) {
      return client.getProductConsumers(subscriptionId, productId)
    } else {
      return Promise.resolve([])
    }
  }, [client, productId])
}

export const ProductConsumersTab = (): JSX.Element => {
  const { productId } = useParams<{ productId: string }>()
  const subscriptionId = useContextSubscriptionId()
  const { value, completed } = useProductConsumers(subscriptionId, productId)

  const columns: Column<GatewayProductConsumersModel>[] = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'name',
      Cell: ({ row }: any) => {
        return <React.Fragment>{row.original.name} {row.original.isProduction && (<span className="chip">Production</span>)}</React.Fragment>
      },
      Filter: ({ column: { filterValue = { search: '', isProduction: false }, setFilter } }: any) => (
        <div style={{ display: 'flex', columnGap: '10px' }}>
          <input value={filterValue?.search} onChange={(e) => { setFilter({ ...filterValue, search: e.target.value }) }} />
          <ToggleSwitch labelPosition="right" isSelected={filterValue?.isProduction} onChange={(val) => { setFilter({ ...filterValue, isProduction: val }) }} label="Production" />
        </div>
      ),
      filter: React.useCallback((rows: Row<GatewayProductConsumersModel>[], _: any, filterValue: any) => {
        return rows.filter((row: any) =>
          (filterValue?.isProduction
            ? (row.original.isProduction === true) && (((row.original.name).toLowerCase())?.indexOf((filterValue?.search).toLowerCase()) !== -1)
            : (((row.original.name).toLowerCase())?.indexOf((filterValue?.search).toLowerCase()) !== -1)))
      }, []),
    } as Column<GatewayProductConsumersModel>,

    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({ row }: any) => {
        return <React.Fragment>{row.original.id}<CopyToClipboard text={row.original.id} /> </React.Fragment>
      },
    },
  ]

  return (
    <Table
      loading={!completed}
      data={value || []}
      columns={columns}
      defaultSort="name"
      noRowsMessage="Looks like you have no records here"
    />
  )
}
