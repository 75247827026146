import React from 'react'
import {
  Section,
  PageHeader,
  Markdown,
  CopyToClipboard,
} from '@logic/platform-fabric'
import {
  Link,
  NavLink,
} from 'react-router-dom'
import './ResourceSummary.scss'
import {
  ServiceDescription,
  useContextSubscriptionId,
} from '@logic/platform-core'
import { ClientCredentialsWidget } from '../client-credentials/ClientCredentialsWidget'
import { ClientCredentials } from '../client-credentials/ClientCrendetialsClient'
import { ProductDetails } from './MarketplaceClient'
import { ApiKeys } from './api-keys/ApiKeys'

type ResourceClientCredentialsProps = {
    selectedCCValues?: ClientCredentials | undefined,
    defaultCCValues?: ClientCredentials | undefined,
    productInfo?: ProductDetails | undefined,
    service?: ServiceDescription | undefined,
    refresh: () => void,
}

export const ResourceClientCredentials = (props: ResourceClientCredentialsProps): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { selectedCCValues, defaultCCValues, productInfo, refresh, service } = props

  return (
    <div>
      <React.Fragment>
        {productInfo?.clientCredentialsRequired === true
          ? (
            selectedCCValues ? (
              <Section>
                <PageHeader heading="Client Credentials" />
                <ClientCredentialsWidget
                  clientId={selectedCCValues.clientId}
                  clientSecret={selectedCCValues.clientSecret}
                  resourceScope={selectedCCValues.resourceScope}
                  applicationIds={selectedCCValues.applicationIds || []}
                  tokenEndpoint={selectedCCValues.tokenEndpoint}
                  resourceDescription={selectedCCValues.resourceDescription}
                  refresh={refresh}
                  showActionButtons={false}
                />
              </Section>
            ) : (
              <Section>
                <PageHeader heading="Client Credentials" />
                <span>This product requires a valid Client Credentials and is optional to create at this stage.
                  Please contact with the <Link to={`/marketplace/${productInfo.key}`}>provider</Link>
                </span>
              </Section>
            )
          )
          : (defaultCCValues && service !== undefined)
            && (
              <Section>
                <PageHeader heading="Client Credentials" />
                <ClientCredentialsWidget
                  clientId={defaultCCValues.clientId}
                  clientSecret={defaultCCValues.clientSecret}
                  resourceScope={defaultCCValues.resourceScope}
                  applicationIds={defaultCCValues.applicationIds || []}
                  tokenEndpoint={defaultCCValues.tokenEndpoint}
                  resourceDescription={defaultCCValues.resourceDescription}
                  refresh={refresh}
                  showActionButtons={false}
                />
              </Section>
            )
        }

        {(service !== undefined && !defaultCCValues)
        && (
          <Section>
            <PageHeader heading="Client Credentials" />
            <span>If required a valid <strong>Logic Identity Client Credentials</strong>, you can request
              <NavLink to={`/subscriptions/${subscriptionId}/client-credentials`}><strong> here</strong></NavLink>
            </span>
          </Section>
        )}
      </React.Fragment>
    </div>
  )
}

type GetStartedMarkDownDocumentProps = {
    productInfo?: ProductDetails | undefined,
}

export const GetStartedMarkDownDocument = (props: GetStartedMarkDownDocumentProps): JSX.Element => {
  const { productInfo } = props
  return (
    <React.Fragment>
      {productInfo?.getStartedUrl
            && (
              <Section>
                <Markdown source={productInfo.getStartedUrl} />
              </Section>
            )}
    </React.Fragment>
  )
}

type ResourceDetailsProps = {
    apiKeyName?: string,
    subscriptionId: string,
    openProduct?: boolean,
    productInfo?: ProductDetails | undefined,
    keyValue?: string,
    apiKeyId: string,
}

export const ResourceDetails = (props: ResourceDetailsProps): JSX.Element => {
  const { subscriptionId, openProduct, apiKeyName, keyValue, apiKeyId } = props

  return (
    <React.Fragment>
      <Section>
        <PageHeader heading={apiKeyName ? apiKeyName : keyValue} />
        {!openProduct
          ? (
            <div className="properties mt15">
              <div>
                <div className="name">Resource ID</div>
                <div className="value">{apiKeyId}</div>
                <div className="copy"><CopyToClipboard text={apiKeyId} /></div>
              </div>
              <div>
                <div className="name">Subscription ID</div>
                <div className="value">{subscriptionId}</div>
                <div className="copy"><CopyToClipboard text={subscriptionId} /></div>
              </div>
            </div>
          )
          : (
            <div className="properties mt15">
              <div>
                <div className="name">Subscription ID</div>
                <div className="value">{subscriptionId}</div>
                <div className="copy"><CopyToClipboard text={subscriptionId} /></div>
              </div>
            </div>
          )
        }
      </Section>
    </React.Fragment>
  )
}

export const ResourceApikeys = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className="api-header">APIKeys</div>
      <ApiKeys isRefresh={false} />
    </React.Fragment>
  )
}
