import { useUserSubscriptionId, useAuthentication } from '@logic/platform-core'
import {
  Button,
  Dialog,
  Field,
  Form,
  useSimpleForm,
} from '@logic/platform-fabric'
import React from 'react'
import { CreateSubcription, useSubscriptionClient } from './SubscriptionClient'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { useUserSubscriptions } from './UserSubscriptionsProvider'

const useCreateSubscriptionDialogForm = ({ closeDialog }: any) => {
  const { addSubscription } = useUserSubscriptions()
  const client = useSubscriptionClient()
  const createSubscription = React.useCallback((values: CreateSubcription) => {
    return client.postSubscription({ ...values, addMeToSubscription: true }).then((subscription) => {
      addSubscription({ id: subscription.id, name: subscription.name, role: 'Owner' })
      closeDialog(subscription.id)
    })
  }, [client, closeDialog, addSubscription])
  return useSimpleForm({ name: '', isProduction: false }, undefined, (values) => createSubscription(values))
}

export type CloseCallback = (subscriptionId?: string | undefined) => void

export type CreateSubscriptionDialogProps = {
  isOpen: boolean,
  onClose: CloseCallback,
}

export const CreateSubscriptionDialog = ({ isOpen, onClose }: CreateSubscriptionDialogProps): JSX.Element => {
  const form = useCreateSubscriptionDialogForm({ closeDialog: onClose })
  const { subscriptions } = useUserSubscriptions()
  return (
    <Dialog isOpen={isOpen} title="Create subscription">
      {subscriptions.length === 0 && (
        <p>
          It looks like this is your first time using Logic. To get started you will just need to create a subscription.
          There is no limit to the number of subscriptions you can have.
        </p>
      )}
      {subscriptions.length > 0 && (
        <p>
          Do you need another subscription? Go for it.
        </p>
      )}
      <Form {...form}>
        <Field name="name" label="Subscription name" placeholder="e.g., northwind-dev" validators={['required']} />
        <Field type="switch" label="Production" name="isProduction" />
        <div style={{ marginTop: 40 }}>
          <Button type="submit" variant="primary">Ok, create subscription</Button>
          <Button type="button" onPress={() => onClose()}>Cancel</Button>
        </div>
      </Form>
    </Dialog>
  )
}

export type CreateSubscriptionDialogControl = {
  open: () => void,
  close: () => void,
  onCloseRef: React.MutableRefObject<CloseCallback | undefined>,
}

export const useCreateSubscriptionDialogControl = () => {
  const state = useOverlayTriggerState({})
  const onCloseRef = React.useRef<CloseCallback | undefined>()
  return {
    dialogProps: {
      isOpen: state.isOpen,
      onClose: (subscriptionId?: string) => onCloseRef.current?.(subscriptionId),
    },
    dialogControl: {
      open: state.open,
      close: state.close,
      onCloseRef,
    },
  }
}

export const useSubscriptionRequiredLink = (to: string, dialogControl: CreateSubscriptionDialogControl) => {
  const userSubscriptionId = useUserSubscriptionId()
  const { changeSubscription } = useAuthentication()
  const { user, redirectToSignIn } = useAuthentication()
  dialogControl.onCloseRef.current = React.useCallback((subscriptionId: string | undefined) => {
    if (subscriptionId) {
      changeSubscription(subscriptionId, `/subscriptions/${subscriptionId}${to}`)
    }
    dialogControl.close()
  }, [dialogControl, changeSubscription, to])

  const onClick = React.useCallback((event: any) => {
    if (!user) {
      redirectToSignIn(location.pathname)
      event.preventDefault()
    } else if (!userSubscriptionId) {
      dialogControl.open()
      event.preventDefault()
    }
  }, [dialogControl, redirectToSignIn, user, userSubscriptionId])

  return {
    to: userSubscriptionId ? `/subscriptions/${userSubscriptionId}${to}` : '',
    onClick,
  }
}
