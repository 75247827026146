import React from 'react'
import {
  Page,
  PageHeader,
  Tab,
  Tabs,
} from '@logic/platform-fabric'
import { Redirect, useParams } from 'react-router-dom'
import { useProduct } from '../useProduct'
import { OverviewTab } from './OverviewTab'
import { ProductTermsTab } from './ProductTermsTab'
import { AddProductButton } from './AddProductButton'
import { ApiReferenceTab } from './ApiReferenceTab'
import { useTitle } from '@logic/platform-core'

export const ProductDescriptionPage = (): any => {
  const { key } = useParams<{ key: string }>()
  const { value, completed, error } = useProduct(key)
  useTitle(() => (value ? `${value.name} - Marketplace` : 'Marketplace'), [value])

  if (!completed && error) {
    return <Redirect to="/resource-not-found" />
  }

  return (
    <Page>
      <PageHeader
        loading={!completed}
        logo={value?.logoUrl || '/default-product-icon.png'}
        heading={value?.name || ''}
        subheading={value?.providerName || ''}
      >
        <AddProductButton productKey={key} />
      </PageHeader>
      <Tabs>
        <Tab name="overview" title="Overview">
          <OverviewTab documentationUrl={value?.documentationUrl} completed={completed} contactProvider={value?.contactProvider} />
        </Tab>
        <Tab name="api-reference" title="API Reference">
          <ApiReferenceTab productKey={key} apiKeyRequired={value?.apiKeyRequired} />
        </Tab>
        <Tab name="terms-and-conditions" title="Terms & conditions">
          <ProductTermsTab productTerms={value?.productTerms} />
        </Tab>
      </Tabs>
    </Page>
  )
}
