import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { SubscriptionsSelector } from '../subscriptions/SubscriptionsSelector'
import './Header.scss'
import TechHeartLogo from './TechHeartLogo'
import { UserPopupMenu } from './UserPopupMenu'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import {
  useAuthentication, useConfig, useUserSubscriptionId,
} from '@logic/platform-core'
import TuneIcon from '@material-ui/icons/Tune'
import { ShareYourProductButton } from '../provider/ShareYourProductButton'

const Logo = () => (
  <NavLink to="/" className="header-logo">
    <TechHeartLogo />
    <span>Logic</span>
  </NavLink>
)

type HeaderProps = {
  onNotificationClick: () => void,
  dropShadow: boolean,
  showSubscriptions: boolean,
  showShareProducts: boolean,
}

export const Header = ({ onNotificationClick, dropShadow, showSubscriptions, showShareProducts }: HeaderProps): JSX.Element => {
  const { adminSubscriptionId } = useConfig()
  const userSubscriptionId = useUserSubscriptionId()
  const { user } = useAuthentication()
  const isAdmin = adminSubscriptionId === userSubscriptionId
  return (
    <div className={`header ${dropShadow ? '' : 'no-shadow'}`}>
      <Logo />
      {user && showSubscriptions && <SubscriptionsSelector />}
      <div className="header-actions">
        {showShareProducts && <ShareYourProductButton />}
        {isAdmin && <Link title="Manage" to="/manage"><TuneIcon /></Link>}
        {user && (
          <button onClick={onNotificationClick} type="button" title="Notifications">
            <NotificationsNoneIcon />
          </button>
        )}
        <UserPopupMenu />
      </div>
    </div>
  )
}
