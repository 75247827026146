import React, { ReactNode } from 'react'
import './CodeSnippet.scss'

export const JsonSnippet = ({ json }: { json: string }) => {
  if (!json) {
    return <React.Fragment />
  }

  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;') // eslint-disable-line no-param-reassign
  const highlighted = json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function addTypeClass(match) {
      let cls = 'number'
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key'
        } else {
          cls = 'string'
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean'
      } else if (/null/.test(match)) {
        cls = 'null'
      }
      return `<span class="${cls}">${match}</span>`
    }
  )

  return (<pre dangerouslySetInnerHTML={{ __html: highlighted }} />) // eslint-disable-line react/no-danger
}

type CodeSnippetProps = {
  header: ReactNode | string,
  code: string,
}

export const CodeSnippet = ({ header, code }: CodeSnippetProps): JSX.Element => {
  return (
    <div className="code-snippet">
      <div className="code-snippet-header">{header}</div>
      <div>
        <JsonSnippet json={code} />
      </div>
    </div>
  )
}
