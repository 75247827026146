import React from 'react'
import {
  ResourceType,
} from '@logic/platform-core'
import { ResourceModel } from './ResourceModel'
import { CancelableLink } from '@logic/platform-fabric'

type PathIconPair = {
  path?: string | undefined,
  icon?: React.ElementType<any>,
}

export const useResourceTypes = (resources: ResourceType[]): Map<string, PathIconPair> => {
  return React.useMemo(() => {
    return new Map(resources.map((resource) => [
      resource.name,
      {
        path: resource.path,
        icon: resource.icon,
      }]))
  }, [resources])
}

type ResourceIconProps = {
  resource: ResourceModel,
  resourceTypes: Map<string, PathIconPair>,
}

export const ResourceIcon = ({ resource, resourceTypes }: ResourceIconProps): JSX.Element => {
  const Icon = resourceTypes.get(resource.resourceType)?.icon
  return Icon ? <Icon /> : <React.Fragment />
}

const createPath = (path: string | undefined, subscriptionId: string, resourceId: string) => {
  return path && path.replace(':subscriptionId', subscriptionId).replace(':resourceId', resourceId)
}

type ResourceLinkProps = {
  resource: ResourceModel,
  resourceTypes: Map<string, PathIconPair>,
}

export const ResourceLink = ({ resource, resourceTypes }: ResourceLinkProps): JSX.Element => {
  const resourceType = resourceTypes.get(resource.resourceType)
  if (resourceType) {
    return (
      <CancelableLink to={createPath(resourceType.path, resource.subscriptionId, resource.id)}>
        {resource.name}
      </CancelableLink>
    )
  }
  return <React.Fragment>{resource.name}</React.Fragment>
}
