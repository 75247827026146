import React from 'react'
import {
  Page,
  LinkButton,
  FormSkeleton,
} from '@logic/platform-fabric'
import { useParams } from 'react-router-dom'
import './ResourceSummary.scss'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import {
  useContextSubscriptionId,
  useServiceCatalog,
} from '@logic/platform-core'
import { useClientCredentials } from '../client-credentials/useClientCredentials'
import { useProduct } from './useProduct'
import { useApiKey } from './api-keys/ApiKeyPage'
import {
  GetStartedMarkDownDocument, ResourceApikeys, ResourceClientCredentials, ResourceDetails,
} from './ResourceDetails'
import { ClientCredentials } from '../client-credentials/ClientCrendetialsClient'

export const ResourceSummary = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { value: defaultCC, refresh } = useClientCredentials(subscriptionId)
  const { key, apiKeyId } = useParams<{ key:string, apiKeyId:string }>()
  const { value: productInfo, completed } = useProduct(key)
  const { value: apiKeys } = useApiKey(apiKeyId)
  const { services } = useServiceCatalog()
  const service = services.filter((s) => s.name === productInfo?.key)[0]

  let selectedCC: ClientCredentials[] | undefined
  (apiKeys?.clientCredentialId)
    && (selectedCC = defaultCC?.filter((ccId) => ccId.clientId === apiKeys.clientCredentialId))
  const selectedCCValues = selectedCC?.[0]
  const defaultCCValues = defaultCC?.[0]

  return (
    <Page loading={!completed} skeleton={FormSkeleton} className="page-size">
      <div>
        <CheckCircleOutlineIcon />
        <h2>Success!</h2>
      </div>
      <GetStartedMarkDownDocument productInfo={productInfo} />
      <ResourceDetails apiKeyId={apiKeyId} keyValue={key} apiKeyName={apiKeys?.name} subscriptionId={subscriptionId} />
      <ResourceClientCredentials
        selectedCCValues={selectedCCValues}
        defaultCCValues={defaultCCValues}
        productInfo={productInfo}
        service={service}
        refresh={refresh}
      />
      {apiKeys && <ResourceApikeys />}
      <React.Fragment>
        <LinkButton to={`/subscriptions/${subscriptionId}/resources`} variant="primary">Go to resource overview</LinkButton>
        <LinkButton to="/marketplace">Create another resource</LinkButton>
      </React.Fragment>
    </Page>
  )
}
