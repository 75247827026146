import React from 'react'
import './OpenApiDescription.scss'
import { Playground } from './Playground'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const getResponses = (operation: any) => {
  return Object.keys(operation.responses).map((statusCode) => {
    const response = operation.responses[statusCode]
    return {
      statusCode,
      description: response.description,
      schema: undefined,
      content: response.content,
    }
  })
}

const sampleValue = (schema: any, schemas: any, path: string[]): any => {
  switch (schema.type) {
  case 'string': {
    if (schema.example) {
      return schema.example
    }
    if (schema.enum) {
      return schema.enum[0]
    }
    switch (schema.format) {
    case 'uuid': return 'ff4caaab-ede9-47cb-9185-d98e3f507240'
    case 'date': return '2017-07-21'
    case 'date-time': return '2017-07-21T17:32:28Z'
    case 'byte': return 'U3dhZ2dlciByb2Nrcw=='
    default: return 'string'
    }
  }
  case 'object': {
    const properties = schema.properties
    if (properties) {
      return Object.keys(properties).reduce((obj: any, property: any) => ({
        ...obj,
        [property]: sampleJson(properties[property], schemas, path),
      }), {})
    }
    return undefined
  }
  case 'integer':
  case 'number':
    return 0
  case 'boolean': return false
  case 'array': return new Array(2).fill(sampleJson(schema.items, schemas, path))
  default: return undefined
  }
}

export const sampleJson = (schema: any, schemas: any, path: string[] = []) => {
  if (!schema) return undefined
  if (schema.$ref) {
    const shortRef = schema.$ref.replace('#/components/schemas/', '').replace('#/definitions/', '')
    if (path.indexOf(shortRef) !== -1) {
      return undefined
    }
    return sampleValue(schemas[shortRef], schemas, [shortRef, ...path])
  }
  if (schema.allOf) {
    const values = schema.allOf.map((item: any) => sampleJson(item, schemas, path))
    return Object.assign({}, ...values)
  }
  if (schema.type) {
    return sampleValue(schema, schemas, path)
  }
}

export type Operation = {
  name: string,
  summary: string,
  method: string,
  path: string,
  parameters?: Parameter[],
  requestContent: any,
  responses: any[],
  security?: any,
}

export type Parameter = any & {
  name: string,
  in: 'query' | 'header' | 'path' | 'cookie',
}

type OperationsByTag = {
  [tag: string]: Operation[],
}

const addApiKeyIfNecessary = (operation: Operation, apiKeyRequired: boolean) => {
  if (!apiKeyRequired) {
    return operation.parameters
  } else {
    return [...(operation.parameters || []), {
      name: 'Logic-Api-Key',
      in: 'header',
    }]
  }
}

const mapApi = (api: any, apiKeyRequired: boolean): OperationsByTag => {
  const operations = Object.keys(api.paths).map((path) => {
    const methods = api.paths[path]
    return Object.keys(methods).map((method) => {
      const operation = methods[method]
      const tag = operation.tags?.[0] || 'default'
      return [tag, {
        name: operation.operationId,
        summary: operation.summary,
        method,
        path,
        parameters: addApiKeyIfNecessary(operation, apiKeyRequired),
        requestContent: operation?.requestBody?.content,
        responses: getResponses(operation),
        security: operation.security?.map((security: any) => Object.keys(security)[0]),
      } as Operation]
    })
  })
  return operations.reduce((prev, value) => [...prev, ...value], []).reduce((acc, [tag, operation]) => {
    acc[tag] = [...(acc[tag] || []), operation]
    return acc
  }, {} as any)
}

const getProperties = (schema: any, schemas: any) => {
  if (!schema) {
    return undefined
  }

  if (schema.$ref) {
    const shortRef = schema.$ref.replace('#/components/schemas/', '').replace('#/definitions/', '')
    return schemas[shortRef].properties
  }

  if (schema.allOf) {
    const allOfProperties = schema.allOf.map((item: any) => getProperties(item, schemas))
    return Object.assign({}, ...allOfProperties)
  }

  if (schema.properties) {
    return schema.properties
  }
}

const getPropertyType = (property: any, schemas: any) => {
  if (property.type) {
    return property.type
  }

  if (property.$ref) {
    const shortRef = property.$ref.replace('#/components/schemas/', '').replace('#/definitions/', '')
    return schemas[shortRef].type
  }

  if (property.allOf) {
    return 'object'
  }
}

const ChildDescription = ({ property, schemas }: any) => {
  const [expanded, setExpanded] = React.useState(false)
  if (!expanded) {
    return (<button type="button" onClick={() => setExpanded(true)}><KeyboardArrowDownIcon />Show child attributes</button>)
  } else {
    return (
      <div className="child-properties">
        <button type="button" onClick={() => setExpanded(false)}><KeyboardArrowUpIcon />Hide child attributes</button>
        <SchemaDescription schema={property} schemas={schemas} />
      </div>
    )
  }
}

const EnumValues = ({ schema, schemas }: any) => {
  const shortRef = schema.$ref.replace('#/components/schemas/', '').replace('#/definitions/', '')
  const values = schemas[shortRef].enum
  return (
    <ul>
      {values.map((value: string) => <li>{value}</li>)}
    </ul>
  )
}

const EnumDescription = ({ property, schemas }: any) => {
  const [expanded, setExpanded] = React.useState(false)
  if (!expanded) {
    return (<button type="button" onClick={() => setExpanded(true)}><KeyboardArrowDownIcon />Show enum values</button>)
  } else {
    return (
      <div className="child-properties">
        <button type="button" onClick={() => setExpanded(false)}><KeyboardArrowUpIcon />Hide enum values</button>
        <EnumValues schema={property} schemas={schemas} />
      </div>
    )
  }
}

const PropertyDescription = ({ name, property, schemas }: any) => {
  const type = getPropertyType(property, schemas)
  const knownAttributes = ['maximum', 'minimum', 'minLength', 'maxLength', 'pattern', 'format']
  const attributesToDisplay = Object.entries(property).filter(([attribute]) => attribute.indexOf('x-') === 0 || knownAttributes.indexOf(attribute) !== -1)
  const [attributesVisible, setAttributesVisible] = React.useState(false)
  return (
    <li>
      <div>
        <pre className="name">{name}</pre>
        <pre className="type">{type}</pre>
        {attributesToDisplay.length > 0 && (
          <span style={{ verticalAlign: 'middle', cursor: 'pointer' }} onClick={() => setAttributesVisible(!attributesVisible)}>{attributesVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
        )}
      </div>
      {attributesToDisplay.length > 0 && attributesVisible && (
        <div className="attributes">
          {attributesToDisplay.map(([attribute, value]) => (
            <div key={attribute}>{attribute}: {value}</div>
          ))}
        </div>
      )}
      <div>{property.description}</div>
      {type === 'object' && (
        (property.$ref && <ChildDescription property={property} schemas={schemas} />)
        || (property.allOf && <ChildDescription property={property} schemas={schemas} />)
      )}
      {type === 'array' && property.items && (
        <ChildDescription property={property.items} schemas={schemas} />
      )}
      {type === 'string' && property.$ref && <EnumDescription property={property} schemas={schemas} />}
    </li>
  )
}

const PropertiesDescription = ({ properties, schemas }: any) => {
  return (
    <ul>
      {Object.keys(properties).map((name) => <PropertyDescription key={name} name={name} property={properties[name]} schemas={schemas} />)}
    </ul>
  )
}

const SchemaDescription = ({ schema, schemas }: any) => {
  const properties = getProperties(schema, schemas)
  if (properties) {
    return (<PropertiesDescription properties={properties} schemas={schemas} />)
  }

  if (schema.type === 'array') {
    const properties2 = getProperties(schema.items, schemas)
    if (properties2) {
      return (<PropertiesDescription properties={properties2} schemas={schemas} />)
    }
  }

  return <React.Fragment />
}

const ContentDescription = ({ content, schemas }: any) => {
  const schema = content['application/json'] || content['multipart/form-data']
  if (!schema) {
    return <React.Fragment />
  }

  return <SchemaDescription schema={schema.schema} schemas={schemas} />
}

const RequestDescription = ({ operation, schemas }: any) => (
  <div className="operation-block">
    <div className="description">
      {operation.parameters && (
        <React.Fragment>
          <h4>Parameters</h4>
          <ul>
            {operation.parameters.map((parameter: any) => (
              <li>
                <div><pre className="name">{parameter.name}</pre><pre className="type">{parameter.schema?.type}</pre></div>
                <div>{parameter.description}</div>
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
      {operation.requestContent && (
        <React.Fragment>
          <h4>Body</h4>
          <ContentDescription content={operation.requestContent} schemas={schemas} />
        </React.Fragment>
      )}
    </div>
  </div>
)

const ResponseDescription = ({ response, schemas }: any) => {
  return (
    <div className="operation-block">
      <div className="description">
        <h5>{response.statusCode}</h5>
        <p>{response.description}</p>
        {response.content && <ContentDescription content={response.content} schemas={schemas} />}
      </div>
    </div>
  )
}

const normalizeResponse = (response: any) => {
  return {
    description: response.description,
    content: response.schema && {
      'application/json': {
        schema: response.schema,
      },
    },
  }
}

const normalizePaths = (paths: any) => {
  return Object.fromEntries(Object.keys(paths).map((path) => {
    const methods = paths[path]
    return [path, Object.fromEntries(Object.keys(methods).map((method) => {
      const { parameters, consumes, ...operation } = methods[method]
      const requestBody = parameters?.filter((p: any) => p.in === 'body')[0]
      return [method, {
        ...operation,
        parameters: parameters?.filter((p: any) => p.in !== 'body'),
        requestBody: requestBody && consumes && {
          content: Object.fromEntries(consumes.map((c: any) => [c, { schema: requestBody.schema }])),
        },
        responses: Object.fromEntries(
          Object
            .keys(operation.responses)
            .map((statusCode) => [statusCode, normalizeResponse(operation.responses[statusCode])])
        ),
      }]
    }))]
  }))
}

const normalizeApi = (api: any) => {
  if (api.swagger === '2.0') {
    const { definitions, host, basePath, schemes, paths, securityDefinitions, security, ...apiRest } = api
    return {
      ...apiRest,
      servers: schemes.map((scheme: any) => ({ url: `${scheme}://${host}${basePath}` })),
      paths: normalizePaths(paths),
      components: {
        schemas: definitions,
        securitySchemes: securityDefinitions,
      },
      security: security?.map((s: any) => Object.keys(s)[0]),
    }
  } else {
    const { security, ...restApi } = api
    return {
      ...restApi,
      security: security?.map((s: any) => Object.keys(s)[0]),
    }
  }
}

type OperationDescriptionProps = {
  operation: Operation,
  id: any,
  api: any,
}

const OperationDescription = ({ operation, id, api }: OperationDescriptionProps) => {
  return (
    <div className="operation" key={operation.name} id={`${id}-${operation.name}`}>
      <div className="operation-description">
        <h3>{operation.name}</h3>
        <div className="operation-block">
          <div className="description">
            {operation.summary}
          </div>
        </div>
        <RequestDescription operation={operation} schemas={api.components.schemas} />
        <h4>Responses</h4>
        {operation.responses.map((response: any) => (
          <ResponseDescription key={response.statusCode} response={response} schemas={api.components.schemas} />
        ))}
      </div>
      <div className="operation-example">
        <Playground
          operation={operation}
          securitySchemes={api.components.securitySchemes}
          security={api.security}
          servers={api.servers}
          schemas={api.components.schemas}
        />
      </div>
    </div>
  )
}

export const OpenApiOperationDescription = ({ api, operationId, tag, apiKeyRequired }: any) => {
  api = normalizeApi(api) // eslint-disable-line no-param-reassign
  const apiDesc = mapApi(api, apiKeyRequired)
  const operation = apiDesc[tag].find((o) => o.name === operationId)
  return (operation ? <OperationDescription operation={operation} api={api} id={1} /> : <React.Fragment />)
}

export const OpenApiDescription = ({ id, api, apiKeyRequired }: any): JSX.Element => {
  api = normalizeApi(api) // eslint-disable-line no-param-reassign
  const apiDesc = mapApi(api, apiKeyRequired)
  return (
    <React.Fragment>
      {Object.keys(apiDesc).map((tag) => {
        const tagOperations = apiDesc[tag]
        return (
          <div id={`${id}-${tag}-operations`}>
            {
              tagOperations.map((operation: any) => (
                <OperationDescription key={operation.name} operation={operation} id={id} api={api} />
              ))
            }
          </div>
        )
      })}
    </React.Fragment>
  )
}
