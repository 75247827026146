import React from 'react'
import { ServiceDescription } from './ServiceDescription'

export type ServiceCatalogContextProps = {
  services: ServiceDescription[],
}

export const ServiceCatalogContext = React.createContext<ServiceCatalogContextProps | undefined>(undefined)

export type ServiceCatalogProviderProps = {
  services: ServiceDescription[],
}

export const ServiceCatalogProvider: React.FC<ServiceCatalogProviderProps> = ({
  children,
  services,
}) => {

  const context = React.useMemo(() => ({
    services,
  }), [services])

  return (
    <ServiceCatalogContext.Provider value={context}>{children}</ServiceCatalogContext.Provider>
  )
}

export const useServiceCatalog = (): ServiceCatalogContextProps => {
  const context = React.useContext<ServiceCatalogContextProps | undefined>(ServiceCatalogContext)
  if (!context) {
    throw Error('Cannot read service catalog out of ServiceCatalogContext scope')
  }

  return context
}
