import {
  useContextSubscriptionId, useTitle,
} from '@logic/platform-core'
import {
  Accordion,
  AccordionItem,
  Page,
  PageHeader,
} from '@logic/platform-fabric'
import React from 'react'
import { AddClientCredentialsButton } from './CreateClientCredentialsDialog'
import { useClientCredentials } from './useClientCredentials'
import './ClientCredentialsPage.scss'
import { ClientCredentialsWidget } from './ClientCredentialsWidget'

export const ClientCredentialsPage = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { value: clientCredentials, completed, refresh } = useClientCredentials(subscriptionId)

  useTitle(() => 'Client credentials', [])
  return (
    <Page>
      <PageHeader heading="Client credentials" subheading="Yup, that's exactly it">
        <AddClientCredentialsButton onClose={refresh} />
      </PageHeader>
      <Accordion role="list" loading={!completed}>
        {(clientCredentials || []).map((cc) => (
          <AccordionItem key={cc.clientId} name={cc.clientId} heading={cc.resourceDescription} subheading="OAuth2 Client Credentials">
            <ClientCredentialsWidget
              clientId={cc.clientId}
              clientSecret={cc.clientSecret}
              resourceScope={cc.resourceScope}
              applicationIds={cc.applicationIds || []}
              tokenEndpoint={cc.tokenEndpoint}
              resourceDescription={cc.resourceDescription}
              refresh={refresh}
              showActionButtons={true}
            />
          </AccordionItem>
        ))}
      </Accordion>
    </Page>
  )
}
