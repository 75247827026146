import { useContextSubscriptionId, useNotifications } from '@logic/platform-core'
import {
  Button,
  Dialog,
  Field,
  Form,
  useSimpleForm,
} from '@logic/platform-fabric'
import { useOverlayTriggerState } from '@react-stately/overlays'
import React from 'react'
import { useClientCredentialsClient } from './ClientCrendetialsClient'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'

const useCreateClientCredentialsDialogForm = (onClose: any) => {
  const subscriptionId = useContextSubscriptionId()
  const { addInfo } = useNotifications()
  const history = useHistory()
  const client = useClientCredentialsClient()
  const createClientCredentials = React.useCallback(async ({ displayName }) => {
    await client.postClientCredentials(subscriptionId, displayName)
    onClose()
    addInfo('Client credentials created')
    history.push(`/subscriptions/${subscriptionId}/client-credentials`)
  }, [client, subscriptionId, onClose, addInfo, history])
  return useSimpleForm({ displayName: '' }, undefined, createClientCredentials)
}

type CreateClientCredentialsDialogProps = {
  isOpen: boolean,
  close: () => void,
}

export const CreateClientCredentialsDialog = ({ isOpen, close }: CreateClientCredentialsDialogProps): JSX.Element => {
  const form = useCreateClientCredentialsDialogForm(close)
  return (

    <Dialog isOpen={isOpen} title="Create client credentials">
      <p>
        Create client credentials to access Logic API. If you want to use any other API from marketplace,
        please contact provider directly and ask for client credentials for your subscription.
      </p>
      <Form {...form}>
        <Field name="displayName" label="Display name" validators={['required']} />
        <div style={{ marginTop: 40 }}>
          <Button type="submit" variant="primary">Create</Button>
          <Button type="button" onPress={() => close()}>Cancel</Button>
        </div>
      </Form>
    </Dialog>
  )
}

export const AddClientCredentialsButton = ({ onClose }: { onClose: () => void }): JSX.Element => {
  const state = useOverlayTriggerState({})
  const close = React.useCallback(() => {
    state.close()
    onClose()
  }, [state, onClose])
  return (
    <React.Fragment>
      <Button type="button" variant="primary" icon={AddIcon} onPress={() => state.open()}>Add new</Button>
      <CreateClientCredentialsDialog isOpen={state.isOpen} close={close} />
    </React.Fragment>
  )
}
