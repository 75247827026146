import React from 'react'
import { ErrorMessage, useField } from 'formik'
import { useDropzone } from 'react-dropzone'
import { BaseFieldControlProps } from './BaseFieldControlProps'
import WallpaperIcon from '@material-ui/icons/Wallpaper'
import { fileSize } from './FileFieldControl'

export type ImageFieldProps = {
  type: 'image',
  accept?: string,
}

export type ImageFieldControlProps = ImageFieldProps & BaseFieldControlProps

export const ImageFieldControl = ({ name, validate, accept, type, ...props }: ImageFieldControlProps): JSX.Element => {
  const [, { value }, { setValue, setTouched }] = useField({ name, validate })
  const [preview, setPreview] = React.useState<string | undefined>()
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: any) => {
      setValue(files[0])
      setTouched(true)
    },
    maxFiles: 1,
    accept,
  })

  React.useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        setPreview(value)
      } else {
        setPreview(URL.createObjectURL(value))
      }
    }
  }, [value])

  React.useEffect(() => () => {
    if (preview) {
      if (typeof value !== 'string') {
        URL.revokeObjectURL(preview)
      }
    }
  }, [preview, value])

  return (
    <div className="image-field">
      <div {...getRootProps()} className="field-drop-zone image">
        <input type="file" {...getInputProps()} {...props} />
        {preview
          ? <img src={preview} alt={value?.name || ''} />
          : (
            <div>
              <div><WallpaperIcon /></div>
              <div>Upload image</div>
            </div>
          )}
      </div>
      {value && (
        <ul className="field-files">
          <li key={value.name}>
            <div><span className="name">{value.name}</span>{value.size ? <span className="size">({fileSize(value.size)})</span> : undefined}</div>
            <div className="message"><ErrorMessage name={name} /></div>
          </li>
        </ul>
      )}
    </div>
  )
}
