import { useParams } from 'react-router-dom'

export const useContextSubscriptionId = (): string => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>()
  if (!subscriptionId) {
    throw new Error('Cannot use `useContextSubscription` out of the context')
  }

  return subscriptionId
}
