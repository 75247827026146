import React from 'react'
import { Api, ApiVersion } from '../../MarketplaceClient'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import './ApiVersionSelector.scss'
import {
  useMenuTrigger,
} from '@react-aria/menu'
import { useMenuTriggerState } from '@react-stately/menu'
import { useButton } from '@react-aria/button'
import { DismissButton, useOverlay } from '@react-aria/overlays'
import { FocusScope } from '@react-aria/focus'
import { Skeleton } from '@logic/platform-fabric'

export type ApiVersionSelection = {
  api: Api,
  version: ApiVersion,
}

type ApiSelectorProps = {
  productApis: Api[],
  version: ApiVersionSelection | undefined,
  onVersionSelected: (choice: ApiVersionSelection) => void,
  loading?: boolean,
}

const ApiSelectorPopup = (props: any) => {
  const data: Api[] = props.data
  const [selectedApi, setApi] = React.useState<Api>()

  // Handle events that should cause the menu to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  const overlayRef = React.useRef<HTMLDivElement | null>(null)
  const { overlayProps } = useOverlay(
    {
      onClose: props.onClose,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  )

  const onAction = React.useCallback((api, version) => {
    props.onAction(api, version)
    props.onClose()
  }, [props])

  const onApiSelected = React.useCallback((api) => {
    if (api.versions.length > 1) {
      setApi(api)
    } else {
      onAction(api, api.versions[0])
    }
  }, [onAction])

  return (
    <FocusScope restoreFocus={true}>
      <div {...overlayProps} ref={overlayRef}>
        <DismissButton onDismiss={props.onClose} />
        <div
          className="popup-menu api-version-popup"
          style={{
            position: 'absolute',
            left: 0,
            margin: '-10px 0 0 0',
          }}
        >
          <ul className="api-list">
            {data.map((api) => (
              <li key={api.name} onClick={() => onApiSelected(api)}>
                <div>{api.name}</div>
              </li>
            ))}
          </ul>
          {selectedApi && (
            <ul className="version-list">
              {selectedApi.versions.map((version) => (
                <li onClick={() => onAction(selectedApi, version)}>
                  <div>{version.number}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  )
}

export const ApiSelector = ({ productApis, onVersionSelected, version, loading }: ApiSelectorProps): JSX.Element => {
  const state = useMenuTriggerState({})
  const ref = React.useRef<HTMLElement | null>(null)
  const { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref)
  const { buttonProps } = useButton(menuTriggerProps, ref)

  const setVersion = React.useCallback((api: Api, v: ApiVersion | undefined) => {
    const ver = v || api.versions[0]
    onVersionSelected({
      api,
      version: ver,
    })
  }, [onVersionSelected])
  React.useEffect(() => {
    productApis.length > 0 && setVersion(productApis[0], undefined)
  }, [setVersion, productApis])

  if (loading) {
    return (
      <div className="api-version-selector">
        <h4>
          <Skeleton />
        </h4>
      </div>
    )
  }

  return (
    <div className="api-version-selector">
      <div style={{ position: 'relative' }}>
        <h4>
          <button {...buttonProps} className="api-version-header" type="button">
            <div>{version?.api.name} {version?.version.number}</div>
            {state.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </button>
        </h4>
        {state.isOpen && (
          <ApiSelectorPopup
            domProps={menuProps}
            autoFocus={state.focusStrategy}
            onClose={state.close}
            data={productApis}
            onAction={setVersion}
          />
        )}
      </div>
    </div>
  )
}
