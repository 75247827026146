import {
  useConfig,
  objectToFormData,
  normalizeBadRequestResponse,
} from '@logic/platform-core'
import { AxiosInstance } from 'axios'
import { useAxios } from '../infrastructure/useAxios'

export type Provider = {
  id: string,
  name: string,
  logo: string | undefined,
  description: string,
  status:string,
}

export type CreateEditProviderRequest = {
  name: string,
  logo: File | undefined,
  description: string,
  markdownDescription: File | undefined,
}

const onError = (reason: any) => {
  if (reason.response.status === 400) {
    return Promise.reject(normalizeBadRequestResponse(reason.response.data))
  } else {
    return Promise.reject(reason)
  }
}

const getSubscriptionProvider = (client: AxiosInstance) => (subscriptionId: string) => {
  return client.get(`/subscriptions/${subscriptionId}/providers`).then<Provider | undefined>((response) => response.data.map((data: any) => ({
    id: data.id,
    subscriptionId: data.subscriptionId,
    name: data.providerName,
    logo: data.logo,
    description: data.apiMarketplaceDescription,
    status: data.status,
  }))[0])
}

const postSubscriptionProvider = (client: AxiosInstance) => (subscriptionId: string, request: CreateEditProviderRequest) => {
  const form = objectToFormData({
    providerName: request.name,
    logo: request.logo,
    apiMarketplaceDescription: request.description,
    apiMarketplaceDocumentation: request.markdownDescription,
  })
  return client.post(`/subscriptions/${subscriptionId}/providers`, form).catch(onError)
}

const putSubscriptionProvider = (client: AxiosInstance) => (subscriptionId: string, id: string, request: CreateEditProviderRequest) => {
  const form = objectToFormData({
    providerName: request.name,
    logo: request.logo,
    apiMarketplaceDescription: request.description,
    apiMarketplaceDocumentation: request.markdownDescription,
  })
  return client.put(`/subscriptions/${subscriptionId}/providers/${id}`, form).catch(onError)
}

export type ProviderClient = {
  getSubscriptionProvider: (subscriptionId: string) => Promise<Provider | undefined>,
  postSubscriptionProvider: (subscriptionId: string, request: CreateEditProviderRequest) => Promise<any>,
  putSubscriptionProvider: (subscriptionId: string, id: string, request: CreateEditProviderRequest) => Promise<any>,
}

const createProviderClient = (client: AxiosInstance) => ({
  getSubscriptionProvider: getSubscriptionProvider(client),
  postSubscriptionProvider: postSubscriptionProvider(client),
  putSubscriptionProvider: putSubscriptionProvider(client),
})

export const useProviderClient = (): ProviderClient => {
  const { labPlatformApiUrl } = useConfig()
  return useAxios(labPlatformApiUrl, createProviderClient)
}
