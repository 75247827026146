import {
  Config,
  LoggingProvider,
  useConfig,
} from '@logic/platform-core'
import React from 'react'
import * as structuredLog from 'structured-log'
import SeqSink from 'structured-log-seq-sink'
import { useAppInsights } from '../tracking/TelemetryProvider'
import AppInsightsSink from './AppInsightsSink'

type SeqConfig = Config & {
  seqUrl: string,
  seqAPIKey: string,
}

export const ShellLoggingProvider: React.FC = ({ children }) => {
  const { seqUrl, seqAPIKey } = useConfig<SeqConfig>()
  const appInsights = useAppInsights()
  const logger = React.useMemo(() => {
    const sink = new structuredLog.ConsoleSink()
    return structuredLog.configure()
      .minLevel(structuredLog.LogEventLevel.information)
      .writeTo(sink)
      .writeTo(new AppInsightsSink(appInsights))
      .writeTo(new SeqSink({ url: seqUrl, apiKey: seqAPIKey }))
      .create()
  }, [seqUrl, seqAPIKey, appInsights])

  return <LoggingProvider logger={logger}>{children}</LoggingProvider>
}
