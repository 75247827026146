import React from 'react'
import NotFoundImage from './404.svg'
import { Page, LinkButton } from '@logic/platform-fabric'
import './ErrorPage.scss'

export const NotFoundPage = (): JSX.Element => {
  return (
    <Page>
      <div className="error-page">
        <img src={NotFoundImage} alt="404" />
        <h1>Oops! 404</h1>
        <p>We can't seem to find the page you're looking for.</p>
        <div className="action-buttons">
          <LinkButton to="/" variant="primary">Take me from here!</LinkButton>
        </div>
      </div>
    </Page>
  )
}
