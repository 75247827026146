import React from 'react'
import {
  Page,
  Form,
  EditablePageHeader,
  Section,
  Field,
  useSimpleForm,
  Button,
  LinkButton,
  FormSkeleton,
} from '@logic/platform-fabric'
import { useProviderClient } from './ProviderClient'
import { useContextSubscriptionId, useNotifications } from '@logic/platform-core'
import { useHistory } from 'react-router-dom'

const useProviderForm = () => {
  const subscriptionId = useContextSubscriptionId()
  const client = useProviderClient()
  const history = useHistory()
  const { addInfo } = useNotifications()
  const initialValues = React.useMemo(() => ({
    id: '',
    name: '',
    logoUrl: undefined,
  }), [])
  const loadProvider = React.useCallback(() => {
    return client.getSubscriptionProvider(subscriptionId).then((data) => data || initialValues)
  }, [client, subscriptionId, initialValues])
  const saveProvider = (values: any) => {
    if (values.id) {
      return client.putSubscriptionProvider(subscriptionId, values.id, values).then(() => {
        addInfo('Provider has been updated')
        history.push(`/subscriptions/${subscriptionId}/provider/products`)
      })
    } else {
      return client.postSubscriptionProvider(subscriptionId, values).then(() => {
        addInfo('Provider has been created')
        history.push(`/subscriptions/${subscriptionId}/provider/products`)
      })
    }
  }
  return useSimpleForm(initialValues, loadProvider, saveProvider)
}

export const CreateEditProviderPage = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { loading, ...form } = useProviderForm()
  return (
    <Page loading={loading} skeleton={FormSkeleton}>
      <Form {...form}>
        <EditablePageHeader name="name" label="Name" subheading="Provider configuration" validators={['required']} />
        <Section>
          <Field name="logo" label="Logo" type="image" accept="image/*" />
          <Field name="description" label="Description" type="multiline" />
          <Field name="markdownDescription" label="Markdown description" type="file" accept=".md" />
        </Section>
        <div>
          <Button type="submit">Save</Button>
          <LinkButton to={`/subscriptions/${subscriptionId}/resources`}>Cancel</LinkButton>
        </div>
      </Form>
    </Page>
  )
}
