import { useContextSubscriptionId } from '@logic/platform-core'
import {
  Button,
  Dialog,
  Field,
  Form,
  useSimpleForm,
} from '@logic/platform-fabric'
import React from 'react'
import { SubscriptionUser, useSubscriptionUserClient } from './SubscriptionUserClient'

const useEditSubscriptionUserForm = (user: SubscriptionUser, onClose: any) => {
  const subscriptionId = useContextSubscriptionId()
  const client = useSubscriptionUserClient()
  const saveUser = React.useCallback((values) => {
    return client.updateSubscriptionUserRole(subscriptionId, user.id, values.role).then(() => onClose())
  }, [client, onClose, user, subscriptionId])
  return useSimpleForm(user, undefined, saveUser)
}

type EditSubscriptionUserDialogProps = {
  user: SubscriptionUser,
  isOpen: boolean,
  onClose: () => void,
}

export const EditSubscriptionUserDialog = ({ user, isOpen, onClose }: EditSubscriptionUserDialogProps): JSX.Element => {
  const form = useEditSubscriptionUserForm(user, onClose)
  const roles = React.useMemo(() => {
    return ['Owner', 'Contributor'].map((role) => ({ value: role, text: role }))
  }, [])
  return (
    <Dialog isOpen={isOpen} title="Edit user role">
      <Form {...form}>
        <Field type="dropdown" name="role" label="User role" options={roles} />
        <div style={{ marginTop: 40 }}>
          <Button type="submit" variant="primary">Change</Button>
          <Button type="button" onPress={() => onClose()}>Cancel</Button>
        </div>
      </Form>
    </Dialog>
  )
}
