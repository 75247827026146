import React from 'react'

type SubscriptionDetails = {
  isProduction: boolean | undefined,
  subscriptionName: string | undefined,
}

export const SubscriptionHeading = (props: SubscriptionDetails): JSX.Element => {

  return (
    <React.Fragment>
      {props.subscriptionName}
      {props.isProduction
      && (
        <span className="chip">
          Production
        </span>
      )}
    </React.Fragment>
  )
}
