import React from 'react'

export const ProductTermsTab = (props: { productTerms: string | undefined }): any => {

  return (
    <React.Fragment>
      {props.productTerms && props.productTerms}
    </React.Fragment>
  )
}
