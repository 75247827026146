import React from 'react'
import { Dialog, Button } from '@logic/platform-fabric'

type QuestionDialogProps = {
  message: string,
  title: string,
  isOpen: boolean,
  onClose: (result: QuestionDialogResult) => Promise<any>,
}

export const QuestionDialog = ({ message, title, isOpen, onClose }: QuestionDialogProps): JSX.Element => {
  return (
    <Dialog isOpen={isOpen} title={title}>
      <p>{message}</p>
      <div>
        <Button variant="primary" onPress={() => onClose({ answer: 'yes' })}>Yes</Button>
        <Button onPress={() => onClose({ answer: 'no' })}>No</Button>
      </div>
    </Dialog>
  )
}

const QuestionDialogController = ({ askQuestionRef, component: Component }: any) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [dialogTitle, setDialogTitle] = React.useState('')
  const [dialogMessage, setDialogMessage] = React.useState('')
  const resolveRef = React.useRef<((result: QuestionDialogResult) => void) | undefined>(undefined)
  const callbackRef = React.useRef<QuestionDialogCallback | undefined>(undefined)

  const onClose = (result: QuestionDialogResult) => {
    if (callbackRef.current) {
      const callbackResult = callbackRef.current(result)
      if (callbackResult) {
        return callbackResult.then(() => {
          resolveRef.current && resolveRef.current(result)
        })
      }
    }

    resolveRef.current && resolveRef.current(result)
    return undefined
  }
  const askQuestion = React.useCallback((message: string, title: string, callback?: QuestionDialogCallback) => {
    setDialogMessage(message)
    setDialogTitle(title)
    setIsOpen(true)
    callbackRef.current = callback
    return new Promise((resolve) => {
      resolveRef.current = resolve
    }).then((result) => {
      setIsOpen(false)
      setDialogTitle('')
      setDialogMessage('')
      callbackRef.current = undefined
      return result
    })
  }, [setDialogMessage, setDialogTitle, setIsOpen])

  React.useEffect(() => {
    askQuestionRef.current = askQuestion
  }, [askQuestion, askQuestionRef])

  return (<Component isOpen={isOpen} title={dialogTitle} message={dialogMessage} onClose={onClose} />)
}

export type QuestionDialogResult = {
  answer: 'yes' | 'no',
}

type QuestionDialogCallback = (result : QuestionDialogResult) => Promise<any> | undefined

type AskQuestion = (message: string, title: string, callback?: QuestionDialogCallback) => Promise<QuestionDialogResult>

type QuestionDialogContextProps = {
  askQuestion: AskQuestion,
}

export const QuestionDialogContext = React.createContext<QuestionDialogContextProps | undefined>(undefined)

type QuestionDialogProviderProps = {
  component: React.ReactNode,
}

export const QuestionDialogProvider: React.FC<QuestionDialogProviderProps> = ({ component, children }) => {
  const askQuestionRef = React.useRef<AskQuestion | undefined>(undefined)
  const value = {
    askQuestion: (message: string, title: string, callback?: QuestionDialogCallback) => {
      if (askQuestionRef.current) {
        return askQuestionRef.current(message, title, callback)
      } else {
        return Promise.reject(Error('The reference to function `askQuestion` is not defined'))
      }
    },
  }
  return (
    <QuestionDialogContext.Provider value={value}>
      {children}
      <QuestionDialogController askQuestionRef={askQuestionRef} component={component} />
    </QuestionDialogContext.Provider>
  )
}

export const useQuestionDialog = (): QuestionDialogContextProps => {
  const context = React.useContext<QuestionDialogContextProps | undefined>(QuestionDialogContext)
  if (!context) {
    throw Error('You cannot use `useQuestionDialog` out of the QuestionDialogContext')
  }

  return context
}
