import { useServiceCatalog } from '@logic/platform-core'
import { useProduct } from './useProduct'

export const useProductLink = (key: string, apiKeyRequired: boolean): string => {
  const { services } = useServiceCatalog()
  const service = services.filter((s) => s.name === key)[0]
  if (service) {
    return `/${service.name}/${service.addProductLink}`
  } else if (apiKeyRequired) {
    return `/add-product?key=${key}`
  } else {
    return `/resources/${key}/getting-started`
  }
}

export const useProductLinkWithText = (key: string): { link: string, text: string } => {
  const { value: product } = useProduct(key)
  const { services } = useServiceCatalog()
  const service = services.filter((s) => s.name === key)[0]
  if (service) {
    return { link: `/${service.name}/${service.addProductLink}`, text: 'Create' }
  } else if (product?.apiKeyRequired) {
    return {
      link: `/add-product?key=${key}`,
      text: product?.providerApprovalRequired ? 'Request' : 'Create',
    }
  } else {
    return { link: `/resources/${key}/getting-started`, text: 'Get started' }
  }
}
