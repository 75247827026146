import { useContextSubscriptionId, useData } from '@logic/platform-core'
import {
  FormSkeleton,
  LinkButton,
  Page,
  PageHeader,
} from '@logic/platform-fabric'
import React from 'react'
import { useParams } from 'react-router-dom'
import '../../client-credentials/ClientCredentialsPage.scss'
import { useGatewayProduct } from '../../provider/gateway/GatewayProductPage'
import { useMarketplaceClient } from '../MarketplaceClient'
import { ApiKeys } from './ApiKeys'

export const useApiKey = (apiKeyId: string) => {
  const subscriptionId = useContextSubscriptionId()
  const client = useMarketplaceClient()
  return useData(undefined, () => client.getProductApiKey(subscriptionId, apiKeyId), [client, subscriptionId, apiKeyId])
}

export const ApiKeyPage = (): JSX.Element => {
  const { apiKeyId } = useParams<{ apiKeyId: string }>()
  const { value, completed } = useApiKey(apiKeyId)
  const subscriptionId = useContextSubscriptionId()
  const { value: productInfo } = useGatewayProduct(subscriptionId, value?.productId)
  return (
    <Page loading={!completed} skeleton={FormSkeleton}>
      <PageHeader heading={value?.name} subheading="API key" resourceId={apiKeyId}>
        <LinkButton to={`/subscriptions/${subscriptionId}/resources/${productInfo?.key}/${apiKeyId}/getting-started`} variant="primary">Get Started</LinkButton>
      </PageHeader>
      <ApiKeys isRefresh={true} />
    </Page>
  )
}
