import React from 'react'
import './HomePage.scss'
import TechHeartLogo from '../../layout/TechHeartLogo'
import { useProducts } from '../useProducts'
import { CancelableLink, Skeleton } from '@logic/platform-fabric'
import { Link } from 'react-router-dom'
import { SearchInput } from './SearchInput'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { useProductLink } from '../useProductLink'
import { ProductItem } from '../MarketplaceClient'
import {
  CreateSubscriptionDialog,
  useCreateSubscriptionDialogControl,
  useSubscriptionRequiredLink,
} from '../../subscriptions/CreateSubscriptionDialog'
import { useTitle } from '@logic/platform-core'

const arraySkeleton = <T extends any>(items: T[] | undefined, loading: boolean, count: number) => {
  return loading ? Array(count).fill({} as T).map((value, index) => ({ id: index, ...value })) : (items || [])
}

const ProductTile = ({ productKey, name, shortDescription, logoUrl, loading, subscriptionDialogControl, apiKeyRequired }: any) => {
  const addProductLink = useProductLink(productKey, apiKeyRequired)
  const linkProps = useSubscriptionRequiredLink(addProductLink, subscriptionDialogControl)
  return (
    <Link to={`/marketplace/${productKey}`} className="product-tile" role="listitem">
      {loading ? <Skeleton width={78} height={78} circle={true} /> : <img src={logoUrl || '/default-product-icon.png'} alt="" />}
      <div className="name" title={name}>{loading ? <Skeleton width={150} /> : name}</div>
      <div className="desc" title={shortDescription}>{loading ? <Skeleton count={2} /> : shortDescription}</div>
      {!loading && <CancelableLink className="add-product" {...linkProps} title="Create"><ControlPointIcon /></CancelableLink>}
    </Link>
  )
}

type ProductsProps = {
  loading: boolean,
  items: ProductItem[] | undefined,
}
const Products = ({ items, loading }: ProductsProps) => {
  const { dialogProps, dialogControl } = useCreateSubscriptionDialogControl()
  return (
    <div className="product-list" role="list" aria-busy={loading}>
      <CreateSubscriptionDialog {...dialogProps} />
      {arraySkeleton(items, loading, 8).map((product: any) => (
        <ProductTile
          key={product.id}
          productKey={product.key}
          {...product}
          loading={loading}
          apiKeyRequired={product.apiKeyRequired}
          subscriptionDialogControl={dialogControl}
        />
      ))}
    </div>
  )
}

export const HomePage = (): JSX.Element => {
  const [query, setQuery] = React.useState('')
  const { completed, value: products } = useProducts(query)
  useTitle(() => 'Marketplace', [])

  return (
    <div className="home-page">
      <div className="home-header">
        <h1>Welcome to Logic Marketplace</h1>
        <h2>You can search for products that will boost your solution's development</h2>
      </div>
      <div className="home-content">
        <div className="logo-wrapper">
          <TechHeartLogo />
        </div>
        <div className="search">
          <SearchInput value={query} onChange={setQuery} />
        </div>
        <Products items={products} loading={!completed} />
      </div>
    </div>
  )
}
