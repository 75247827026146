import React from 'react'
import {
  ActionMenu,
  FormSkeleton,
  Page,
  PageHeader,
  Tab,
  Tabs,
} from '@logic/platform-fabric'
import { ProductSettingsTab } from './ProductSettingsTab'
import { ProductConsumersTab } from './ProductConsumersTab'
import { useGatewayClient } from './GatewayClient'
import {
  Data, useContextSubscriptionId, useData, useNotifications,
} from '@logic/platform-core'
import { useHistory, useParams } from 'react-router-dom'
import { GatewayProductModel } from './GatewayProductModel'
import { useSubscriptionProvider } from '../useSubscriptionProvider'
import { useDeleteDialog } from '../../layout/DeleteDialog'
import { ProductVisibilityButton } from './ProductVisibilityButton'
import { NotAuthorizedMessage } from '../../layout/ErrorPage403'
import { useSubscriptionRole } from '../../subscriptions/useSubscriptionRole'
import { ApisTab } from './ApisTab'

export const useGatewayProduct = (subscriptionId: string, productId: string | undefined): Data<GatewayProductModel | undefined> => {
  const client = useGatewayClient()
  return useData(
    undefined,
    () => {
      if (productId) {
        return client.loadGatewayProduct(subscriptionId, productId)
      } else {
        return Promise.resolve(undefined)
      }
    },
    [client, subscriptionId, productId]
  )
}

export const GatewayProductPage = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const userRole = useSubscriptionRole(subscriptionId)
  const { productId } = useParams<{ productId: string }>()
  const { value: provider, completed: providerCompleted } = useSubscriptionProvider(subscriptionId)
  const { value, completed, refresh } = useGatewayProduct(subscriptionId, productId)
  const history = useHistory()
  const client = useGatewayClient()
  const { confirmDeletion } = useDeleteDialog()
  const { addInfo, addError } = useNotifications()

  const deleteResource = async () => {
    await client.deleteProduct(subscriptionId, productId)
    addInfo('Product has been deleted successfully')
  }

  const deleteProduct = React.useCallback(async () => {
    return confirmDeletion(`Are you sure want to delete the product "${value && value.name}"?`, `${value && value.name}`, deleteResource)
      .then(async ({ answer }) => {
        if (answer === 'delete') {
          history.push(`/subscriptions/${subscriptionId}/provider/products`)
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addError, addInfo, confirmDeletion, refresh, value])

  const ownerActions = [
    {
      key: 'products',
      title: 'More product settings',
      href: `/subscriptions/${subscriptionId}/gateway/products/${productId}/`,
    },
    {
      key: 'delete-product',
      title: 'Delete product',
      onClick: deleteProduct,
    },
  ]
  const otherActions = [
    {
      key: 'products',
      title: 'More product settings',
      href: `/subscriptions/${subscriptionId}/gateway/products/${productId}/`,
    },
  ]
  return (
    <Page loading={!providerCompleted} skeleton={FormSkeleton}>
      { (provider && provider.status === 'Approved')
        ? (
          <React.Fragment>
            <PageHeader
              loading={!completed}
              logo={value?.logoUrl || '/default-product-icon.png'}
              heading={value?.name || 'Unnamed product'}
              subheading={provider?.name || ''}
            >
              {value && <ProductVisibilityButton subscriptionId={subscriptionId} data={value} />}
              {productId && <ActionMenu actions={(userRole === 'Owner') ? ownerActions : otherActions} />}
            </PageHeader>
            <Tabs>
              <Tab name="settings" title="Settings">
                <ProductSettingsTab />
              </Tab>
              {productId
                ? (
                  <Tab name="apis" title="APIs">
                    <ApisTab />
                  </Tab>
                ) : ''}
              <Tab name="consumers" title="Consumers">
                <ProductConsumersTab />
              </Tab>
            </Tabs>
          </React.Fragment>
        ) : (
          <NotAuthorizedMessage header="Provider not approved" />
        )}
    </Page>
  )
}
