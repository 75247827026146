import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import { useData } from '@logic/platform-core'
import Prism from 'prismjs'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-csharp'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-powershell'
import 'prismjs/components/prism-sql'
import 'prismjs/components/prism-typescript'
import { Skeleton } from '../../index'

export type MarkdownProps = {
  /**
   * The URL of markdown document to be displayed.
   * */
  source: string | undefined,
  loading?: boolean,
}

const linkTarget = (uri: string) => (uri.startsWith('http') ? '_blank' : '')

const useMarkdown = (source: string | undefined) => useData(undefined, () => {
  return (source ? fetch(source).then((result) => result.text()) : Promise.resolve(undefined))
}, [source])
const usePrism = (value: string | undefined) => {
  React.useEffect(() => {
    Prism.highlightAll()
  }, [value])
}
const useScrollToId = (hash: string) => {
  React.useEffect(() => {
    if (hash !== '') {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView()
      }
    }
  }, [hash])
}

export const MarkdownSkeleton = () => (
  <div>
    <h2><Skeleton width={200} /></h2>
    <Skeleton count={5} />
  </div>
)

export const Markdown: React.FC<MarkdownProps> = ({ source, loading }: MarkdownProps): JSX.Element => {
  const { value, completed } = useMarkdown(source)
  const { hash } = window.location
  usePrism(value)
  useScrollToId(hash)

  // TODO: Support errors
  // TODO: Support headings
  return (loading === true || !completed) ? <MarkdownSkeleton /> : <ReactMarkdown linkTarget={linkTarget}>{value || ''}</ReactMarkdown>
}
