export type ResourceModel = {
  id: string,
  subscriptionId: string,
  name: string,
  resourceType: string,
  status: ResourceStatus,
  createdAt: string,
  updatedAt: string,
}

export enum ResourceStatus {
  Published = 'Published',
  Unpublished = 'Unpublished'
}
