import React from 'react'
import {
  ActionMenu,
  FormSkeleton,
  Page,
  PageHeader,
  Tab,
  Tabs,
} from '@logic/platform-fabric'
import { useGatewayClient } from './GatewayClient'
import {
  Data, useContextSubscriptionId, useData, useTitle,
} from '@logic/platform-core'
import { useParams } from 'react-router-dom'
import { useSubscriptionProvider } from '../useSubscriptionProvider'
import { ApiForm } from './ApiForm'
import { GatewayApiVersionSetModel } from './GatewayApiModel'
import { VersionsTab } from './VersionsTab'

export const useGatewayApi = (subscriptionId: string, apiVersionSetId:string): Data<GatewayApiVersionSetModel | undefined> => {
  const client = useGatewayClient()
  return useData(undefined, () => client.fetchApiVersionSet(subscriptionId, apiVersionSetId), [client, subscriptionId, apiVersionSetId])
}

export const GatewayApiPage = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { versionSetId, apiId } = useParams<{ versionSetId:string, apiId:string }>()
  const { value: provider } = useSubscriptionProvider(subscriptionId)
  const { value, completed } = useGatewayApi(subscriptionId, versionSetId)
  const absoluteApi = value?.versions.find((x) => x.apiId === apiId)
  const apiVersionSetId = value?.versionSetId
  useTitle(() => (absoluteApi ? `${absoluteApi.name} - Gateway API` : 'Gateway API'), [absoluteApi])
  const actions = React.useMemo(() => [
    {
      key: 'more-settings',
      title: 'More API settings',
      href: `/subscriptions/${subscriptionId}/gateway/apis/${apiVersionSetId}/versions/${apiId}`,
    },
  ], [subscriptionId, apiId, apiVersionSetId])

  return (
    <Page loading={!completed} skeleton={FormSkeleton}>
      <PageHeader
        loading={!completed}
        heading={absoluteApi?.name || 'Unnamed Api'}
        subheading={provider?.name || ''}
      >
        <ActionMenu actions={actions} />
      </PageHeader>
      <Tabs>
        <Tab name="settings" title="Settings">
          <ApiForm />
        </Tab>
        { versionSetId
          ? (
            <Tab name="versions" title="Versions">
              <VersionsTab />
            </Tab>
          ) : ''}
      </Tabs>
    </Page>
  )
}
