import React from 'react'
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  Column,
} from 'react-table'
import './Table.scss'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import FilterListIcon from '@material-ui/icons/FilterList'
import { Skeleton } from '../../index'
import Nothing from './nothing.svg'

// eslint-disable-next-line @typescript-eslint/ban-types
export type TableProps<TItem extends object> = {
  loading: boolean,
  columns: Column<TItem>[],
  data: TItem[],
  defaultSort: string,
  noRowsMessage?: string | React.ReactNode,
}

export const DefaultColumnFilter = ({
  column: { filterValue, setFilter },
}: any): JSX.Element => {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value || undefined)}
    />
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const Table = <TItem extends object>({
  loading,
  data,
  columns,
  defaultSort,
  noRowsMessage,
}: TableProps<TItem>): JSX.Element => {

  const tableData = React.useMemo(
    () => (loading ? Array(5).fill({}) : data),
    [loading, data]
  )
  const tableColumns = React.useMemo(
    () =>
      (loading
        ? columns.map((column) => ({
          ...column,
          Cell: <Skeleton />,
        }))
        : columns),
    [loading, columns]
  )
  const [filterVisible, setFilterVisible] = React.useState(false)
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  ) as any

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns: tableColumns,
    data: tableData,
    defaultColumn,
    initialState: {
      sortBy: [
        {
          id: defaultSort,
          desc: false,
        },
      ],
    } as any,
  },
  useFilters,
  useSortBy,
  usePagination) as any

  if (!loading && data.length === 0) {
    return (
      <div className="table-empty">
        <img src={Nothing} alt="Empty space" />
        <h4>Nothing to show</h4>
        {noRowsMessage && <p>{noRowsMessage}</p>}
      </div>
    )
  }

  return (
    <div aria-busy={loading} role="table">
      <div className="table-header">
        <button onClick={() => setFilterVisible(!filterVisible)} type="button">
          <FilterListIcon />
        </button>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup:any) => (
            <React.Fragment>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column:any) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <KeyboardArrowDownIcon />
                          : <KeyboardArrowUpIcon />
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
              {
                filterVisible
                  ? (
                    <tr>
                      {headerGroup.headers.map((column:any) => (
                        <th>
                          <div>{column.canFilter ? column.render('Filter') : null}</div>
                        </th>
                      ))}
                    </tr>
                  )
                  : null
              }
            </React.Fragment>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(
            (row:any) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell:any) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )
            }
          )}
        </tbody>
      </table>
      <div className="table-footer">
        {
          pageCount > 1
            ? (
              <div className="page-selector">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} type="button">
                  First
                </button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage} type="button">
                  <ChevronLeftIcon />
                </button>
                <span>Page {pageIndex + 1}/{pageCount}</span>
                <button onClick={() => nextPage()} disabled={!canNextPage} type="button">
                  <ChevronRightIcon />
                </button>
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} type="button">
                  Last
                </button>
              </div>
            )
            : null
        }
        <div className="page-size-selector">
          Show
          <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
            {[5, 10, 20, 50, 100].map((optionPageSize) => (
              <option key={optionPageSize} value={optionPageSize}>{optionPageSize}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}
