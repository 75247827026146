import React, { useMemo } from 'react'
import {
  useAuthentication,
} from '@logic/platform-core'
import { UserSubscription, useSubscriptionClient } from './SubscriptionClient'

export type UserSubscriptionsContextProps = {
  completed: boolean,
  subscriptions: UserSubscription[],
  addSubscription: (subscription: UserSubscription) => void,
}

export const UserSubscriptionsContext = React.createContext<UserSubscriptionsContextProps | undefined>(undefined)

export const UserSubscriptionsProvider: React.FC = ({ children }) => {
  const client = useSubscriptionClient()
  const { user } = useAuthentication()
  const [subscriptions, updateSubscriptions] = React.useState<UserSubscription[]>([])
  const [completed, setCompleted] = React.useState(false)
  const sub = user?.profile.sub
  React.useEffect(() => {
    if (sub) {
      setCompleted(false)
      client.getSubscriptions()
        .then((subs) => {
          updateSubscriptions(subs)
          setCompleted(true)
        })
    }
  }, [client, sub])

  const addSubscription = React.useCallback((subscription: UserSubscription) => {
    updateSubscriptions([...subscriptions, subscription])
  }, [subscriptions])

  const context = useMemo(() => ({
    completed,
    subscriptions,
    addSubscription,
  }), [completed, subscriptions, addSubscription])

  return (
    <UserSubscriptionsContext.Provider value={context}>{children}</UserSubscriptionsContext.Provider>
  )
}

export const useUserSubscriptions = (): UserSubscriptionsContextProps => {
  const context = React.useContext<UserSubscriptionsContextProps | undefined>(UserSubscriptionsContext)
  if (!context) {
    throw Error('Cannot access UserSubscriptionsContext')
  }

  return context
}
