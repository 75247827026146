import { Config, useConfig } from '@logic/platform-core'
import { useAxios } from '../infrastructure/useAxios'
import { AxiosInstance } from 'axios'

export type ClientCredentials = {
  clientId: string,
  clientSecret: string,
  resourceScope: string,
  resourceDescription: string,
  applicationIds?: string[],
  tokenEndpoint: string,
}

export type ClientCredentialsClient = {
  getClientCredentials: (subscriptionId: string) => Promise<ClientCredentials[]>,
  postClientCredentials: (subscriptionId: string, displayName: string) => Promise<void>,
  deleteClientCredentials: (subscriptionId: string, applicationId: string) => Promise<void>,
}

export type ClientCredentialsApplicationClient = {
  renewClientCredentials: (applicationId: string) => Promise<string>,
}

const getClientCredentials = (client: AxiosInstance) => (subscriptionId: string) => {
  return client.get<ClientCredentials[]>(`/subscriptions/${subscriptionId}/clientcredentials`).then((response) => response.data)
}

const postClientCredentials = (client: AxiosInstance) => (subscriptionId: string, displayName: string) => {
  return client.post(`subscriptions/${subscriptionId}/clientcredentials`, { displayName }).then((response) => response.data)
}

const deleteClientCredentials = (client:AxiosInstance) => (subscriptionId: string, applicationId: string) => {
  return client.delete(`subscriptions/${subscriptionId}/clientcredentials/${applicationId}`).then((response) => response.data)
}
const renewClientCredentials = (client:AxiosInstance) => (applicationId: string) => {
  return client.patch(`/${applicationId}/RenewSecret`).then((response) => response.data)
}

const createClientCredentialsClient = (client: AxiosInstance): ClientCredentialsClient => {
  return {
    getClientCredentials: getClientCredentials(client),
    postClientCredentials: postClientCredentials(client),
    deleteClientCredentials: deleteClientCredentials(client),
  }
}

const createClientCredentialsApplicationClient = (client: AxiosInstance): ClientCredentialsApplicationClient => {
  return {
    renewClientCredentials: renewClientCredentials(client),
  }
}

export const useClientCredentialsClient = (): ClientCredentialsClient => {
  const { labPlatformApiUrl } = useConfig()
  return useAxios(labPlatformApiUrl, (client) => createClientCredentialsClient(client))
}

type ClientCredentialConfig = {
  identityApiUrl: string,
} & Config

export const useIdentityClient = (): ClientCredentialsApplicationClient => {
  const { identityApiUrl } = useConfig<ClientCredentialConfig>()
  const client = useAxios(identityApiUrl, createClientCredentialsApplicationClient)
  return client
}
