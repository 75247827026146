import { useAuthentication } from '@logic/platform-core'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import React from 'react'

const createAxiosJwtInterceptor = (getAccessToken: () => string | undefined) => (config: AxiosRequestConfig) => {
  if (getAccessToken()) {
    const userToken = getAccessToken()
    config.headers = {
      Authorization: `Bearer ${userToken}`,
    }
  }
  return config
}

const createAxios = (instance: AxiosInstance, baseURL: string, getAccessToken: () => string | undefined) => {
  instance.defaults.baseURL = baseURL
  instance.interceptors.request.use(createAxiosJwtInterceptor(getAccessToken))
  return instance
}

export const useAxios = <T>(baseUrl: string, factory: (client: AxiosInstance) => T): T => {
  const { getAccessToken } = useAuthentication()
  return React.useMemo(() => {
    const instance = axios.create()
    return factory(createAxios(instance, baseUrl, getAccessToken))
  }, [baseUrl, getAccessToken]) // eslint-disable-line react-hooks/exhaustive-deps
}
