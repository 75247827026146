import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'
import {
  LogEvent,
  LogEventLevel,
  Sink,
} from 'structured-log'

export default class AppInsightsSink implements Sink {
  private appInsights: ApplicationInsights | undefined

  constructor(appInsigths: ApplicationInsights | undefined) {
    this.appInsights = appInsigths
  }

  private levelPrefixMap: Map<number, SeverityLevel> = new Map([
    [LogEventLevel.fatal, SeverityLevel.Critical],
    [LogEventLevel.error, SeverityLevel.Error],
    [LogEventLevel.warning, SeverityLevel.Warning],
    [LogEventLevel.information, SeverityLevel.Information],
    [LogEventLevel.debug, SeverityLevel.Verbose],
    [LogEventLevel.verbose, SeverityLevel.Verbose],
  ])

  public emit = (events: LogEvent[]) => {
    for (const e of events) {
      const prefix = this.levelPrefixMap.get(e.level) || SeverityLevel.Information
      this.logToAppInsights(prefix, e)
    }
  }

  private logToAppInsights(severityLevel: SeverityLevel, e: LogEvent) {
    if (this.appInsights) {
      const output = `[${severityLevel}] ${e.messageTemplate.render(e.properties)}`
      if (severityLevel === SeverityLevel.Error || severityLevel === SeverityLevel.Critical) {
        if (e.error instanceof Error) {
          this.appInsights.trackException({
            exception: e.error,
          })
        } else {
          this.appInsights.trackException({
            exception: new Error(output),
          })
        }
      } else {
        this.appInsights.trackTrace({
          severityLevel,
          message: output,
        })
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public flush() {
    return Promise.resolve()
  }
}
