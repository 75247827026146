import React from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { IconButton } from '@material-ui/core'

export type HiddenDataProps = {
    content: React.ReactText,
    length?: number,
    mask?: string,
    visibility?:boolean,
  }

export const HiddenData = ({ content, length = 32, mask = '*', visibility }:HiddenDataProps): JSX.Element => {
  const maskVal = mask.charAt(0).repeat(length)
  const [contentVisible, setContentVisible] = React.useState(false)

  const toggleVisibility = () => {
    const currentState = contentVisible
    setContentVisible(!currentState)
  }

  return (
    <React.Fragment>
      {(contentVisible && content) || (content && maskVal)}
      { visibility && (
        <IconButton onClick={toggleVisibility}>
          {(contentVisible && <VisibilityOffIcon />) || <VisibilityIcon />}
        </IconButton>
      )}
    </React.Fragment>
  )
}
