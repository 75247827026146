import { useContextSubscriptionId } from '@logic/platform-core'
import {
  CopyToClipboard,
  HiddenData,
  Button,
  Section,
  SectionSkeleton,
} from '@logic/platform-fabric'
import React from 'react'
import { useParams } from 'react-router-dom'
import '../../client-credentials/ClientCredentialsPage.scss'
import { MarketplaceClient, useMarketplaceClient } from '../MarketplaceClient'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useQuestionDialog } from '../../layout/QuestionDialog'
import { useApiKey } from './ApiKeyPage'

type ApiKeyProps = {
    isRefresh?: boolean,
}

const useRegenerateKey = (keyName: string, currentKey: string | undefined, action: (client: MarketplaceClient) => Promise<string>): [
  string,
  () => Promise<void>
] => {
  const [key, setKey] = React.useState(currentKey || '')
  const { askQuestion } = useQuestionDialog()
  const client = useMarketplaceClient()
  const onClick = React.useCallback(() => {
    return askQuestion(`Are you sure you want to reset ${keyName}?`, 'API Key').then(({ answer }) => {
      if (answer === 'yes') {
        action(client).then(setKey)
      }
    })
  }, [askQuestion, client, keyName, action])

  React.useEffect(() => {
    setKey(currentKey || '')
  }, [currentKey])

  return [key, onClick]
}

export const ApiKeys = (props: ApiKeyProps): JSX.Element => {
  const { isRefresh } = props
  const subscriptionId = useContextSubscriptionId()
  const { apiKeyId } = useParams<{ apiKeyId: string }>()
  const { value, completed } = useApiKey(apiKeyId)

  const regeneratePrimaryKey = React.useCallback((client) => client.regeneratePrimaryKey(subscriptionId, apiKeyId), [apiKeyId, subscriptionId])
  const [primaryKey, resetPrimaryKey] = useRegenerateKey('primary key', value?.primaryKey, regeneratePrimaryKey)
  const regenerateSecondaryKey = React.useCallback((client) => client
    .regenerateSecondaryKey(subscriptionId, apiKeyId), [apiKeyId, subscriptionId])
  const [secondaryKey, resetSecondaryKey] = useRegenerateKey('secondary key', value?.secondaryKey, regenerateSecondaryKey)

  return (
    <Section loading={!completed} skeleton={SectionSkeleton}>
      <p>
        To consume related product, please add <strong>Logic-Api-Key</strong> header
        with one of those values to request that you are sending.
      </p>
      <div className="properties">
        <div>
          <div className="name">Primary key</div>
          <div className="value"><HiddenData content={primaryKey} /></div>
          <div className="copy">
            <CopyToClipboard text={primaryKey} />
            { isRefresh === true && <Button variant="light" size="small" icon={RefreshIcon} onPress={resetPrimaryKey} title="Reset primary key" />}
          </div>
        </div>
        <div>
          <div className="name">Secondary key</div>
          <div className="value"><HiddenData content={secondaryKey} /></div>
          <div className="copy">
            <CopyToClipboard text={secondaryKey} />
            { isRefresh === true && <Button variant="light" size="small" icon={RefreshIcon} onPress={resetSecondaryKey} title="Reset secondary key" />}
          </div>
        </div>
      </div>
    </Section>
  )
}
