import {
  Button,
  Page,
  PageHeader,
  Table,
} from '@logic/platform-fabric'
import React from 'react'
import { Column } from 'react-table'
import { useSubscriptionName } from '../useSubscriptionName'
import DeleteIcon from '@material-ui/icons/Delete'
import { useContextSubscriptionId, useData } from '@logic/platform-core'
import { useSubscriptionUserClient, InvitationItem } from './SubscriptionUserClient'
import { useQuestionDialog } from '../../layout/QuestionDialog'

const useInvitations = (subscriptionId: string) => {
  const client = useSubscriptionUserClient()
  return useData([], () => client.getInvitations(subscriptionId), [subscriptionId, client])
}

export const InvitationsList = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const subscriptionName = useSubscriptionName(subscriptionId)
  const { value, completed, refresh } = useInvitations(subscriptionId)
  const client = useSubscriptionUserClient()
  const { askQuestion } = useQuestionDialog()
  const deleteInvitation = React.useCallback((invitation: InvitationItem) => {
    askQuestion(`Are you sure you want to delete invitation for ${invitation.emailAddress}?`, 'Delete invitation').then(({ answer }) => {
      if (answer === 'yes') {
        client.deleteInvitation(subscriptionId, invitation.id).then(refresh)
      }
    })
  }, [client, subscriptionId, askQuestion, refresh])
  const columns: Column<InvitationItem>[] = [
    {
      accessor: 'emailAddress',
      Header: 'E-mail address',
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }: any) => (
        <div className="actions">
          <Button variant="light" size="small" title="Delete" icon={DeleteIcon} onPress={() => deleteInvitation(row.original)} />
        </div>
      ),
    },
  ]

  return (
    <Page>
      <PageHeader heading={subscriptionName} subheading="Subscription invitations" />
      <Table
        loading={!completed}
        data={value || []}
        columns={columns}
        defaultSort="emailAddress"
      />
    </Page>
  )
}
