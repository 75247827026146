import {
  Button,
  CopyToClipboard,
  HiddenData,
} from '@logic/platform-fabric'
import React, { useState } from 'react'
import {
  useConfig,
  useContextSubscriptionId,
  useNotifications,
} from '@logic/platform-core'
import { useSubscriptionRole } from '../subscriptions/useSubscriptionRole'
import { DeleteClientCredentialsButton } from './DeleteClientCredentialsDialog'
import { useQuestionDialog } from '../layout/QuestionDialog'
import { useIdentityClient } from './ClientCrendetialsClient'

type ClientCredentialsWidgetProps = {
    clientId: string,
    clientSecret: string,
    resourceScope: string,
    applicationIds: string[],
    tokenEndpoint: string,
    resourceDescription: string,
    refresh: () => void,
    showActionButtons: boolean,
}

export const ClientCredentialsWidget = (props: ClientCredentialsWidgetProps): JSX.Element => {
  const { clientId, clientSecret, resourceScope, applicationIds, tokenEndpoint, resourceDescription, refresh, showActionButtons } = props
  const subscriptionId = useContextSubscriptionId()
  const userRole = useSubscriptionRole(subscriptionId)
  const { clientCredentialsAppId } = useConfig()
  const { addInfo } = useNotifications()
  const client = useIdentityClient()
  const { askQuestion } = useQuestionDialog()
  const [secret, setSecret] = useState<string>(clientSecret)

  const renewClientCredentials = React.useCallback(async (renewClientId: string) => {
    return askQuestion('Are you sure want to renew Client Credentials secret', 'Reset client credentials secret')
      .then(async ({ answer }) => {
        if (answer === 'yes') {
          setSecret(await client.renewClientCredentials(renewClientId))
          addInfo('Client credentials has been renewed successfully')
        }
      })
  }, [askQuestion, client, addInfo])

  return (
    <React.Fragment>
      <p>
        The OAuth2 Client Credentials token endpoint is: <strong>{tokenEndpoint}</strong>
      </p>
      <p>
        To obtain an access token an <strong>HTTP POST</strong> must be made against the token endpoint,
        supplying the following information as url-encoded form content:
      </p>
      <div className="properties">
        <div>
          <div className="name">grant_type</div>
          <div className="value">client_credentials</div>
          <div className="copy"><CopyToClipboard text="client_credentials" /></div>
        </div>
        <div>
          <div className="name">client_id</div>
          <div className="value">{clientId}</div>
          <div className="copy"><CopyToClipboard text={clientId} /></div>
        </div>
        <div>
          <div className="name">client_secret</div>
          <div className="value"><HiddenData content={secret} /></div>
          <div className="copy"><CopyToClipboard text={secret} /></div>
        </div>
        <div>
          <div className="name">scope</div>
          <div className="value">{resourceScope}</div>
          <div className="copy"><CopyToClipboard text={resourceScope} /></div>
        </div>
        <div>
          <div className="name">applications</div>
          <div className="value">{applicationIds}</div>
          <div className="copy"><CopyToClipboard text={applicationIds?.toString() || ''} /></div>
        </div>
      </div>
      { showActionButtons === true && userRole === 'Owner'
      && (
        <React.Fragment>
          <Button className="revoke" type="button" variant="light" onPress={() => renewClientCredentials(clientId)}>Renew client secret</Button>
          {applicationIds?.includes(clientCredentialsAppId)
            && <DeleteClientCredentialsButton onClose={refresh} clientId={clientId} clientName={resourceDescription} />}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
