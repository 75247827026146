import React, { useState } from 'react'
import { Button } from '@logic/platform-fabric'
import {
  GatewayProductModel, GatewayVisibility,
} from './GatewayProductModel'
import { useQuestionDialog } from '../..'
import { useGatewayClient } from './GatewayClient'
import { useNotifications } from '@logic/platform-core'
import PublishIcon from '@material-ui/icons/Publish'

type ProductVisibilityProps = {
    subscriptionId: string,
    data: GatewayProductModel,
}

export const ProductVisibilityButton = (props: ProductVisibilityProps): JSX.Element => {
  const { subscriptionId, data } = props
  const client = useGatewayClient()
  const { addInfo } = useNotifications()
  const [visibilityStatus, setVisibiltyStatus] = useState(data.visibility)
  const { askQuestion } = useQuestionDialog()

  const updateVisibility = React.useCallback(async (visibility: GatewayVisibility) => {
    const message = (visibility === GatewayVisibility.Public) ? 'Are you sure you want to publish your product' : 'Are you sure you want to unpublish your product'
    const title = (visibility === GatewayVisibility.Public) ? 'Publish' : 'Unpublish'
    return askQuestion(message, title)
      .then(async ({ answer }) => {
        if (answer === 'yes') {
          const productVisbility = await client.patchProduct({ visibility: visibility }, subscriptionId, data.id)
          setVisibiltyStatus(productVisbility.visibility)
          addInfo((visibility === GatewayVisibility.Private) ? 'Product has been unpublished successfully' : 'Product has been published successfully')
        }
      })
  }, [askQuestion, client, subscriptionId, data.id, addInfo])

  return (
    <React.Fragment>
      {visibilityStatus === GatewayVisibility.Private
        && <Button type="submit" variant="primary" icon={PublishIcon} onPress={() => updateVisibility(GatewayVisibility.Public)}>Publish</Button>
      }
      {visibilityStatus === GatewayVisibility.Public
        && <Button type="submit" variant="primary" onPress={() => updateVisibility(GatewayVisibility.Private)}>Unpublish</Button>
      }
    </React.Fragment>
  )
}
