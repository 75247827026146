import React from 'react'
import {
  ActionMenu,
  LinkButton,
  Page,
  PageHeader,
  Table,
  CopyToClipboard,
} from '@logic/platform-fabric'
import {
  useContextSubscriptionId,
  useServiceCatalog,
  distanceInWords,
  useTitle,
  Data,
  useData,
} from '@logic/platform-core'
import { Column, Row } from 'react-table'
import { useResources } from './useResources'
import { ResourceModel } from './ResourceModel'
import {
  useResourceTypes,
  ResourceIcon,
  ResourceLink,
} from './resourceLinkIconMapping'
import { useSubscriptionProvider } from '../provider/useSubscriptionProvider'
import { Link } from 'react-router-dom'
import { DropdownFilter } from './ResourceFilter'
import { shellResources } from '../description'
import { SubscriptionHeading } from './SubscriptionHeading'
import './ResourceFilter.scss'
import { SubscriptionDetails, useSubscriptionClient } from '../subscriptions/SubscriptionClient'

const useSubscription = (subscriptionId: string): Data<SubscriptionDetails | undefined> => {
  const client = useSubscriptionClient()
  return useData(undefined, () => client.getSubscription(subscriptionId), [subscriptionId, client])
}

export const ResourceOverview = (): JSX.Element => {
  const { services } = useServiceCatalog()
  const subscriptionId = useContextSubscriptionId()
  const { value: subscriptionDetails } = useSubscription(subscriptionId)
  const { value, completed } = useResources(subscriptionId)
  const { value: provider } = useSubscriptionProvider(subscriptionId)
  useTitle(() => (subscriptionDetails?.name ? `${subscriptionDetails.name} - Resource overview` : 'Resource overview'), [subscriptionDetails?.name])

  const resources = useResourceTypes([...services.map((service) => service.resources).flat(), ...shellResources])
  const resourceTypeAllowedValues = React.useMemo(() => {
    const items = value?.map((x) => ({ displayName: x.resourceType, value: x.resourceType })) || []
    return items.filter((item, index) => items.findIndex(({ value: x }) => x === item.value) === index)
  }, [value])

  const columns: Column<ResourceModel>[] = [
    {
      id: 'icon',
      Header: '',
      Cell: ({ row }: any) => {
        return <ResourceIcon resource={row.original} resourceTypes={resources} />
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row }) => {
        return <ResourceLink resource={row.original} resourceTypes={resources} />
      },
    },
    {
      Header: 'Type',
      accessor: 'resourceType',
      Filter: ({ column: { filterValue, setFilter } }: any) => (
        <DropdownFilter allowedValues={resourceTypeAllowedValues} selectedKeys={filterValue} onSelectionChange={setFilter} />
      ),
      filter: React.useCallback((rows: Row<ResourceModel>[], _, filterValue: Set<string> | undefined) => {
        return rows.filter((row) => !filterValue || filterValue.size === 0 || filterValue.has(row.original.resourceType))
      }, []),
    } as Column<ResourceModel>,
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({ row }) => {
        return <React.Fragment>{row.original.id}<CopyToClipboard text={row.original.id} /> </React.Fragment>
      },
    },
    {
      Header: 'Published',
      accessor: 'createdAt',
      Cell: ({ row }) => {
        return `${distanceInWords(row.original.createdAt)} ago`
      },
    },
  ]

  const actions = [
    { key: 'users', title: 'Users', href: `/subscriptions/${subscriptionId}/users` },
    { key: 'client-credentials', title: 'Client credentials', href: `/subscriptions/${subscriptionId}/client-credentials` },
    { key: 'pending-invitations', title: 'Pending invitations', href: `/subscriptions/${subscriptionId}/invitations` },
    {
      key: 'products',
      title: provider?.id ? 'My products' : 'My provider',
      href: provider?.id ? `/subscriptions/${subscriptionId}/provider/products` : `/subscriptions/${subscriptionId}/marketplace/providers/create`,
    },
  ]

  return (
    <Page>
      <PageHeader heading={<SubscriptionHeading subscriptionName={subscriptionDetails?.name} isProduction={subscriptionDetails?.isProduction} />} subheading="Resource Overview" resourceId={subscriptionId}>
        <LinkButton to="/marketplace" variant="primary">Browse marketplace</LinkButton>
        <LinkButton to={`/subscriptions/${subscriptionId}/edit`}>Edit</LinkButton>
        <ActionMenu actions={actions} />
      </PageHeader>
      <Table
        loading={!completed}
        data={value || []}
        columns={columns}
        defaultSort="name"
        noRowsMessage={<p>Looks like you have no resources here.<br /><Link to="/marketplace">Click here to create new resource.</Link></p>}
      />
    </Page>
  )
}
