import React from 'react'
import {
  useHistory, useParams,
} from 'react-router-dom'
import {
  useContextSubscriptionId, useNotifications, useTitle,
} from '@logic/platform-core'
import {
  Button, CancelButton, Field, Form, Page, PageHeader, Section, SectionSkeleton, useSimpleForm,
} from '@logic/platform-fabric'
import { useSubscriptionProvider } from '../useSubscriptionProvider'
import {
  ApisetsStatus, GatewayCurrentVersionModel,
} from './GatewayApiModel'
import { useGatewayClient } from './GatewayClient'
import { GatewaySynchronization, GatewayVisibility } from './GatewayProductModel'
import { useVersionApis } from './VersionsTab'

export const useApiVersionPageForm = (subscriptionId: string, providerId: string | undefined, productId: string, versionSetId: string,
  apiId: string, apiVersion:string, absoluteApi: GatewayCurrentVersionModel | undefined, name:string | undefined, path:string | undefined) => {
  const client = useGatewayClient()
  const history = useHistory()
  const { addInfo, addError } = useNotifications()
  const apiVersionSetId = versionSetId

  const initialValues: GatewayCurrentVersionModel = React.useMemo(() => ({
    apiId: '',
    providerId: '',
    name: '',
    description: '',
    path: '',
    backendServiceUrl: '',
    apiVersion: '',
    openApiSpecUrl: '',
    logoUrl: '',
    documentationUrl: '',
    productIds: [productId],
    visibility: GatewayVisibility.Public,
    apiVersionSetId: apiVersionSetId,
    synchronization: GatewaySynchronization.Active,
    isCurrent: false,
    openApiSpec: new File([], ''),
    status: ApisetsStatus.Preview,
  }), [apiVersionSetId, productId])

  const fetchApiVersionSet = React.useCallback(async () => {
    const updatedVersion = { ...absoluteApi, path, apiVersionSetId, providerId }
    return (updatedVersion || initialValues)
  }, [absoluteApi, path, apiVersionSetId, providerId, initialValues])

  const saveApiVersion = async (data: any) => {
    const apiData = { ...data, name, path }
    const axiosResponse = apiVersion ? await client.updateApiVersion(apiData, subscriptionId)
      : await client.createApiVersion(apiData, subscriptionId)
    if (axiosResponse.status === 200 || axiosResponse.status === 201) {
      apiVersion ? addInfo('API Version has been updated') : addInfo('API version has been created')
      history.push(`/subscriptions/${subscriptionId}/provider/products/${productId}/apis/${versionSetId}/versions/${apiId}/edit#versions`)
    } else if (axiosResponse.status === 400 || axiosResponse.status === 409 || axiosResponse.status === 422) {
      const err = (axiosResponse.data).valueOf()
      const errorMessage = Object.values(err) as any
      addError(errorMessage)
    }
  }
  return useSimpleForm(initialValues, (apiVersion ? fetchApiVersionSet : undefined), saveApiVersion)
}

export const VersionForm = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const { value: provider } = useSubscriptionProvider(subscriptionId)
  const { versionSetId, apiId, productId, apiVersion } = useParams<{ versionSetId: string, apiId:string, productId:string, apiVersion:string }>()
  const { value, completed } = useVersionApis(subscriptionId, versionSetId)
  const absoluteApi = value?.versions.find((x) => x.apiId === apiId)
  const name = absoluteApi?.name
  const path = value?.path
  const { loading, ...form } = useApiVersionPageForm(subscriptionId, provider?.id,
    productId, versionSetId, apiId, apiVersion, absoluteApi, name, path)

  useTitle(() => (name ? `${name} - Gateway Version` : 'Gateway Version'), [name])

  const apiVersionStatus = [
    { value: ApisetsStatus.Active, text: 'Active' },
    { value: ApisetsStatus.Deprecated, text: 'Deprecated' },
    { value: ApisetsStatus.Preview, text: 'Preview' },
  ]

  if (loading) {
    return <SectionSkeleton />
  }
  return (
    <Page>
      <PageHeader
        loading={!completed}
        heading={name}
      />
      <Form {...form}>
        <Section>
          <Field name="apiVersion" label="API Version" type="text" validators={['required']} />
          <Field name="backendServiceUrl" label="Backend location" placeholder="e.g., https://api.northwind.com/trading" type="text" validators={['required', 'url']} />
          {!apiVersion && <Field type="switch" label="Make as current" name="isCurrent" />}
          <Field name="status" label="State" type="dropdown" options={apiVersionStatus} />
          <Field name="documentation" label="API Description" type="file" accept=".md" />
          <Field name="openApiSpec" label="API Definition" type="file" accept=".json" />
        </Section>
        <Button type="submit" variant="primary">Save</Button>
        <CancelButton defaultBackTo={`/subscriptions/${subscriptionId}/provider/products/${productId}/apis/${versionSetId}/versions/${apiId}/edit#versions/`} />
      </Form>
    </Page>
  )
}
