import { useConfig } from '@logic/platform-core'
import { AxiosInstance } from 'axios'
import { useAxios } from '../infrastructure/useAxios'

const updateDefaultSubscriptionId = (client: AxiosInstance) => (userId: string, subscriptionId: string) => {
  return client.put(`users/${userId}/preferences`, {
    Console: {
      DefaultSubscriptionId: subscriptionId,
    },
  }).then(() => {
    // do nothing
  })
}

type UserClient = {
  updateDefaultSubscriptionId: (userId: string, subscriptionId: string) => Promise<void>,
}

const createUserClient = (client: AxiosInstance): UserClient => {
  return {
    updateDefaultSubscriptionId: updateDefaultSubscriptionId(client),
  }
}

export const useUserClient = (): UserClient => {
  const config = useConfig()
  return useAxios(config.labPlatformApiUrl, createUserClient)
}
