import React from 'react'
import { Field } from 'formik'
import { BaseFieldControlProps } from './BaseFieldControlProps'

export type Option = {
  value: string,
  text: string,
}

export type DropDownFieldProps = {
  type: 'dropdown',
  options: Option[],
  optionLabel?: string,
}

export type DropDownFieldControlProps = DropDownFieldProps & BaseFieldControlProps

export const DropDownFieldControl = ({ name, options, validate, optionLabel, ...props }: DropDownFieldControlProps): JSX.Element => {
  return (
    <Field name={name} as="select" validate={validate} {...props}>
      {optionLabel && <option value="" selected={true}>{optionLabel}</option>}
      {options.map(({ value, text }: Option) => <option value={value} key={value}>{text}</option>)}
    </Field>
  )
}
