import React from 'react'
import { AuthPage } from './AuthPageContent'
import {
  useAuthentication,
  useConfig,
  Config,
  useTitle,
} from '@logic/platform-core'
import { useLocation } from 'react-router-dom'
import { hideSplash } from '../layout/Splash'

export const LoginPage = () => {
  const { signIn } = useAuthentication()
  const { state: { redirectTo } } = useLocation<{ redirectTo: string }>()
  const { idps } = useConfig<{ idps: string[] } & Config>()
  useTitle(() => ('Login'), [])

  const providers = [
    {
      id: 'KmdExch',
      domainHint: 'kmd.dk',
      label: 'KMD ADFS',
    },
    {
      id: 'LogicIdentityLocalUsers',
      domainHint: 'logicIdentity-localusers',
      label: 'Logic Users',
    },
    {
      id: 'LogicIdentityLocalUsersDev',
      domainHint: 'logicIdentity-localusersDev',
      label: 'Logic Users - Dev',
    },
  ].filter((provider) => idps.indexOf(provider.id) !== -1)
  const signInUsing = React.useCallback((loginHint) => {
    signIn(redirectTo, loginHint)
  }, [redirectTo, signIn])
  hideSplash()
  return (
    <AuthPage>
      <div className="intro">
        <p>Sign in with your existing account</p>
      </div>
      <div role="form">
        <ul className="idps">
          {providers.map((provider) => (
            <li>
              <button type="button" className="button" id={provider.id} aria-label={provider.label} onClick={() => signInUsing(provider.domainHint)}>{provider.label}</button>
            </li>
          ))}
        </ul>
      </div>
    </AuthPage>
  )
}
