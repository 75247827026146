import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import './SearchInput.scss'
import { useSearchFieldState } from '@react-stately/searchfield'
import { useSearchField } from '@react-aria/searchfield'

type SearchInputProps = {
  value: string,
  onChange: (value: string) => void,
}

export const SearchInput = (props: SearchInputProps): JSX.Element => {
  const state = useSearchFieldState(props)
  const ref = React.useRef<HTMLInputElement>() as any
  const { inputProps } = useSearchField({ ...props, 'aria-label': 'Search' }, state, ref)

  return (
    <div className="search-field input">
      <SearchIcon />
      <input {...inputProps} placeholder="Search products" ref={ref} />
    </div>
  )
}
