import React from 'react'
import {
  LinkButton,
  Page,
  PageHeader,
  Table,
} from '@logic/platform-fabric'
import {
  Data, useContextSubscriptionId, useData,
} from '@logic/platform-core'
import { Link, useHistory } from 'react-router-dom'
import { Column } from 'react-table'
import { ProductItem, useGatewayClient } from './GatewayClient'
import { useSubscriptionProvider } from '../useSubscriptionProvider'

export const useProducts = (providerId?: string): Data<ProductItem[] | undefined> => {
  const client = useGatewayClient()
  return useData([], () => (providerId ? client.getProviderProducts(providerId) : Promise.resolve([])), [client, providerId])
}

export const ProviderProductsPage = (): JSX.Element => {
  const subscriptionId = useContextSubscriptionId()
  const history = useHistory()
  const { value: provider, completed } = useSubscriptionProvider(subscriptionId)
  const { value, completed: productsCompleted } = useProducts(provider?.id)

  if (completed && !provider) {
    history.replace(`/subscriptions/${subscriptionId}/marketplace/providers/create`)
  }

  const renderProductName = (data: ProductItem) => {
    return <Link to={`/subscriptions/${subscriptionId}/provider/products/${data.id}`}>{data.name}</Link>
  }

  const columns: Column<ProductItem>[] = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row }) => {
        return renderProductName(row.original)
      },
    },
    {
      Header: 'Key',
      accessor: 'key',
    },
    {
      Header: 'Visibility',
      accessor: 'visibility',
    },
  ]

  return (
    <Page>
      <PageHeader
        loading={!completed}
        heading={provider?.name || ''}
        subheading="Products Overview"
        resourceId={provider?.id}
      >
        <LinkButton to={`/subscriptions/${subscriptionId}/provider/products/new`} variant="primary">Add new product</LinkButton>
        <LinkButton to={`/subscriptions/${subscriptionId}/marketplace/providers/edit`}>Edit</LinkButton>
      </PageHeader>
      <Table
        loading={!productsCompleted}
        data={value || []}
        columns={columns}
        defaultSort="name"
        noRowsMessage={<p>Looks like you have no products here.<br /><Link to={`/subscriptions/${subscriptionId}/provider/products/new`}>Click here to add new product.</Link></p>}
      />
    </Page>
  )
}
