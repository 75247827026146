import React from 'react'
import './PageHeader.scss'
import CreateIcon from '@material-ui/icons/Create'
import { useField } from 'formik'
import {
  Validator,
  FieldValidator,
  createValidate,
} from '../../forms/Field'
import { Skeleton } from '../../index'
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard'

export type EditablePageHeaderProps = {
  label: string,
  name: string,
  subheading: string,
  validators?: Validator[],
  validate?: FieldValidator,
  loading?: boolean,
  resourceId?: string,
  children?: React.ReactNode,
}

export const EditablePageHeader = ({
  label,
  name,
  subheading,
  validate,
  validators,
  loading,
  resourceId,
  children,
}: EditablePageHeaderProps): JSX.Element => {
  const aggregatedValidate = createValidate(label, validators, validate)
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [field, { value, error }, { setValue }] = useField({ name, validate: aggregatedValidate })
  const [originalValue, setOriginalValue] = React.useState()

  React.useEffect(() => {
    if (error) {
      setIsEditMode(true)
    }
  }, [error])

  React.useEffect(() => {
    if (value === '' && !loading) {
      setIsEditMode(true)
    }
  }, [value, loading])

  const startEdit = () => {
    setOriginalValue(value)
    setIsEditMode(true)
  }

  const cancelEdit = () => {
    setValue(originalValue || '')
    if (originalValue) {
      setIsEditMode(false)
    }
  }

  return (
    <section className="page-header">
      <section>
        <div role="heading" aria-level={1} className={`heading ${error ? 'error' : ''}`}>
          {
            loading ? <Skeleton width={150} /> : (
              <React.Fragment>
                <div>
                  {isEditMode ? <input {...field} aria-label={label} type="text" autoFocus={true} placeholder={label} /> : <span>{value}</span>}
                  {isEditMode
                    ? (
                      <React.Fragment>
                        <button type="button" onClick={cancelEdit}>
                          Cancel
                        </button>
                      </React.Fragment>
                    )
                    : (
                      <button type="button" className="primary" onClick={startEdit}>
                        <CreateIcon /> Edit name
                      </button>
                    )
                  }
                </div>
                <div className="message">{error}</div>
              </React.Fragment>
            )
          }
        </div>
        <div className="subheading">{loading ? <Skeleton width={100} /> : subheading}</div>
      </section>
      {resourceId
        && (
          <section className="copy-resource">
            { resourceId}
            <CopyToClipboard title="Copy ID" text={resourceId} />
          </section>
        )}
      <section className="actions">{!loading && children}</section>
    </section>
  )

}
