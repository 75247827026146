import React, { ReactNode } from 'react'
import './FieldInstruction.scss'

export type FieldInstructionProps = {
  children: ReactNode,
}

export const FieldInstruction = ({ children }: FieldInstructionProps): JSX.Element => {
  return (<div className="field-instruction">{children}</div>)
}
