import React from 'react'
import { useMenuTriggerState } from '@react-stately/menu'
import {
  useMenu,
  useMenuItem,
  useMenuTrigger,
} from '@react-aria/menu'
import { useButton } from '@react-aria/button'
import { useTreeState } from '@react-stately/tree'
import { Item } from '@react-stately/collections'
import { mergeProps } from '@react-aria/utils'
import { FocusScope } from '@react-aria/focus'
import { useFocus } from '@react-aria/interactions'
import { useOverlay, DismissButton } from '@react-aria/overlays'
import './UserPopupMenu.scss'
import { useAuthentication } from '@logic/platform-core'
import Gravatar from 'react-gravatar'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from '@logic/platform-fabric'

function MenuItem({ item, state, onAction, onClose }: any) {
  // Get props for the menu item element
  const ref = React.useRef()
  const { menuItemProps, labelProps } = useMenuItem(
    {
      key: item.key,
      isDisabled: item.isDisabled,
      onAction,
      onClose,
    },
    state,
    ref as any,
  )

  // Handle focus events so we can apply highlighted
  // style to the focused menu item
  const [, setFocused] = React.useState(false)
  const { focusProps } = useFocus({ onFocusChange: setFocused })
  const [icon, title] = item.rendered

  return (
    <li
      {...mergeProps(menuItemProps, focusProps)}
      ref={ref as any}
    >
      <div className="icon">{React.cloneElement(icon)}</div>
      <div className="title">{React.cloneElement(title, labelProps)}</div>
    </li>
  )
}

function MenuPopup(props: any) {
  // Create menu state based on the incoming props
  const state = useTreeState({ ...props, selectionMode: 'none' })

  // Get props for the menu element
  const ref = React.useRef()
  const { menuProps } = useMenu(props, state, ref as any)

  // Handle events that should cause the menu to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  const overlayRef = React.useRef()
  const { overlayProps } = useOverlay(
    {
      onClose: props.onClose,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef as any,
  )

  // Wrap in <FocusScope> so that focus is restored back to the
  // trigger when the menu is closed. In addition, add hidden
  // <DismissButton> components at the start and end of the list
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope restoreFocus={true}>
      <div {...overlayProps} ref={overlayRef as any}>
        <DismissButton onDismiss={props.onClose} />
        <ul
          className="header-popup-menu"
          {...mergeProps(menuProps, props.domProps)}
          ref={ref}
          style={{
            position: 'absolute',
            right: 5,
            margin: '4px 0 0 0',
            listStyle: 'none',
          }}
        >
          {[...state.collection].map((item) => (
            <MenuItem
              key={item.key}
              item={item}
              state={state}
              onAction={props.onAction}
              onClose={props.onClose}
            />
          ))}
        </ul>
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  )
}

function MenuButton({ component: Content, ...props }: any) {
  // Create state based on the incoming props
  const state = useMenuTriggerState(props)

  // Get props for the menu trigger and menu elements
  const ref = React.useRef<HTMLElement>()
  const { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref as any)

  // Get props for the button based on the trigger props from useMenuTrigger
  const { buttonProps } = useButton(menuTriggerProps, ref as any)

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button className="header-button" type="button" {...buttonProps} ref={ref}>
        <Content isOpen={state.isOpen} />
      </button>
      {state.isOpen && (
        <MenuPopup
          {...props}
          domProps={menuProps}
          autoFocus={state.focusStrategy}
          onClose={() => state.close()}
        />
      )}
    </div>
  )
}

const UserButton = ({ isOpen }: any) => {
  const { user } = useAuthentication()
  return (
    <div className="header-user-button">
      <span>{`${user?.profile.given_name} ${user?.profile.family_name}`}</span>
      <Gravatar email={user?.profile.email} size={43} default="mm" />
      {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </div>
  )
}

const LoginButton = () => {
  const { redirectToSignIn } = useAuthentication()
  const location = useLocation()

  return (
    <Button className="login" onPress={() => redirectToSignIn(location.pathname)}>Login</Button>
  )
}

export const UserPopupMenu = (): JSX.Element => {

  const { signOut, user } = useAuthentication()
  const history = useHistory()

  const actions = React.useMemo(() => [
    {
      key: 'profile',
      title: 'Profile',
      icon: <AccountCircleOutlinedIcon />,
      action: () => history.push('/me'),
    },
    {
      key: 'logout',
      title: 'Logout',
      icon: <PowerSettingsNewIcon />,
      action: () => signOut(),
    },
  ], [signOut, history])

  const performAction = (key: string) => {
    const performedAction = actions.filter((action) => action.key === key)[0]
    if (performedAction) {
      performedAction.action()
    }
  }

  return (
    <MenuButton component={user ? UserButton : LoginButton} aria-label="Actions" label="Actions" onAction={performAction}>
      {user && actions.map((action) => (
        <Item key={action.key} textValue={action.title}>
          {action.icon}
          <span>{action.title}</span>
        </Item>
      ))}
    </MenuButton>
  )
}
