import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from './Button'

export type CancelButtonProps = {
  defaultBackTo: string,
}

export const CancelButton = ({ defaultBackTo }: CancelButtonProps): JSX.Element => {
  const { state } = useLocation<{ backTo?: string }>()
  const history = useHistory()
  const onCancelPress = React.useCallback(() => {
    const backTo = (state && state.backTo) || defaultBackTo
    history.push(backTo)
  }, [defaultBackTo, state, history])
  return <Button variant="secondary" onPress={onCancelPress}>Cancel</Button>
}
