import React from 'react'
import './PageHeader.scss'
import { Skeleton } from '../../index'
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard'

export type PageHeaderProps = {
  heading?: string | React.ReactNode,
  subheading?: string,
  loading?: boolean,
  children?: React.ReactNode,
  resourceId?: string,
  logo?: string | React.ReactNode,
}

export const PageHeader = ({ children, resourceId, heading, subheading, loading, logo }: PageHeaderProps): JSX.Element => {
  const LogoOrImg: any = () => (typeof logo === 'string' ? (<img src={logo} alt="" width={76} height={76} />) : (logo || <React.Fragment />))
  return (
    <div className="page-header">
      {logo && (
        loading ? <Skeleton width={76} height={76} circle={true} /> : <LogoOrImg />
      )}
      <section>
        <div role="heading" aria-level={1} className="heading" aria-busy={loading}>{loading ? <Skeleton width={150} /> : heading}</div>
        <div className="subheading">{loading ? <Skeleton width={100} /> : subheading}</div>
      </section>
      {resourceId
      && (
        <section className="copy-resource">
          <span>{ resourceId }</span>
          <CopyToClipboard title="Copy ID" text={resourceId} />
        </section>
      )}
      <section className="actions">{ !loading && children }</section>
    </div>
  )

}
