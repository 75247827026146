import { useUserSubscriptionId, useServiceCatalog } from '@logic/platform-core'
import {
  LinkButton,
  Page,
  PageHeader,
} from '@logic/platform-fabric'
import React from 'react'

export const useAdminActions = () => {
  const { services } = useServiceCatalog()
  return React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return services.filter((x) => Boolean(x.adminActions)).map((x) => x.adminActions!).flat()
  }, [services])
}

export const AdminPage = (): JSX.Element => {
  const subscriptionId = useUserSubscriptionId()
  const adminActions = useAdminActions()
  return (
    <Page>
      <PageHeader heading="Manage Logic platform" subheading="Administration" />
      <LinkButton to="/manage/subscriptions">Manage subscriptions</LinkButton>
      <LinkButton to="/client-credentials/admin">Manage client credentials</LinkButton>
      <LinkButton to={`/subscriptions/${subscriptionId}/marketplace/providers/manage`}>Manage providers</LinkButton>
      {adminActions.map((action) => <LinkButton key={action.path} to={action.path}>{action.name}</LinkButton>)}
    </Page>
  )
}
