import React from 'react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { useHistory } from 'react-router-dom'

type TelemetryProviderProps = {
  instrumentationKey: string,
  children: React.ReactNode,
}

export const TelemetryContext = React.createContext<ApplicationInsights | undefined>(undefined)

export const TelemetryProvider = ({ instrumentationKey, children }: TelemetryProviderProps): JSX.Element => {

  const appInsightsRef = React.useRef<ApplicationInsights | undefined>()
  const history = useHistory()
  React.useEffect(() => {
    if (instrumentationKey) {
      const reactPlugin = new ReactPlugin()
      appInsightsRef.current = new ApplicationInsights({
        config: {
          instrumentationKey,
          maxBatchInterval: 0,
          disableFetchTracking: false,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: {
              history,
            },
          },
        },
      })
      appInsightsRef.current.loadAppInsights()
    }
  }, [history, instrumentationKey])

  return <TelemetryContext.Provider value={appInsightsRef.current}>{children}</TelemetryContext.Provider>
}

export const useAppInsights = (): ApplicationInsights | undefined => React.useContext(TelemetryContext)
