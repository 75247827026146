import React from 'react'
import { Field } from 'formik'
import { BaseFieldControlProps } from './BaseFieldControlProps'

export type TextFieldProps = {
  type?: 'text' | 'password' | 'multiline' | 'time' | 'email',
  placeholder?: string,
  maxLength?: number,
  prefix?: string,
}

export type TextFieldControlProps = TextFieldProps & BaseFieldControlProps

export const TextFieldControl = ({ name, placeholder, validate, type, maxLength, prefix, ...props }: TextFieldControlProps): JSX.Element => {
  const FieldWrapper: React.FC = React.useMemo(() => {
    if (prefix) {
      return ({ children }) => (
        <div className="input prefixed">
          <div>{prefix}</div>
          {children}
        </div>
      )
    }

    return ({ children }) => (<React.Fragment>{children}</React.Fragment>)
  }, [prefix])
  if (type === 'multiline') {
    return <Field name={name} placeholder={placeholder} validate={validate} as="textarea" {...props} maxlength={maxLength} />
  } else {
    return (
      <FieldWrapper>
        <Field name={name} placeholder={placeholder} validate={validate} type={type} {...props} maxlength={maxLength} />
      </FieldWrapper>
    )
  }
}
