import React from 'react'
import {
  Markdown,
} from '@logic/platform-fabric'
import ReactMarkdown from 'react-markdown'
import './OverviewTab.scss'

export const OverviewTab = (props: { documentationUrl: string | undefined, completed: boolean, contactProvider: string | undefined }): any => {

  return (
    <React.Fragment>
      <section className="overview-col">
        <Markdown source={props.documentationUrl} loading={!props.completed} />
      </section>
      <section className="overview-col">
        {props.contactProvider && (
          <div className="contact-provider">
            <header>contact the provider</header>
            <ReactMarkdown>{props.contactProvider}</ReactMarkdown>
          </div>
        )}
      </section>
    </React.Fragment>
  )
}
